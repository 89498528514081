// TURBO

// frames
//---------------------------------------------
// change the color of the progress bar
.turbo-frame--loading {
  display: none;
}

turbo-frame[busy] .turbo-frame--loading {
  display: inline-block;
}


// progress bar
//---------------------------------------------
// https://turbo.hotwired.dev/handbook/drive

// change the color of the progress bar
.turbo-progress-bar {
  background-color: $gray-dark;
}