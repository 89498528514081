// KEYFRAMES

// pulse
// --------------------------------------------------
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(174, 223, 246, 1);
  }

  20% {
    box-shadow: 0 0 0 0.5rem rgba(174, 223, 246, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(174, 223, 246, 0);
  }
}


// rotate
// --------------------------------------------------
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


// save success
// --------------------------------------------------
@keyframes saveSuccess {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    opacity: 1;
    transform: scale3d(1.2, 1.2, 1.2);
  }

  40% {
    transform: scale3d(1, 1, 1);
  }

  60% {
    transform: scale3d(1, 1, 1);
  }

  80% {
    transform: scale3d(1, 1, 1);
  }

  90% {
    opacity: 1;
    transform: scale3d(1.08, 1.08, 1.08);
  }

  to {
    transform: scale3d(0.3, 0.3, 0.3);
    visibility: hidden;
  }
}


// pulse question
// --------------------------------------------------
@keyframes pulseQuestion {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 209, 45, 0.8);
    transform: scale(0.6);
  }

  30% {
    transform: scale(1);
  }

  70% {
    box-shadow: 0 0 0 0.6rem rgba(255, 209, 45, 0);
    transform: scale(1);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 209, 45, 0);
    transform: scale(0.6);
  }
}


// loader spinner (live components)
// --------------------------------------------------
@keyframes loaderSpinner {
  to { transform: rotate(360deg); }
}


// fadeOut
// --------------------------------------------------
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


// pulse steps (1,2,3)
// --------------------------------------------------
@keyframes pulseStepperBullet {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.15);
  }
}

// community setup card animations (leave, enter)
// --------------------------------------------------
// leave animations
@keyframes leaveToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-100px);
    opacity: 0;
  }
}

@keyframes leaveToRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100px);
    opacity: 0;
  }
}

// enter animations
@keyframes enterFromRight {
  0% {
    transform: translateX(100px);

    opacity: 0;
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

// bounce animations
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px) rotateY(-9deg);
  }

  5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  20% {
    opacity: 1;
    transform: translateX(5px) rotateY(7deg);
  }

  30% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  40% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}


// hero scroll bounce
// --------------------------------------------------
@keyframes scrollDownBounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-2px);
  }
}