// STAR RATING

// content
//---------------------------------------------
.star-rating {
  direction: rtl;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin: 0 0 $space-30 0;
}

// <input type="radio">
.star-rating__input {
  // hide the radio
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  &:checked {
    // show all stars before the 'checked' one in yellow
    & ~ .star-rating__label {
      color: $bright-yellow; // same color as navigation badge
    }
  }
}

// <label>
.star-rating__label {
  cursor: pointer;
  position: relative;
  z-index: 2;
  color: transparent;
  border-radius: 50%;
  padding: 0.25rem;

  &:hover,
  &:focus {
    transform: scale(1.1);
    color: $bright-yellow; // same color as navigation badge

    // also show the 'previous' stars in yellow
    & ~ .star-rating__label {
      color: $bright-yellow; // same color as navigation badge
    }
  }

  &:active {
    transform: scale(1.05);
  }
}

// <svg> star
.star-rating__svg {
  width: 2.5rem;
  height: 2.5rem;

  path {
    stroke: $bright-yellow; // same color as navigation badge
    stroke-width: 8%;
  }
}