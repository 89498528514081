// VARIABLES

// css custom properties
// --------------------------------------------------
body {
  // cards (overriden for alternative sections, maybe later for dark mode)
  --card-bg: #fff;
  --card-color: #231f20;

  // communitys (overriden for alternative sections, wizard and maybe later for dark mode)
  --community-bg: #fff;
  --pill-bg: #f2f2f5;

  // border radius
  --border-radius-xl: calc(var(--border-radius) * 0.125rem); // double of radius (radius*2)
  --border-radius-lg: calc(var(--border-radius) * 0.0625rem); // radius in rem
  --border-radius-sm: calc((var(--border-radius) / 2) * 0.0625rem); // half of radius (radius/2)
}

// ui variables
// --------------------------------------------------

// ## general
$base-font: Verdana, Arial, sans-serif; // copy text
$base-font-size: 1rem;
$line-height-text: 1.5;
$text-color: #231f20; // copy text color
$border-color: #626b85; // inputs, selects, etc.
$border-color-light: #e3e5ea; // light border between e.g. list items

// ## focus
$focus-shadow: 0 0 0 0.125rem rgba(255,255,255,1);
$focus-outline: 0.1875rem solid #231f20; // 3
$focus-outline-offset: 0.125rem; // 2

// ## colors
$gray-mm: #231f20;
$gray-darkest: #454c56;
$gray-dark: #626b85;
$gray-middle: #caced8;
$gray-light: #e3e5ea;
$gray-lightest: #f2f2f5;

$danger-color: #da2939;
$danger-color-hover: #861721;
$warning-color: #cb4e19;
$warning-bg: #ff7942;
$success-color: #008a09;
$info-color: #005a9f;
$bright-yellow: #ffd12d;

$alert-error-bg: #f5c9cd;
$alert-error-color: #861721;
$alert-warning-bg: #feeba2;
$alert-warning-color: #7e6502;
$alert-info-bg: #aedff6;
$alert-info-color: #0d5577;
$alert-success-bg: #d5ebcc;
$alert-success-color: #3d6d2a;
$alert-tip-bg: #eaf4ff;
$alert-tip-color: #005bb5;

$pastel-green: #d5f5e2; // (50% opacity of #abebc6)
$pastel-purple: #eaddf0; // (50% opacity of #d7bde2)
$pastel-blue: #d6eaf7; // (50% opacity of #aed6f1)
$pastel-yellow: #fff5d5; // (50% opacity of #edcda3)
$pastel-beige: #f6e6d1; // (50% opacity of #ffe69b)
$pastel-red: #feebee;

$overlay-bg-color: rgba(0,0,0,0.8); // modal backdrop

// ## breakpoints
$breakpoint-sm: 36rem; // 576
$breakpoint-md: 48rem; // 768
$breakpoint-lg: 62rem; // 992
$breakpoint-xl: 75rem; // 1200
$breakpoint-xxl: 120rem; // 1920

// ## shadows
$box-shadow-sm: 0 0.125rem 0.3125rem 0 rgba(0,0,0,0.25);
$box-shadow-highlight: -0.3125rem -0.3125rem 0.9375rem 0 rgba(255,255,255,0.75),
                        inset 1.875rem 1.875rem 3.75rem 0 rgba(255,255,255,0.3),
                        0.0625rem 0.0625rem 0 0.125rem rgba(0,0,0,0.03),
                        0.3125rem 0.3125rem 0.9375rem rgba(98, 107, 133,0.25);
// ## transitions
$transition-timing: cubic-bezier(.4,.0,.23,1);
$transition: 0.3s $transition-timing;

// ## form
$input-height: 2.5rem; // height of inputs & select

// ## z-index
$zindex-modal: 9999;
$zindex-modal-dialog: 9998;
$zindex-modal-backdrop: 9997;
$zindex-impersonation: 1003;
$zindex-dropdown: 1003;
$zindex-dialog: 1003;
$zindex-sidebar: 1002; // needs to be lower than the dialog
$zindex-system-message: 1002; // needs to be lower than impersonation
$zindex-header: 1002;
$zindex-sticky: 999; // e.g. sub navigation
$zindex-navigation: 999;
$zindex-footer: 998;
$zindex-loader: 997;

// ## container sizes
$header-height: 5.3125rem; // 85 used for top spacing with sticky header
$mobile-sidebar-height: 5rem; // 80 used for bottom spacing with sticky navbar
$system-message-height: 3.75rem; // 60 used for top spacing with sticky header and navigation
$footer-height: 3.75rem; // 60 used for spacing with sidebar
$setup-submit-height: 6.25rem; // 100 used for community setup sticky submit area
$sidebar-width: 21.25rem; // 340 used for main left padding
$container-xl: 80rem; // 1280 + 1.875rem padding left / right
$container-lg: 73.125rem; // 1170 + 1.875rem padding left / right
$container-md: 48.125rem; // 770 + 1.875rem padding left right
$container-sm: 35.625rem; // 570 + 1.875rem padding left right
$container-xs: 23.125rem; // 370 + 1.875rem padding left right

// ## spacings
$page-padding: 1.875rem; // 30 used for left right spacing on containers
$box-padding-lg: 1.875rem; // 30px
$box-padding-sm: 0.9375rem; // 15px

// only used for margins
$space-100: 6.25rem;
$space-90: 5.625rem;
$space-80: 5rem;
$space-70: 4.375rem;
$space-60: 3.75rem;
$space-50: 3.125rem;
$space-40: 2.5rem;
$space-30: 1.875rem;
$space-20: 1.25rem;
$space-15: 0.9375rem;
$space-10: 0.625rem;
$space-5: 0.3125rem;

$copy-text-spacing: 0.9375rem; // 15px spacing used for p, ul, ol