// WIZARD

// <div> wrapper
//---------------------------------------------
.wizard {
  padding: 4.375rem $page-padding;
}

// <div> box
//---------------------------------------------
.wizard__box {
  border-radius: var(--border-radius-lg);
  padding: 2.5rem $box-padding-lg;
  background: #fff;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;

  @media (max-width: $breakpoint-sm) {
    gap: $space-30;
  }

  @media (min-width: $breakpoint-sm) {
    gap: $space-60;
  }
}

// <modifier> sizes
.wizard--sm {
  max-width: $container-sm;
}

.wizard--md {
  @media (max-width: $breakpoint-xl) {
    max-width: $container-sm;
  }

  @media (min-width: $breakpoint-xl) {
    max-width: $container-md;
  }
}

// <div> header
//---------------------------------------------
.wizard__header {
  text-align: center;
}

// <div> rating
.wizard__rating {
  display: flex;
  justify-content: center;
}

// <img>
.wizard__image {
  text-align: center;
  img, svg {
    max-width: 12.5rem; // 200
    margin: 0 auto;
  }
}

// <div> navigation
.wizard__navigation {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.9375rem;
  padding: $space-10 0 0 0;

  @media (max-width: $breakpoint-sm) {
    margin: $space-30 0 0 0;
  }

  @media (min-width: $breakpoint-sm) {
    margin: $space-60 0 0 0;
  }

  .btn {
    flex: 1 1 50%;

    // if it is the only button, then also show the button text on mobile
    // (additionally to the next/prev icon)
    &:only-child {
      .wizard__link-icon {
        display: none;
      }

      .wizard__link-text {
        display: block;
      }
    }
  }

  // if the navigation is the only child in the container, dont add margin
  &:only-child {
    margin: 0;
    padding: 0;
  }

  :only-child {
    flex: 0 0 auto;
    margin-inline: auto;
  }
}

// <a>
.wizard__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;

  @media (min-width: $breakpoint-sm) {
    max-width: none;
  }
}

// <svg>
.wizard__link-icon {
  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}

// <span>
.wizard__link-text {
  @media (max-width: $breakpoint-sm) {
    display: none;
  }

  @media (min-width: $breakpoint-sm) {
    display: block;
  }
}


// <div> progress
// --------------------------------------------------------
.wizard__progress {
  position: relative;
  display: flex;
  justify-content: center;
}

// <span> step text
.progress__steps {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 1.625rem; // 26
  font-weight: bold;
  color: #fff;
  background: $gray-mm;
}

// <svg>
.progress__svg {
  max-width: 12.5rem; // 200
  height: auto;
  filter: drop-shadow(-0.125rem 0.125rem 1.25rem $gray-middle);
  position: relative;
}

// <svg> <circle>
.progress__svg-circle {
  stroke: var(--button-bg-color);
  stroke-width: 2.1875rem; // 35
  stroke-dasharray: 10000; // hide the initial colored circle
  stroke-dashoffset: -625rem; // hide the initial colored circle
  //stroke-dasharray: 1510; // this value is calculated (use it if there is visual 'flickering')
  //stroke-dashoffset: 94.375rem; // this value is calculated (use it if there is visual 'flickering')
  transform-origin: center; // make sure to rotate it from the center and not the left, right, ...

  &.has--transition {
    transition: stroke-dashoffset 0.6s $transition-timing;
  }
}

// <div> community name
// --------------------------------------------------------
.wizard__community {
  text-align: center;
}

.wizard__community-inner {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  margin: 0 0 2.5rem 0;
  background: $gray-lightest;
  border-radius: var(--border-radius-sm);
  padding: 0.625rem 1.25rem;
}

// <img>
.wizard__community-image {
  align-self: flex-start;
  border-radius: var(--border-radius-sm);
}

// <hX> name as headline
.wizard__community-headline {
  align-self: center;
  text-align: left;
  line-height: 1.3;
  font-size: 1.125rem; // 20
}