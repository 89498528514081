// ACCORDION

// <div> wrapper
//---------------------------------------------
.accordion {
  max-width: $container-md;
  margin: 0 auto;
  width: 100%;
}

// <div> item
//---------------------------------------------
.accordion__item {
  background: var(--card-bg);
  color: var(--card-color);
  border-radius: var(--border-radius-lg);
  padding: $box-padding-lg;

  &:not(:last-child) {
    margin: 0 0 $space-30 0;
  }
}

// <hX>
//---------------------------------------------
.accordion__headline {
  margin: 0 $space-5 0 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: $line-height-text;
}

// <div> panel
//---------------------------------------------
.accordion__panel {
  padding: 0.9375rem 0 0 0; // 15 0 0 0

  // apply more spacing on top of headlines, if they follow a p, ul or ol
  p {
    margin: 0 0 $copy-text-spacing 0;

    & + h2, & + h3, & + h4 {
      margin-top: $space-30;
    }
  }

  :last-child {
    margin-bottom: 0;
  }
}