// CARD USER (on dashboard)

// <div> header
//---------------------------------------------
.participant__header {
  display: flex;
  align-items: center;
  gap: 1.875rem;

  @media (max-width: $breakpoint-lg) {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
  }
}

// <img>
.participant__image {
  width: 9.375rem;
  border-radius: 50%;
  max-width: 100%;
  align-self: center;

  @media (max-width: $breakpoint-lg) {
    flex: 0 0 9.375rem;
  }

  @media (min-width: $breakpoint-lg) {
    flex: 0 0 10.625rem;
  }
}

// <div> info (headline & link)
.participant__info {
  display: inline-flex;
  flex-direction: column;
  gap: $space-15;

  @media (max-width: $breakpoint-lg) {
    align-items: center;
  }

  @media (min-width: $breakpoint-lg) {
    align-items: flex-start;
  }
}

// name
.participant__headline {
  @media (max-width: $breakpoint-md) {
    font-size: 2rem;
  }

  @media (min-width: $breakpoint-md) {
    font-size: 2.25rem; // 46
  }
}

// <a> mailto
.participant__email {
  line-height: $line-height-text;
}

// <div> attributes
//---------------------------------------------
.participant__attributes {
  margin: $space-60 0 0 0;

  // feature detection for has pseudo selector
  @supports selector(:has(*)) {
    display: none;

    &:has(.participant__attribute-row) {
      display: block;
    }
  }

  .attribute-value {
    line-height: $line-height-text;
    word-break: break-word;
  }
}

// <div> group
//---------------------------------------------
.participant__group {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  background: $gray-lightest;
  border-radius: var(--border-radius-sm);
  padding: 0.625rem;
  margin-block-start: $space-60;
  margin-block-end: $space-30;
}

// <img>
.participant__group-image {
  border-radius: var(--border-radius-sm);

  @media (max-width: $breakpoint-sm) {
    align-self: flex-start;
  }

  @media (min-width: $breakpoint-sm) {
    align-self: center;
  }
}

// <hX> name as headline
.participant__group-headline {
  align-self: center;
  font-size: 1.25rem; // 20
  line-height: 1.3;
}

// <div> row
//---------------------------------------------
.participant__attribute-row {
  padding-inline: 0.625rem;
  margin: 0 0 $space-30 0;
}

// <div> "label" above the value
.participant__attribute-header {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin: 0 0 $space-15 0;
}

// headline
.participant__attribute-headline {
  font-size: 1.125rem;
}

// <div>
//---------------------------------------------
.participant__community {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  margin-block-start: $space-10;
  padding-inline: 0.625rem;

  &:first-of-type {
    margin-block-start: $space-15;
  }
}

.participant__community-image {
  border-radius: var(--border-radius-sm);
}

.participant__community-name {
  font-weight: normal;
  font-size: 1rem;
}

// footer
.participant__footer {
  margin: $space-60 0 0 0;
  border-block-start: 0.0625rem solid $border-color-light;
  padding: $space-15 0 0 0;
  font-size: 0.875rem;
  line-height: 1.5;
}