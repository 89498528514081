// PASSWORD (bullets, reveal, strength)

// password bullets <div>
//---------------------------------------------
.password-bullets {
  display: inline-grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0.25rem;
}

// single bullet <span>
.password-bullets__circle {
  width: 0.375rem;
  height: 0.375rem;
  background: $text-color;
  border-radius: 50%;
}


// password reveal
//---------------------------------------------
.password-reveal {
  position: relative;
}

// <input>
.password-reveal__input {
  padding-right: 2.8125rem; // 45
}

// <button>
.password-reveal__toggle {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.8125rem;
  height: 100%;
  z-index: 10;
  border-radius: var(--border-radius-sm);
  border: 0.0625rem solid transparent;

  &:hover,
  &:focus {
    color: $gray-dark;
  }
}

// <svg>
.password-reveal__svg {
  width: 1.25rem;
  height: auto;

  &:nth-child(1) {
    display: block;
  }

  &:nth-child(2) {
    display: none;
  }

  // password is visible
  .is--shown & {
    // show & hide the correct text
    &:nth-child(1) {
      display: none;
    }

    &:nth-child(2) {
      display: block;
    }
  }
}

// <span> text
.password-reveal__show {
  display: block;

  .is--shown & {
    display: none;
  }
}

.password-reveal__hide {
  display: none;

  .is--shown & {
    display: block;
  }
}


// generate <button>
//---------------------------------------------
.password-generate {
  margin: 0 0 $space-10 0;
  font-size: 0.875rem;
}

.password-generate__button {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem; // 10
  font-weight: 700;
  color: $gray-mm;
  text-underline-offset: 0.125rem;
  text-align: left;
  line-height: 1.3;
  border-radius: var(--border-radius-sm);

  &:hover {
    text-decoration: underline;
  }
}


// password strength
//---------------------------------------------
.password-strength {
  width: 100%;
  margin: $space-10 0 0 0;
  padding: 0 0 0.9375rem 0;
  position: relative;
  color: var(--pw-color);

  &.is--weak {
    --pw-bg: #{$danger-color}; // get the value via #{...} from the scss variable
    --pw-color: #{$alert-error-color};
    --pw-width: 33%;
  }

  &.is--medium {
    --pw-bg: #{$warning-bg};
    --pw-color: #{$warning-color};
    --pw-width: 66%;
  }

  &.is--strong {
    --pw-bg: #{$success-color};
    --pw-color: #{$alert-success-color};
    --pw-width: 100%;
  }
}

// <div> visual progress
.password-strength__visual {
  background: $gray-middle;
  border-radius: var(--border-radius-lg);
  height: 0.375rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    transition: all 0.2s ease-in;
    border-radius: var(--border-radius-sm);
    // these values are set above in the .password-strength rule
    width: var(--pw-width);
    background: var(--pw-bg);
  }
}

// <div> step
.password-strength__step {
  display: none;
  position: absolute;
  left: 0;
  bottom: -0.0625rem;

  &.is--visible {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
  }
}

// <span> text
.password-strength__text {
  font-size: 0.75rem;
  font-weight: 700;
  color: currentColor; // color (--pw-color) is set in .password-strength rule
}