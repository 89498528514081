// GROUP MATCH (used only for group matching)


// <div> group match
.group-match {
  grid-column: span 2;
  margin-block-start: 1.875rem;
}

// <div> avatars
//---------------------------------------------
.group-avatars {
  display: flex;
  margin-block-end: 1.875rem;

  // 330
  @container card-inline (width <= 20.625rem) {
    justify-content: center;
  }
}

// <img>
.group-avatars__img {
  box-shadow: 0 0 0 0.1875rem #fff;
  border-radius: 50%;

  &:not(:first-child) {
    margin-inline-start: -0.625rem;
  }
}

// <button>
.group-avatars__trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.8125rem; // 45
  height: 2.8125rem; // 45
  margin-inline-start: -0.625rem;
  background: $gray-middle;
  color: $gray-mm;

  border-radius: 50%;
  box-shadow: 0 0 0 0.1875rem #fff;
  font-weight: bold;

  &:hover,
  &:focus {
    background: $gray-dark;
    color: #fff;
  }
}


// <div> group members actions
//---------------------------------------------
.group-actions {
  display: flex;
  align-items: center;
  gap: 1.875rem;

  // 330
  @container card-inline (width <= 20.625rem) {
    flex-direction: column-reverse;
  }
}

// <a>, <button>
.group-actions__action {
  display: inline-flex;
  align-items: flex-start;
  gap: 0.625rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.3;
  text-decoration: underline;
  text-align: left;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  // add an invisible checkmark after the copy button
  &.is--copyable {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      translate: 0.125rem -0.125rem;
      display: inline-block;
      width: 0.625rem;
      height: 1.125rem;
      border-bottom: 0.25rem solid $gray-mm;
      border-right: 0.25rem solid $gray-mm;
      border-top-right-radius: 0.0625rem;
      border-bottom-left-radius: 0.0625rem;
      border-bottom-right-radius: 0.0625rem;

      transform-origin: center center;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: rotate(45deg) scale3d(0.9, 0.9, 0.9);
      transition: 0.3s $transition-timing;
      will-change: transform, opacity, visibility;
    }
  }

  // show the checkmark on success
  &.is--success {
    svg {
      opacity: 0;
      transition: 0.5s $transition-timing;
    }

    &::before {
      opacity: 1;
      visibility: visible;
      transform: rotate(45deg) scale3d(1, 1, 1);
      pointer-events: all;
    }
  }

  &[aria-expanded="true"] {
    svg {
      transform: rotate(180deg);
    }
  }
}

// <div> group members
//---------------------------------------------
.group-members {
  margin-block-start: 2.5rem;

  &:not(.is--visible) {
    display: none;
  }
}

// <span>
.group-members__role {
  display: block;
  text-align: left;
  font-weight: 700;
  color: $gray-dark;
  margin-block-end: $space-10;
}

// <ul>
//---------------------------------------------
.group-members__list {
  margin: 0;
  padding: 0;
  list-style: none;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 18.75rem), 1fr));
  gap: 0.9375rem;
}

// <li>
.group-members__item {
  padding: 0.9375rem;
  background: $gray-lightest;
  border-radius: var(--border-radius-lg);
}

// <a>, <div>
.group-members__link {
  display: flex;
  gap: 1.25rem;
  align-items: center;
  text-decoration: none;

  // if the outer element is a link, show underline on hover and focus
  &:is(a) {
    &:hover,
    &:focus {
      .group-members__name {
        text-decoration: underline;
      }
    }
  }
}

// <img> avatar
.group-members__img {
  flex: 0 0 3.125rem;
  border-radius: 50%;
  align-self: flex-start;
}

// <div> info
.group-members__info {
  flex: 1;
}

// <span> name
.group-members__name {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3;
  display: block;
}

// <span> attribute
.group-members__attribute {
  display: block;
  margin: 0.3125rem 0 0 0;
}