// SIMPLE TOGGLE

// wrapper
//---------------------------------------------
.simple-toggle {

}

// <div> target that gets shown/hidden
//---------------------------------------------
.simple-toggle__target {
  &:not(.is--visible) {
    display: none;
  }
}
