// CONTACTS


// <div> flexible container for contacts
//---------------------------------------------
.contact-flexible {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.875rem;
  width: 100%;

  @media (max-width: $breakpoint-lg) {
    align-items: center;
    flex-direction: column;
  }

  @media (min-width: $breakpoint-lg) {
    align-items: stretch;
    flex-direction: row;
  }

  & > div {
    flex: 0 1 30%;
    height: auto;
    max-width: $container-sm;
  }
}

// grid container for contacts
//---------------------------------------------
.contact-grid {
  display: grid;
  gap: 1.875rem;

  @media (max-width: $breakpoint-lg) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(2, 1fr);
  }
}

// <div> contact
//---------------------------------------------
.contact {
  display: flex;
  gap: 1.875rem;
  align-items: center;
  width: 100%;

  // bigger than 500
  @container card-inline (width > 20.625rem) {
  }

  // smaller than 500
  @container card-inline (width <= 20.625rem) {
    flex-direction: column;
    text-align: center;
    justify-items: center;
  }
}

.contact__visual {
  flex: 0 0 6.25rem;
  width: 6.25rem; // 100
  height: 6.25rem;
}

.contact__image {
  object-fit: cover; // img should take the whole space
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
}

.contact__details {
  display: flex;
  flex-direction: column;
  gap: $space-10;

  // smaller than 500
  @container card-inline (width <= 20.625rem) {
    align-items: center;
  }
}

