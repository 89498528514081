// DIALOG (support, feedback)

// overlay <div>
//---------------------------------------------
.dialog {
  display: flex;
  flex-direction: column;
  gap: $space-30;
  position: fixed;
  max-height: calc(100vh - 2.5rem);
  overflow: auto;
  z-index: $zindex-dialog;
  background: #fff;
  box-shadow: $box-shadow-sm;
  padding: $box-padding-lg;
  border-radius: var(--border-radius-lg);

  @media (max-width: $breakpoint-sm) {
    left: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
  }

  @media (min-width: $breakpoint-sm) {
    max-width: 23.125rem;
    left: auto;
    bottom: $page-padding;
    right: $page-padding;
  }

  @media (max-width: $breakpoint-lg) {
    transform-origin: center bottom;
  }

  @media (min-width: $breakpoint-lg) {
    transform-origin: right bottom;
  }


  // if the dialog is opened within the profile, we place it differently
  .has--navigation & {
    max-height: calc(100vh - 6.875rem);

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) {
      left: auto;
    }

    @media (max-width: $breakpoint-lg) {
      bottom: 6.25rem;
    }

    @media (min-width: $breakpoint-lg) {
      left: auto;
      bottom: calc(#{$footer-height} + #{$page-padding});
    }
  }

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: scale3d(0.9, 0.9, 0.9);
  transition: $transition; // 0.25
  will-change: transform, opacity, visibility;

  &.is--visible {
    opacity: 1;
    visibility: visible;
    transform: scale3d(1, 1, 1);
    pointer-events: all;
  }
}

// header
//---------------------------------------------
.dialog__header {
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
}

// <hX> headline
//---------------------------------------------
.dialog__headline {
  h1, h2, h3, h4, span, p {
    font-weight: 700;
    line-height: 1.3;
    margin: 0;
    font-size: 1.625rem;
  }
}

// close <button>
.dialog__close {
  margin-left: auto;
  transform: translate(0.9375rem, -0.9375rem);
}

// leadin <div>
//---------------------------------------------
.dialog__leadin {
}

// body <div>
//---------------------------------------------
.dialog__body {
}