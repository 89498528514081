// PLATFORM ROLES

// <div> add platform roles
//---------------------------------------------
.add-platform-role {
  display: flex;
  gap: $space-15;

  // < 700
  @container card (width <= 43.75rem) {
    flex-direction: column;
  }
}

.add-platform-role__group {
  flex: 1;
  display: flex;
  position: relative;
}

// <label>
.add-platform-role__label {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-dark;
}

// <input>
.add-platform-role__input {
  padding-inline-start: 3.125rem; // 50
  min-height: 100%;
}


// <ul> platform-admins
//---------------------------------------------
.platform-roles {
  padding: 0;
  list-style: none;
  margin: 0 0 $space-15 0;
}

// <li> single platform-admin
//---------------------------------------------
.platform-role {
  display: flex;
  gap: $space-15;
  border-radius: var(--border-radius-lg);
  padding: $box-padding-sm;
  position: relative;

  &:nth-child(odd) {
    background: $gray-lightest;
  }

  // > 500
  @container card (width > 31.25rem) {
    align-items: center;
  }

  // < 500
  @container card (width <= 31.25rem) {
    flex-direction: column;
  }
}

// <img>
.platform-role__img {
  flex: 0 0 3.125rem; // 50
  min-width: 3.125rem;
  border-radius: 50%;
  align-self: center;
}

// <div>
.platform-role__info {
  display: flex;
  gap: $space-5;
  flex-direction: column;
}

// <a>
.platform-role__link {
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

// <h3> name
.platform-role__name {
  font-size: 1rem;
  line-height: 1.3;
  margin: 0;

}

.platform-role__email {
  font-size: 0.875rem; // 14
  color: $gray-dark;
  display: block;
  word-break: break-word;
  line-height: 1.3;
}

// <div> delete button
.platform-role__actions {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-inline-start: auto; // move the container to the right side

  @container card (width <= 31.25rem) {
    position: absolute;
    top: 0.9375rem; // 15
    right: 0.9375rem; // 15
  }
}