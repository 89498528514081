// SECTION <section>

// <section>
// --------------------------------------------------
.section {
  background: #fff;

  // alternating background
  &:nth-child(odd) {
    --card-bg: #{$gray-lightest}; // info: if darkmode is implemented change sass variables to custom properties
    --community-bg: #{$gray-lightest};
    --pill-bg: #fff;
  }

  &:nth-child(even) {
    background: $gray-lightest;
    --card-bg: #fff;
    --community-bg: #fff;
    --pill-bg: #{$gray-lightest};
  }
}

// <div> inner
// --------------------------------------------------
.section__inner {
  max-width: $container-lg;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: $space-80;
}

// <div> intro
// --------------------------------------------------
.section__intro {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.9375rem; // 15

  @media (max-width: $breakpoint-xl) {
    max-width: $container-sm;
  }

  @media (min-width: $breakpoint-xl) {
    max-width: $container-md;
  }
}


// section specific selectors
// --------------------------------------------------
.section--hero {
  background: var(--hero-gradient, var(--hero-bg-color)); // use --hero-bg-color as fallback if --hero-gradient does not exist
  position: relative;
  overflow: clip;

  @media (min-width: $breakpoint-xl) {
    min-height: 75vh;
    display: flex;
    align-items: center;
  }
}

.section--about {

}

.section--about-steps {
  content-visibility: auto;
}

.section--contact {
  content-visibility: auto;
}

.section--faq {

}