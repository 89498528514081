// HEADER <header>

// <header>
// --------------------------------------------------
.header {
  width: 100%;
  background: #fff;
  box-shadow: 0 0.625rem 2.5rem -0.625rem rgba(0, 0, 0, 0.1);
  padding: 0 $page-padding;

  // sticky header for bigger screens
  @media (min-width: $breakpoint-lg) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-header;
  }
}

// <div>
// --------------------------------------------------
.header__inner {
  height: $header-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
  margin: 0 auto;
}

// logo
// --------------------------------------------------
.logo {
  flex: 0 1 auto;

  @media (max-width: $breakpoint-md) {
    max-width: 10.625rem; // 170
  }

  @media (min-width: $breakpoint-md) {
    max-width: 14.375rem; // 230
  }
}

// <img>
.logo__image {
  max-height: 3.4375rem; // 55
  width: auto;
}

// actions on right side
// --------------------------------------------------
.header__actions {
  display: inline-flex;

  @media (max-width: $breakpoint-sm) {
    gap: 0.625rem;
  }

  @media (min-width: $breakpoint-sm) {
    gap: 1.25rem;
  }
}