// CHART

// <div> placeholder
//---------------------------------------------
.chart-placeholder {
  border-radius: var(--border-radius-lg);
  background: $gray-lightest;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 18.75rem;
}

// <div> wrapper
//---------------------------------------------
.chart-wrapper {
  border: 0.0625rem solid $border-color-light;
  padding: 1.875rem 0.9375rem 0.9375rem; // create more space for chart labels
  border-radius: var(--border-radius-lg);
}

// <div> holding the chart
//---------------------------------------------
.chart {
  height: 100%;
  min-height: 18.75rem;
}

.chart--network-graph {
  min-height: 43.75rem;
}

// <div> chart export
// in this section we override the default menu styles
// https://www.amcharts.com/docs/v5/concepts/exporting/export-menu/
//---------------------------------------------
.am5exporting-menu {
  // reposition the whole menu
  translate: 0.625rem -1.875rem; // 10 -30
}

// menu toggle "button"
a.am5exporting-icon {
  min-width: 2.125rem;
  min-height: 2.125rem;
  border: 0.0625rem solid transparent; // used for high contrast mode
  width: auto;
  height: auto;
  margin: 0;
  padding: 0.3125rem;
  border-radius: var(--border-radius-sm);
  opacity: 1;
  color: $text-color;
  font-weight: 400;

  .am5exporting-menu-open &,
  &:focus,
  &:hover {
    background: none;

    svg {
      path {
        fill: $gray-mm;
      }
    }
  }

  // the (***) svg
  svg {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    // we make the svg bigger with scale, because there are no other options
    transform: scale(1.4);

    path {
      fill: $gray-dark;
    }
  }
}

// A <ul> element with menu items.
.am5exporting-list {
  &.am5exporting-align-right {
    // we are going to use translate to "override" the added style block in the html head
    // we move the list below the toggle button (***)
    translate: 2.5rem 2.1875rem;
    border: 0.0625rem solid $gray-lightest;
    box-shadow: $box-shadow-sm;
    border-radius: var(--border-radius-sm);
    padding: 0.9375rem; // 15
    background: #fff;
  }
}

// remove the separator <li> and add proper border instead
.am5exporting-type-separator {
  display: none;
}

// <a> element of the menu item (child of the <li>)
.am5exporting-item a {
  padding: 0.625rem 1.25rem;
  text-decoration: none;
  border-radius: var(--border-radius-sm);
  width: 100%;
  white-space: nowrap; // make sure the text does not break into another line
  font-size: 1rem;

  // use this trick to override inline styles for hover and focus
  .am5exporting-menu-open & {
    padding: 0.625rem 1.25rem;

    &:hover,
    &:focus {
      color: $text-color;
      background: $gray-middle;
    }
  }

  // hide the small label inside saying 'Bild' or 'Daten'
  .am5exporting-label-alt {
    display: none;
  }
}