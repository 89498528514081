// SINGLE SIGN ON (SSO)

// <div> wrapper
//---------------------------------------------
.sso {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.625rem;
}

// <a>
//---------------------------------------------
.sso__link {
  padding-inline: 0.625rem;
  text-align: left;
}