// AUTH LINKS (e.g. hero and community join page)

// links <div>
// --------------------------------------------------
.auth-links {
  display: flex;
  gap: 1.875rem;
  flex-wrap: wrap;
  margin: $space-30 0 0 0;
}

// <a> link style
.auth-links__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  text-decoration: underline;
  text-underline-offset: 0.125rem;
  font-weight: 700;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// <div> sso links
// --------------------------------------------------
.auth-links__sso {
  // show a grid, if there are at least 2 items
  &:not(&[data-sso-count="1"]) {
    display: inline-grid;
    gap: 0.9375rem;

    @media (max-width: $breakpoint-sm) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media (min-width: $breakpoint-sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}