// HERO

// wrapper <div>
// --------------------------------------------------
.hero {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-xl) {
    max-width: $container-sm;
  }

  @media (min-width: $breakpoint-xl) {
    max-width: $container-xl;
    width: 100%;
  }
}

// <img>
// --------------------------------------------------
.hero__bg {
  position: absolute;
  object-fit: cover;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;

  @media (max-width: $breakpoint-xl) {
    max-width: unset;
  }

  @media (min-width: $breakpoint-xl) {
    max-width: 100%;
  }
}

// <div> inner
// --------------------------------------------------
.hero__inner {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-xl) {
    gap: 3.75rem;
    flex-direction: column;
  }

  @media (min-width: $breakpoint-xl) {
    flex-direction: row;
    gap: 6.25rem;
    width: 100%;
  }

  // adapt the layout if characters are hidden. feature: hide-phoenix-characters
  [data-has-front-layer="false"] & {
    justify-content: center;
    text-align: center;

    @media(max-width: $breakpoint-xxl) {
      max-width: $container-sm;
    }

    @media(min-width: $breakpoint-xxl) {
      max-width: $container-md;
    }
  }
}

// <div> text
// --------------------------------------------------
.hero__text {
  flex: 1; // make sure that text and image (if set) takes the same amount of space
  color: var(--hero-text-color);
}

// headline
.hero__headline {
  @media (max-width: $breakpoint-md) {
    font-size: 2rem;
  }

  @media (min-width: $breakpoint-md) {
    font-size: 3.5rem;
  }

  @media (max-width: $breakpoint-xl) {
    text-align: center;
  }

  @media(min-width: $breakpoint-xl) {
    text-align: left;

    // adapt the layout if characters are hidden. feature: hide-phoenix-characters
    [data-has-front-layer="false"] & {
      text-align: center;
    }
  }

  // subline
  span {
    margin: $space-15 0 0 0;
    font-size: 1.625rem; // 26
    font-weight: 400;
    display: block;
  }
}

// links <div>
// --------------------------------------------------
.hero__links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $space-60 0 0 0;

  @media (max-width: $breakpoint-sm) {
    gap: 1.875rem;
    flex-direction: column;
  }

  @media (min-width: $breakpoint-sm) {
    gap: 3.125rem;
    flex-direction: row;
  }

  @media (min-width: $breakpoint-xl) {
    justify-content: flex-start;
  }

  // adapt the layout if characters are hidden. feature: hide-phoenix-characters
  [data-has-front-layer="false"] & {
    justify-content: center;

    @media(min-width: $breakpoint-xxl) {
      margin-inline: auto;
      max-width: $container-sm;
    }
  }

  .btn--primary {
    background: transparent;
    color: var(--hero-text-color);
    border-color: var(--hero-text-color);
    border-width: 0.125rem;

    &:hover,
    &:focus {
      background: #fff;
      color: $text-color;
    }
  }
}

.hero__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  text-decoration: underline;
  text-underline-offset: 0.125rem;
  font-weight: 700;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// <div> sso links
// --------------------------------------------------
.hero__sso {
  // show a grid, if there are at least 2 items
  &:not(&[data-sso-count="1"]) {
    display: inline-grid;
    gap: 0.9375rem;

    @media (max-width: $breakpoint-sm) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media (min-width: $breakpoint-sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

// visual <div>
// --------------------------------------------------
.hero__visual {
  display: inline-flex;
  justify-content: center;
  flex: 1; // make sure that text and image (if set) takes the same amount of space

  svg {
    @media (max-width: $breakpoint-md) {
      max-width: 25rem;
    }

    @media (min-width: $breakpoint-md) {
      max-width: 100%;
      width: 25rem;
    }
  }
}

// animated scroll arrow
// --------------------------------------------------
// todo: as soon as scrolldriven animations are supported, use this instead of hero_scroll_controller.js
.hero__scroll {
  @media (max-width: $breakpoint-xl) {
    display: none;
  }

  @media (min-width: $breakpoint-xl) {
    display: flex;
    gap: $space-10;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: $space-30;
    left: 50%;
    translate: -50% 0;
    z-index: 2;
    pointer-events: none;
    background: rgba(0,0,0,0.6);
    color: #fff;
    font-size: 0.75rem;
    font-weight: 700;
    transition: $transition;

    width: 2.5rem; // 40
    height: 2.5rem; // 40
    border-radius: 50%;
    animation: scrollDownBounce 2s infinite;
    animation-delay: 2s;

    &.is--hidden {
      opacity: 0;
    }

    &:not(.is--hidden) {
      opacity: 1;
    }
  }
}

// <svg>
.hero__scroll-icon {
  opacity: 0.85;
}

// <span>
.hero__scroll-text {
}