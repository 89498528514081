//= SYMFONY UX DROPZONE
// original styles can be found here: @symfony/ux-dropzone/src/style.css
// we use our own styles

// <div> wrapper
//---------------------------------------------
.dropzone-container {
  width: 10.625rem; // 170
  height: 10.625rem; // 170
  border-radius: 50%;
  box-shadow: $box-shadow-sm;
  gap: 0.625rem;
  padding: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;

  &:focus-within,
  &:hover {
    background: $gray-lightest;
  }

  &:focus-within {
    box-shadow: $focus-shadow;
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }

  &.is--loading {
    // add loading spinner
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 10.625rem; // 170
      height: 10.625rem; // 170
      border-top: 0.1875rem solid rgba(0, 0, 0, 0.25);
      border-right: 0.1875rem solid transparent;
      border-radius: 50%;
      animation: dropzoneLoading 1s linear infinite;
    }
  }
}

@keyframes dropzoneLoading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// <input type="file">
//---------------------------------------------
.dropzone-input {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
}

// text in dropzone circle
//---------------------------------------------
.dropzone-placeholder {
  line-height: 1.5;
  font-size: 0.875rem;
  padding: 2.5rem 0 0 0;
  position: relative;

  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-139.9 63.7l-10.8 10.8c-9.6 9.6-25.2 9.3-34.5-.5L320 266.1V392c0 13.3-10.7 24-24 24h-16c-13.3 0-24-10.7-24-24V266.1l-32.4 34.5c-9.3 9.9-24.9 10.1-34.5.5l-10.8-10.8c-9.4-9.4-9.4-24.6 0-33.9l92.7-92.7c9.4-9.4 24.6-9.4 33.9 0l92.7 92.7c9.4 9.4 9.4 24.6.1 33.9z"/></svg>');
  background-repeat: no-repeat;
  background-size: 2.5rem auto; // 40 auto
  background-position: center top;
}

// hide the preview within the dropzone
//---------------------------------------------
.dropzone-preview {
  display: none;
  visibility: hidden;
}

// <div> custom wrapper
//---------------------------------------------
.dropzone-wrapper {
  position: relative;
  max-width: 10.625rem; // 170
}

// <button> cancel
//---------------------------------------------
.dropzone__cancel {
  display: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;

  &:hover,
  &:focus {
    color: $gray-dark;
  }

  &.is--visible {
    display: flex;
  }
}

// dropzone errors
//---------------------------------------------
.dropzone-error-message {
  display: none;
  align-items: flex-start;
  flex-direction: column; // just in case there is more than one error shown (e.g. blank and email format)
  gap: 0.3125rem;
  color: $danger-color;
  line-height: $line-height-text;
  font-size: 0.875rem;
  margin: $space-15 0 0 0;

  &.is--visible{
    display: flex;
  }
}