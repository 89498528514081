// GRID (profile)

// <div> wrapper
//---------------------------------------------
.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  row-gap: 1.875rem;

  @media (min-width: $breakpoint-md) {
    column-gap: 1.875rem;
  }

  // add spacing to following card grids
  & + .grid {
    margin: $space-60 0 0 0;
  }
}

// <div> grid item
//---------------------------------------------
.grid__item {
  grid-column: span 12; // per default the grid__item uses the full width
}

// <modifiers>

// small
.grid__item--sm {
  @media (min-width: $breakpoint-xl) {
    grid-column: span 4;
  }
}

// middle
.grid__item--md {
  @media (min-width: $breakpoint-xl) {
    grid-column: span 6;
  }
}

// large
.grid__item--lg {
  @media (min-width: $breakpoint-xl) {
    grid-column: span 8;
  }
}

// full width
.grid__item--full {
  grid-column: span 12;
}

// grid headline
//---------------------------------------------
.grid__headline {
  grid-column: span 12; // the headline uses the full width
  display: flex;
  align-items: center;
  gap: 0.625rem;
  flex-wrap: wrap;
}

// <img>
.grid__headline-image {
  border-radius: var(--border-radius-sm);
}

// <div> text
.grid__headline-text {
  flex: 0 1 100%;
}