// BENEFITS

// <div> wrapper
//---------------------------------------------
.benefits {
  padding-block: 1.25rem; // 20
}

// <div> grid
//---------------------------------------------
.benefits__grid {
  display: grid;
  gap: 15px;
  margin-block-end: $space-30;

  @container card (width > 31.25rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  @container card (width > 62.5rem) {
    grid-template-columns: repeat(4, 1fr);
  }
}

// item
//---------------------------------------------
.benefits__item {
  display: grid;
  gap: 1.875rem;
  // use subgrid to properly align the values and the svg
  grid-row: auto / span 2;
  grid-template-rows: subgrid;
  align-items: center;
  justify-items: center;
  text-align: center;

  background: $gray-lightest;
  border-radius: var(--border-radius-lg);
  padding-block: $box-padding-lg;
  padding-inline: $box-padding-sm;
}

// <modifiers>
.benefit--green {
  background: $pastel-green;
}

.benefit--purple {
  background: $pastel-purple;
}

.benefit--blue {
  background: $pastel-blue;
}

.benefit--yellow {
  background: $pastel-yellow;
}

// <span> value
.benefits__value {
  font-weight: bold;
  font-size: 2rem; // 32
}

// <h*> headline
.benefits__headline {
  font-weight: normal;
}