// SKIP LINK (used for keyboard navigation)

.skip {
  margin: 0;
  padding: 0;
  list-style: none;
}

// <a>
//---------------------------------------------
.skip__link {
  border: 0;
  clip-path: inset(50%);
  height: 0.0625rem;
  width: 0.0625rem;
  margin: -0.0625rem;
  padding: 0;
  overflow: hidden;
  position: absolute;
  text-decoration: none;

  &:focus {
    clip-path: unset;
    box-shadow: none;
    width: 100%;
    height: $header-height;
    top: 0;
    left: 0;
    z-index: 9999;
    display: inline-flex;
    align-items: center;
    padding: 0 $page-padding;
    background: #fff;
    font-weight: bold;
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
  }

  .has--message & {
    &:focus {
      height: calc($header-height + $system-message-height);
    }
  }
}