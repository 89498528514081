// PROFILE IMAGE

// <div> wrapper
//---------------------------------------------
.profile-picture {
  display: flex;
  gap: 1.875rem;

  @media (max-width: $breakpoint-md) {
    flex-direction: column;
  }

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
  }
}

// <img> profile picture or avatar
.profile-picture__img {
  border: 0.0625rem solid $gray-middle;
}

// upload
//---------------------------------------------
.upload {
  @media (max-width: $breakpoint-md) {
    padding: 0 0 1.875rem 0;
  }

  @media (min-width: $breakpoint-md) {
    flex: 0 0 16.875rem; // 270
    max-width: 16.875rem;
    padding: 0 1.875rem 0 0;
    border-right: 0.0625rem solid $gray-middle;
  }
}

// upload <button>
.upload__button {
  width: 10.625rem;
  height: 10.625rem;
  border-radius: 50%;
  box-shadow: $box-shadow-sm;
  gap: 0.625rem;
  padding: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border: none;

  &:hover,
  &:focus {
    .upload__icon {
      transform: scale(1.1) translateY(-0.1875rem);
    }
  }
}

// cancel <button>
.cancel__button {
  display: none;

  &.is--visible {
    display: block;
  }
}

// <svg>
.upload__icon {
  width: 2.8125rem;
  height: auto;
  color: $gray-middle;
}

// <span>
.upload__text {
  font-size: 0.875rem;
  text-align: center;
}

// avatar picker
//---------------------------------------------
.avatar-wrapper {

  &.is--hidden {
    display: none;
  }
}

// <div>
.avatar-picker {
  flex: 1;
}

// <ul>
.avatar-picker__grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(35px, 65px)); // 65
  gap: 1.25rem;
  list-style: none;
  padding: 0;

  // if a profile image is set remove the active and hover box-shadow, so the users dont get confused
  .is--disabled & {
    pointer-events: none;
    opacity: 0.5;
  }
}


// <input type="radio">
.avatar-picker__input {
  // "hide" the radio
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;

  &:hover ~ .avatar-picker__label,
  &:checked ~ .avatar-picker__label {
    box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.375rem $gray-middle;
  }

  &:checked ~ .avatar-picker__label {
    box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.375rem var(--button-bg-color);
  }

  &:focus-visible ~ .avatar-picker__label {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }

  // if a profile image is set reomve the active and hover box-shadow, so the users dont get confused
  .is--disabled & ~ .avatar-picker__label {
    box-shadow: none;
  }
}

// <label>
.avatar-picker__label {
  cursor: pointer;
  display: inline-block;
  user-select: none;
  border-radius: 50%;
  -webkit-tap-highlight-color: transparent;
}

// <img>
.avatar-picker__img {
  border-radius: 50%;
}