// UTILITY CLASSES

// accessibility (move out of visible area for screenreaders)
// --------------------------------------------------
.visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 0.0625rem; //1px
  height: 0.0625rem; //1px
  overflow: hidden;
  border: 0;
  padding: 0;
  clip-path: inset(50%);
  margin: -0.0625rem; //1px
}

// width
// --------------------------------------------------
.full--width {
  width: 100%;
}

// containers
// --------------------------------------------------
.container {
  width: 100%;
}

// sizes
.container-xs {
  max-width: $container-xs;
  width: 100%;
}

.container-sm {
  max-width: $container-sm;
  width: 100%;
}

.container-md {
  max-width: $container-md;
  width: 100%;
}

.container-lg {
  max-width: $container-lg;
  width: 100%;
}

.container-xl {
  max-width: $container-xl;
  width: 100%;
}

// positioning
.container--center {
  margin-inline: auto;
}

// inner spacing
.container--padding {
  @media (max-width: $breakpoint-md) {
    padding: $space-60 $page-padding;
  }

  @media (min-width: $breakpoint-md) {
    padding: $space-100 $page-padding;
  }
}


// radius
// --------------------------------------------------
.image--circle {
  border-radius: 50%;
}

.radius--sm {
  border-radius: var(--border-radius-sm);
}

// text
// --------------------------------------------------
.text--center {
  text-align: center;
}

.text--gray {
  color: $gray-dark;
}

.text--xl {
  font-size: 1.25rem; // 20
}

.text--lg {
  font-size: 1.125rem; // 18
}

.text--sm {
  font-size: 0.875rem; // 14
}

.text--bold {
  font-weight: bold;
}


// colors
.color--green {
  color: $success-color;
}

.color--red {
  color: $danger-color;
}

// flex
// --------------------------------------------------
// todo: check if this can be better handled with :has selector
.justify--between {
  justify-content: space-between;
}

// positioning
// --------------------------------------------------
.position--relative {
  position: relative;
}

// outer spacing
// --------------------------------------------------

// reset
.margin--reset-block {
  margin-block: 0;
}

.margin--reset-inline {
  margin-inline: 0;
}

.margin--reset-all {
  margin: 0;
}

// margin inline
.margin--inline-auto {
  margin-inline: auto;
}

// margin block (top and bottom)
.margin--block-100 {
  margin-block: $space-100;
}

.margin--block-80 {
  margin-block: $space-80;
}

.margin--block-60 {
  margin-block: $space-60;
}

.margin--block-50 {
  margin-block: $space-50;
}

.margin--block-40 {
  margin-block: $space-40;
}

.margin--block-30 {
  margin-block: $space-30;
}

.margin--block-15 {
  margin-block: $space-15;
}

.margin--block-10 {
  margin-block: $space-10;
}

.margin--block-5 {
  margin-block: $space-5;
}

// margin bottom
.margin--bottom-100 {
  margin-block-end: $space-100;
}

.margin--bottom-80 {
  margin-block-end: $space-80;
}

.margin--bottom-60 {
  margin-block-end: $space-60;
}

.margin--bottom-50 {
  margin-block-end: $space-50;
}

.margin--bottom-40 {
  margin-block-end: $space-40;
}

.margin--bottom-30 {
  margin-block-end: $space-30;
}

.margin--bottom-15 {
  margin-block-end: $space-15;
}

.margin--bottom-10 {
  margin-block-end: $space-10;
}

.margin--bottom-5 {
  margin-block-end: $space-5;
}

// margin top
.margin--top-100 {
  margin-block-start: $space-100;
}

.margin--top-80 {
  margin-block-start: $space-80;
}

.margin--top-60 {
  margin-block-start: $space-60;
}

.margin--top-50 {
  margin-block-start: $space-50;
}

.margin--top-40 {
  margin-block-start: $space-40;
}

.margin--top-30 {
  margin-block-start: $space-30;
}

.margin--top-15 {
  margin-block-start: $space-15;
}

.margin--top-10 {
  margin-block-start: $space-10;
}

.margin--top-5 {
  margin-block-start: $space-5;
}

// inner spacing
// --------------------------------------------------
.padding-30 {
  padding: $space-30;
}

.padding-20 {
  padding: $space-20;
}

.padding-15 {
  padding: $space-15;
}

.padding-10 {
  padding: $space-10;
}

.padding-5 {
  padding: $space-5;
}

// padding block (top & bottom)
.padding--block-100 {
  padding-block: $space-100;
}

.padding--block-80 {
  padding-block: $space-80;
}

.padding--block-60 {
  padding-block: $space-60;
}

.padding--block-30 {
  padding-block: $space-30;
}

.padding--block-15 {
  padding-block: $space-15;
}

.padding--block-10 {
  padding-block: $space-10;
}

.padding--block-5 {
  padding-block: $space-5;
}

// padding inline (left & right)
.padding--inline-100 {
  padding-inline: $space-100;
}

.padding--inline-80 {
  padding-inline: $space-80;
}

.padding--inline-60 {
  padding-inline: $space-60;
}

.padding--inline-30 {
  padding-inline: $space-30;
}

.padding--inline-15 {
  padding-inline: $space-15;
}

.padding--inline-10 {
  padding-inline: $space-10;
}

.padding--inline-5 {
  padding-inline: $space-5;
}

// padding bottom
.padding--bottom-100 {
  padding-block-end: $space-100;
}

.padding--bottom-80 {
  padding-block-end: $space-80;
}

.padding--bottom-60 {
  padding-block-end: $space-60;
}

.padding--bottom-50 {
  padding-block-end: $space-50;
}

.padding--bottom-40 {
  padding-block-end: $space-40;
}

.padding--bottom-30 {
  padding-block-end: $space-30;
}

.padding--bottom-15 {
  padding-block-end: $space-15;
}

.padding--bottom-10 {
  padding-block-end: $space-10;
}

.padding--bottom-5 {
  padding-block-end: $space-5;
}

// padding top
.padding--top-100 {
  padding-block-start: $space-100;
}

.padding--top-80 {
  padding-block-start: $space-80;
}

.padding--top-60 {
  padding-block-start: $space-60;
}

.padding--top-30 {
  padding-block-start: $space-30;
}

.padding--top-15 {
  padding-block-start: $space-15;
}

.padding--top-10 {
  padding-block-start: $space-10;
}

.padding--top-5 {
  padding-block-start: $space-5;
}

// word break
.word-break {
  word-break: break-word;
}