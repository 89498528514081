// LOADER SPINNER

// <div> live component loader (inline)
//---------------------------------------------
.live-loader {

}

// <div> spinner circle
.live-loader__spinner {
  // initially hide the spinner
  opacity: 0;
  pointer-events: none;
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.9375rem 0; // 15
  transform-origin: center center;
  transition: 0.3s cubic-bezier(.4,.0,.23,1); // 0.25
  will-change: transform, opacity;
  font-size: 1rem;
  font-weight: 700;

  &::before {
    content: '';
    width: 0.9375rem;
    height: 0.9375rem;
    border-radius: 50%;
    border: 0.125rem solid $gray-middle;
    border-top-color: $gray-dark;
    animation: loaderSpinner 1s linear infinite;
    display: inline-block;
  }

  // visible styles
  &.is--loading {
    opacity: 1;
    pointer-events: all;
  }
}


// <div> overlay loader (with inline option)
//---------------------------------------------
.loader-container {
  position: relative;
}

// limit the available space for loaders that are laid over the whole card
.outer-loader-limit {
  position: absolute;
  top: $space-20; // 20
  left: $space-20; // 20
  right: $space-20; // 20
  bottom: $space-20; // 20
}

// <div> big and possible as overlay
//---------------------------------------------
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.9375rem;
  z-index: $zindex-loader;
  border-radius: var(--border-radius-lg);
  pointer-events: none;
  padding: $box-padding-lg;

  // add fade out animation when its not loading
  &:not(.is--loading) {
    animation: fadeOut 1s forwards;
  }

  // show the loader as overlay
  &.is--overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &.is--white {
    background: #fff;
  }

  &.is--gray {
    background: $gray-lightest;
  }
}

// spinning animation
.loader__spinner {
  width: 3.125rem; // 50
  height: 3.125rem; // 50
  border-radius: 50%;
  border: 0.1875rem solid $gray-middle;
  border-top-color: $gray-dark;
  animation: loaderSpinner 1s linear infinite;
  display: inline-block;
}

// <span> text
.loader__text {
  font-size: 0.875rem; // 14
}
