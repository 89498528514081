// PAGINATION

// <div> wrapper
//---------------------------------------------
.pagination {
  display: flex;
  align-items: center;
}


// <ul>
//---------------------------------------------
.pagination__list {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-inline: auto;
  margin-block-start: $space-60;
}

// <li>
.pagination__item {

}

// <button> prev, next
.pagination__button {
  &[hidden] {
    opacity: 0;
    pointer-events: none;
  }
}

// <a>
.pagination__link {
  display: inline-block;
  width: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  border-radius: var(--border-radius-sm);
  text-decoration: none;
  border: 0.0625rem solid transparent;

  &:hover,
  &:focus {
    background: #fff;
    border-color: $border-color;
  }

  &:active {
    box-shadow: inset 0 -0.0625rem 0.625rem 0 rgba(0, 0, 0, 0.05); // 0.125rem 0.9375rem
  }

  // active item
  &.is--active {
    background: var(--button-bg-color);
    color: var(--button-text-color);
    border: 0.0625rem solid var(--button-bg-color);
    font-weight: 700;

    &:focus,
    &:hover {
      color: var(--button-text-color-hover);
      background: var(--button-bg-color-hover);
      border-color: var(--button-bg-color-hover);
    }
  }
}

// <span>...
.pagination__dots {
  font-size: 1.25rem;
  width: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  letter-spacing: 0.03125rem;
}