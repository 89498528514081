// FLATPICKR OVERRIDES

// <div> datepicker
//---------------------------------------------
.datepicker {
  position: relative;
}

// custom info message for invalid period
//---------------------------------------------
.datepicker__info {
  display: none; // injected and shown via javascript
  margin: $space-10 0 0 0;
}

// <div> flatpickr wrapper
//---------------------------------------------
.flatpickr-calendar {
  width: 100%;
  margin: $space-15 0 0 0;
  box-shadow: none;
  border: 0.0625rem solid $border-color;
  padding: 0.3125rem; // 5
}

.flatpickr-rContainer {
  width: 100%; // stretch the container over whole width
}


// <div> months wrapper (header)
//---------------------------------------------
.flatpickr-months {
  padding: 0.625rem 0; // 10 0
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.625rem; // 10
  align-items: center;
}

.flatpickr-months .flatpickr-month {
  grid-column: span 5;
  height: auto;
}

.flatpickr-current-month {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: static;
  flex-wrap: wrap;
  height: auto;

  @media(max-width: $breakpoint-sm) {
    gap: 0.3125rem; // 5
  }

  @media(min-width: $breakpoint-sm) {
    gap: 0.625rem; // 10
  }
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: inline-flex;
  opacity: 0;
  pointer-events: none;
}

// <select> month
.flatpickr-current-month .flatpickr-monthDropdown-months {
  margin: 0;
  padding: 0.3125rem; // 5
  border-radius: var(--border-radius-sm);

  @media(max-width: $breakpoint-sm) {
    font-size: 1rem;
    width: 100%;
    background: $gray-lightest;
  }

  @media(min-width: $breakpoint-sm) {
    font-size: 1.125rem;
    background: transparent;
    width: auto;
  }
}

// year
.flatpickr-current-month .numInputWrapper {
  border-radius: var(--border-radius-sm);

  @media(max-width: $breakpoint-sm) {
    font-size: 1rem;
    width: 100%;
    background: $gray-lightest;
  }

  @media(min-width: $breakpoint-sm) {
    font-size: 1.125rem;
    background: transparent;
    width: 6ch;
  }

  &:hover {
    background: $gray-middle;
    border-radius: var(--border-radius-sm);
  }
}

// <input>
.flatpickr-current-month input.cur-year {
  margin: 0;
  padding: 0.3125rem 0.625rem; // 5 10
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: $gray-middle;
}

.flatpickr-months .flatpickr-prev-month, 
.flatpickr-months .flatpickr-next-month {
  position: static;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.125rem;
  height: 2.125rem;
  padding: 0;

  grid-column: span 1;
  justify-self: center;
}

// arrows
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  border-radius: var(--border-radius-sm);
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  background: $gray-middle;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: auto;
  height: 1.625rem; // 26
  fill: $gray-mm;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $gray-mm;
}

// <div> weekdays
//---------------------------------------------
.flatpickr-weekdays {
  height: auto;
  padding: 0.9375rem 0;
}

.flatpickr-weekdaycontainer {
  // we use grid instead of flex
  display: grid;
  grid-template-columns: repeat(7,1fr);
  gap: 0.1875rem;
}

span.flatpickr-weekday {
  color: $text-color;
  font-size: 0.875rem; // 14
}

// <div> days
//---------------------------------------------
.flatpickr-days {
  width: 100%; // stretch the container over whole width
}

.dayContainer {
  width: 100%;
  max-width: 100%;
  min-width: 0; // make sure the datepicker looks good on small screens (320px)
  display: grid;
  grid-template-columns: repeat(7,1fr);
  gap: 0.1875rem;
  justify-items: center;
}

// <span> single days
//---------------------------------------------
.flatpickr-day {
  text-align: center;
  width: 100%;
  max-width: 100%;
  color: $gray-mm;
  border-radius: var(--border-radius-sm);
}

.flatpickr-day.today {
  font-weight: bold;
  border: 0;
  text-decoration: underline;
  text-underline-offset: 0.125rem;
  text-decoration-thickness: 0.125rem;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: $gray-dark;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  background: $gray-middle;
  border-color: $gray-middle;
  color: $text-color;
}

// range styles
//---------------------------------------------
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange {
  background: $gray-lightest;
  border-color: $gray-lightest;
}

.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.today:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.today:focus {
  cursor: pointer;
  outline: 0;
  background: $gray-middle;
  border-color: $gray-middle;
  color: $text-color;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -0.3125rem 0 0 $gray-lightest, 0.3125rem 0 0 $gray-lightest;
  box-shadow: -0.3125rem 0 0 $gray-lightest, 0.3125rem 0 0 $gray-lightest;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: var(--button-bg-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--button-text-color);
  border-color: var(--button-bg-color);
}

.flatpickr-day.selected:hover {
  color: var(--button-text-color-hover);
  border-color: var(--button-bg-color-hover);
  background: var(--button-bg-color-hover);
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: var(--border-radius-sm);
}

// week styles
//---------------------------------------------
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -0.3125rem 0 0 var(--button-bg-color), 0.3125rem 0 0 var(--button-bg-color);
  box-shadow: -0.3125rem 0 0 var(--button-bg-color), 0.3125rem 0 0 var(--button-bg-color);
}

// monthSelect styles
//---------------------------------------------
.flatpickr-monthSelect-months {
  margin: 0;
  padding: 0.9375rem 0 0 0;
  display: grid;
  gap: 0.1875rem;

  @media (max-width: $breakpoint-sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $breakpoint-sm) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.flatpickr-monthSelect-month {
  border-radius: var(--border-radius-sm);
  color: $text-color;
  width: 100%;
  background: $gray-lightest;

  &:hover,
  &:focus {
    background: $gray-middle;
  }
}

.flatpickr-monthSelect-month.selected {
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  font-weight: 700;

  //&:focus,
  &:hover {
    background-color: var(--button-bg-color-hover);
    border-color: var(--button-bg-color-hover);
    color: var(--button-text-color-hover);
  }
}