﻿// FIELDSET <fieldset>

// <fieldset>
//---------------------------------------------
.fieldset {
  //reset styles
  margin: 0;
  min-width: 0;
  padding: 0;
  border: 0;

  &:not(:last-child) {
    margin: 0 0 1.25rem 0; // form__row has the same spacing
  }

  legend {
    &:has(svg) {
      display: flex;
      align-items: center;
      gap: $space-10;
    }
  }
}

// <legend>
.fieldset__legend {
  width: 100%;
  max-width: 100%; // ms edge fix
  margin: 0 0 $space-30 0;
}


// if the fieldset is inside a community,
// add background and padding to it
//---------------------------------------------
.community-question {
  .fieldset {
    background: $gray-lightest;
    border-radius: var(--border-radius-lg);
    padding: $box-padding-lg;
    padding-block-start: 2.5rem; // 40
  }

  legend {
    translate: 0 2.5rem; // 40
  }
}