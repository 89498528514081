// CKE EDITOR OVERRIDES
// info: to override the ck editor styles, add the class rte to a parent element

// <div> wrapper
.page {
  --ck-font-face: var(--web-font), #{$base-font};
  --ck-focus-ring: 0.0625rem solid #{$border-color};
  --ck-focus-outer-shadow: #{$gray-middle};
  --ck-color-focus-outer-shadow: #{$gray-light};
  --ck-color-base-border: #{$border-color};
  --ck-border-radius: var(--border-radius-sm);
  --ck-inner-shadow: 0;
  --ck-color-toolbar-border: #{$border-color};
  --ck-color-button-on-color: #{$gray-dark};
  --ck-color-button-on-background: #{$gray-lightest};
  --ck-color-button-on-hover-background: #{$gray-middle};
  --ck-color-list-button-hover-background: #{$gray-middle};
  --ck-color-link-selected-background: transparent;
  --ck-spacing-standard: #{$space-15};

  // button style in "text" menu
  --ck-color-list-button-on-background: var(--button-bg-color);
  --ck-color-list-button-on-background-focus: var(--button-bg-color);

  // set the focus ring on the whole ck-editor
  .ck-editor {
    &:focus-within {
      box-shadow: $focus-shadow;
      text-decoration: none;
      outline: $focus-outline;
      outline-offset: $focus-outline-offset;
      border-radius: var(--border-radius-sm);
    }

    // set correct spacings, so it appears like in the email
    .ck-content ul,
    .ck-content ol,{
      padding-left: 1.1875rem;
      line-height: $line-height-text;
      margin: $space-15 0;
    }

    .ck-content p {
      margin: $space-15 0;
    }
  }

  // restyle it for preview
  .is--preview {
    .ck-editor {
      display: none;
    }
    /*--ck-border-radius: 0; // reset the border radius completely
    --ck-spacing-large: 0; // reset the spacing of the paragraph inside.
    --ck-color-base-border: #fff; // set the border-color of the content area to white
    --ck-spacing-standard: 0; // remove the inner padding of the content area
    //--ck-color-base-border

    // hide the toolbar of the editor
    .ck-editor__top {
      display: none;
    }

    // disable pointer events and text-selection
    .ck-editor {
      pointer-events: none;
      user-select: none;
      cursor: not-allowed;
    }*/
  }
}

// <div> that is attached via javascript after every CK-Editor.
// Used for previewing the HTML of the ck-editor.
.editor-preview {
  display: none;

  .is--preview & {
    display: block;

    p, ol, ul {
      margin: $space-15 0;
      line-height: $line-height-text;
    }

    ol, ul {
      padding-left: 1.1875rem; // 17
    }
  }
}