// DROPDOWN

// <div> wrapper
// --------------------------------------------------------
.dropdown {
  position: relative;
}

// <button> toggle
// --------------------------------------------------------
.dropdown__toggle {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  min-height: 3.125rem; // 50 - increase the click area
  border-radius: var(--border-radius-sm);
  padding: 0 0.625rem;
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &[aria-expanded="true"] {
    background: $gray-lightest;
  }
}

// <img>
.dropdown__img {
  flex: 0 0 2.1875rem; // 35
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 50%;
  border: 0.0625rem solid $gray-middle;
}

// <span>
.dropdown__text {

}

// username
.dropdown--username {
  display: none;

  @media(min-width: $breakpoint-md) {
    display: none;
  }
}

// language
.dropdown__language {

}

.language--long {
  @media(max-width: $breakpoint-sm) {
    display: none;
  }

  @media(min-width: $breakpoint-sm) {
    display: block;
  }
}

.language--short {
  @media(max-width: $breakpoint-sm) {
    display: block;
    text-transform: uppercase;
  }

  @media(min-width: $breakpoint-sm) {
    display: none;
  }
}

// <svg>
.dropdown__icon {
  flex: 0 0 0.75rem;
  width: 0.75rem;
  height: auto;
  display: none;

  &.icon--collapsed {
    display: block;
  }

  [aria-expanded="true"] & {
    &.icon--expanded {
      display: block;
    }

    &.icon--collapsed {
      display: none;
    }
  }
}

// <ul> menu
// --------------------------------------------------------
.dropdown__menu {
  list-style: none;
  min-width: 100%;
  border: 0.0625rem solid $gray-lightest;
  box-shadow: $box-shadow-sm;
  border-radius: var(--border-radius-sm);
  padding: 0.9375rem; // 15
  background: #fff;
  margin: $space-10 0 0 0; // default margin on top
  opacity: 0;
  pointer-events: none;
  transform-origin: center top; // default transform-origin, overwritten via data-attributes below
  transform: scale3d(0.9, 0.9, 0.9);
  transition: $transition; // 0.25
  will-change: transform, opacity;

  // visible styles
  &.is--visible {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    pointer-events: all;
  }

  // placement
  position: absolute;
  z-index: $zindex-dropdown;

  // placement horizontal
  &[data-place-horizontal="left"] {
    left: 0;
  }

  &[data-place-horizontal="right"] {
    right: 0;
  }

  // placement vertical
  &[data-place-vertical="top"] {
    // if we open it on top, we add a little margin to the bottom and change the transform origin to bottom
    bottom: 100%;
    transform-origin: center bottom;
    margin: 0 0 $space-10 0;
  }

  &[data-place-horizontal="bottom"] {
    // if we open it on bottom, we add a little margin to the top and change the transform origin to top
    top: 100%;
    transform-origin: center top;
    margin: $space-10 0 0 0;
  }
}

// <li>
.dropdown__item {
  &:not(:last-child) {
    margin: 0 0 0.25rem 0;
  }
}

// <a>
.dropdown__link {
  padding: 0.625rem 1.25rem;
  display: grid;

  &:has(svg:first-child) {
    grid-template-columns: 1rem 1fr;
  }

  &:has(svg:last-child) {
    grid-template-columns: 1fr 1rem;
  }

  align-items: center;
  justify-content: flex-start;
  text-align: left;
  gap: 0.625rem;
  text-decoration: none;
  border-radius: var(--border-radius-sm);
  width: 100%;
  white-space: nowrap; // make sure the text does not break into another line

  &.is--active {
    background: $gray-lightest;
    font-weight: 700;
  }

  &:hover,
  &:focus {
    color: $text-color;
    background: $gray-middle;
  }
}

// <svg>
.dropdown__link-icon {
  width: 1rem;
  height: auto;
  flex: 0 0 1rem;
}