// MAIN <main>

// <main>
//---------------------------------------------
.main {
  flex: 1; // stretch the container all the way down (.page in _scaffolding.scss is the parent container)
  position: relative; // make sure the loader is centered
}

// <div> intro with headline
//---------------------------------------------
.main__intro {

}

// <hX> headline
//---------------------------------------------
.main__headline {
  padding-block-end: $space-30;
}

// community specific headlines
//---------------------------------------------
.main__community {
  display: flex;
  gap: $space-15;
  justify-content: space-between;
  padding-block-end: $space-30;
}

// <img> + <hX>
.main__community-name {
  display: inline-flex;
  gap: $space-15;
  align-items: center;
}

// <img>
.main__community-img {
  border-radius: var(--border-radius-sm);
  align-self: flex-start;
}

// <modifier>
//---------------------------------------------
// solid background
.has--gray-bg {
  background: $gray-lightest;
}

// padding left for sidebar
.has--sidebar {
  // new
  @media (max-width: $breakpoint-lg) {
    padding: $space-30;
  }

  @media (min-width: $breakpoint-lg) {
    display: grid;
    grid-template-columns: $sidebar-width 1fr;
    gap: $space-30;
    padding-block-start: $space-60;
    padding-inline: $space-30;
    padding-block-end: calc($space-60 + $footer-height);
  }
}