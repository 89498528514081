// SCAFFOLDING

// focus outline
// --------------------------------------------------
// remove outline color, width, and style on focus
:focus {
  outline: transparent;
}

// shows the focus indicator only when elements receive keyboard focus
:focus-visible {
  box-shadow: $focus-shadow;
  text-decoration: none;
  outline: $focus-outline;
  outline-offset: $focus-outline-offset;
}

// <body>
// --------------------------------------------------
body {
  font-family: var(--web-font), $base-font;
  font-size: $base-font-size;
  line-height: 1;
  color: $text-color;

  &.scroll--disabled {
    overflow: hidden; // can be removed as soon as Safari has proper scrollbar-gutter support
    height: 100%;

    // scrollbar-gutter: reserve space for the scrollbar. this way the page will not "jump" when there is or is no scrollbar
    @supports (scrollbar-gutter: stable) {
      overflow: visible; // can be removed as soon as Safari has proper scrollbar-gutter support
      scrollbar-gutter: stable;
    }
  }

  @media (max-width: $breakpoint-lg) {
    &.scroll-mobile--disabled {
      overflow: hidden; // can be removed as soon as Safara has proper scrollbar-gutter support
      height: 100%;
    }
  }
}

// page wrapper styles
// --------------------------------------------------
.page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-height: 100vh;

  @media (min-width: $breakpoint-lg) {
    padding: $header-height 0 0 0;
  }

  // this part handles spacings and top values, when messages above the header are shown (e.g. impersonation, system message)
  // if only one of the two messages is shown (either impersonation message or maintenance message)
  &.has--message:not(.is--impersonating),
  &.is--impersonating:not(.has--message) {
    padding: 0;

    @media (min-width: $breakpoint-lg) {
      padding: calc($system-message-height + $header-height) 0 0 0;

      &.has--navigation {
        padding: calc($system-message-height + $header-height) 0 0 0;
      }
    }

    // adjust the top value for the header
    .header {
      @media (min-width: $breakpoint-lg) {
        top: $system-message-height;
      }
    }
  }

  // if both messages (system and impersonating) are shown
  &.has--message.is--impersonating {
    @media (min-width: $breakpoint-lg) {
      padding: calc($system-message-height + $system-message-height + $header-height) 0 0 0;

      .header {
        top: calc($system-message-height + $system-message-height);
      }

      .system-message {
        top: $system-message-height;
      }
    }
  }
}

.page__icon {
  color: #626b85;
  margin-inline: auto;
  margin-block-end: $space-20;
}

// containers (used for container queries)
// --------------------------------------------------

// used for as single outer container
.card-container {
  container: card / inline-size;
}

// used for e.g. multiple items within a card
.card-inline-container {
  container: card-inline / inline-size;
}