// MATCH NEW

// <div> matching on dashboard
.matching {
  display: grid;
  gap: 1.875rem;

  // if the outer container is bigger than 500 (px) change the direction to left/right
  @container card (width > 50rem) {
    // the matching items should at least be 25rem, otherwise take the full space
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  }
}

.matching__item {
  background: $gray-lightest;
  border-radius: var(--border-radius-lg);
  padding: $box-padding-lg;
}

// wrapper <div>
// --------------------------------------------------
.match {
  // if the outer container is smaller than 330 (px) change the direction to top-down
  @container card-inline (width <= 20.625rem) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.9375rem;
  }

  // if the outer container is bigger than 330 (px) change the direction to left-right
  @container card-inline (width > 20.625rem) {
    display: grid;
    align-items: center;
    column-gap: 1.25rem;
    row-gap: 1.875rem;
    grid-template-columns: 6.25rem 1fr;
    grid-template-rows: auto;

    // currently only used for people browser
    &:has(.match__action) {
      grid-template-columns: 6.25rem 1fr auto;
    }
  }
}

// <div>, <a>, visual wrapper
.match__visual {

}

// <img>
.match__image {
  border-radius: 50%;
  color: $gray-middle; // if it is a svg
}

// <div> info
.match__info {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  font-size: 1rem;

  // if the outer container is smaller than 330 (px) change the direction to top-down
  @container card-inline (width <= 20.625rem) {
    align-items: center;
    text-align: center;
  }
}

// name as headline
.match__headline {
  font-size: 1.125rem;
  font-weight: 700;
}

// name link
.match__profile-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.match__text {
  font-weight: 400;
  line-height: 1.3;
}

// role (e.g. organizer)
.match__role {
  color: $gray-dark;
}

// <div> links
// --------------------------------------------------
.match__links {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

// <a>
.match__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $gray-dark;
  position: relative;
  flex-shrink: 0; // make sure they dont resize

  &::after {
    content: '';
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
  }

  &:hover,
  &:focus {
    color: $gray-mm;
  }
}

// <a, button> action
// --------------------------------------------------
.match__action {
  align-self: center;
}

// group
// --------------------------------------------------
.match__group-link {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

// <div> rating
// --------------------------------------------------
.match__rating {
  width: 100%;
  border-block-start: 0.0625rem solid $border-color-light;
  padding-block: 0.3125rem;
}

.match__rating-question {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-size: 0.875rem;

  @container card-inline (width <= 20.625rem) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0;
    padding-block-start: 0.625rem;
  }

  &[hidden] {
    display: none;
  }
}

// <div> label
.match__rating-label {
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
}

// <span> pulse
.match__rating-pulse {
  width: 0.875rem;
  height: 0.875rem;
  display: inline-block;
  translate: 0 0.125rem;
  border-radius: 50%;
  background: #ffc700;
  color: $text-color;
  animation: pulseQuestion 3s infinite ease-in-out; // find animation in _keyframes.scss
  animation-fill-mode: both;
}

// <span> text
.match__rating-text {
  line-height: $line-height-text;
}

// <div>
.match__rating-buttons {
  display: inline-flex;

  @container card-inline (width <= 20.625rem) {
    padding-inline-start: 1.25rem;
  }
}

// <button>
.match__rating-btn {
  padding: 0.625rem;
  font-weight: bold;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// <div> answer
.match__rating-answer {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-size: .875rem;
  line-height: 1.5;
  margin: 0;

  &[hidden] {
    display: none;
  }
}

// <div> icon wrapper
.match__rating-icon {
  flex: 0 0 1.25rem;
  width: 1.25rem;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
}

// <svg>
.match__rating-svg {
  flex: 0 0 0.875rem;
  width: 0.875rem;
  height: auto;

  &.is--success {
    fill: $success-color;
  }

  &.is--error {
    fill: $danger-color;
  }
}

//.match__rating-text {
//
//}



// <div> received date
// --------------------------------------------------
.match__received {
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;

  font-size: 0.875rem;
  padding: $space-15 0 0 0;
  line-height: $line-height-text;
  border-block-start: 0.0625rem solid $border-color-light;
}

// <img> community
.match__community-image {
  border-radius: var(--border-radius-sm);
}