// SELECT <select>

// reset
//---------------------------------------------
select[multiple],
select[size] {
  height: auto;
}

// wrapper <div>
//---------------------------------------------
.select {
  appearance: none;
  width: 100%;
  height: $input-height;
  padding: 0 2.1875rem 0 0.9375rem;
  background-color: #fff;
  color: $text-color;
  border: 1px solid $border-color;
  border-radius: var(--border-radius-sm);

  // set the arrow right
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/></svg>');
  background-size: 0.75rem;
  background-repeat: no-repeat;
  background-position: right 0.625rem center; // 10px set the arrow svg as background image

  // hover & focus
  &:hover,
  &:focus {
    border-color: $gray-mm;
  }

  &:focus {
    // add focus outline for all users (mouse, touch & keyboard)
    box-shadow: $focus-shadow;
  }

  // disabled
  &[disabled] {
    background-color: $gray-lightest;
    pointer-events: none;
  }

  // error style
  &[aria-invalid="true"],
  &.is--invalid {
    border-color: $danger-color;

    &:focus {
      border-color: $border-color;
    }
  }

  // hide the ms dropdown button
  &::-ms-expand {
    display: none;
  }
}