// INSIGHTS

// <div>
//---------------------------------------------
.quick-insights {
  display: grid;
  gap: 1.875rem;
  --numerOfInsights: 3; // per default we show 3 grid rows/cols

  // if there is only 1 item, we stretch it to 100%
  &:has( > :only-child) {
    --numerOfInsights: 1;
  }

  // if there are only 2 items, we stretch them to 50% each
  &:has( > :nth-child(2):last-child) {
    --numerOfInsights: 2;
  }

  @media (max-width: $breakpoint-xl) {
    grid-template-rows: repeat(var(--numerOfInsights), 1fr);
  }

  @media (min-width: $breakpoint-xl) {
    grid-template-columns: repeat(var(--numerOfInsights), 1fr);
  }
}

// <div> box footer
.quick-insights__footer {
  width: 100%;
}

// <a>
.quick-insights__link {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
}

// <div> single insight
//---------------------------------------------
.insight {
  border-radius: var(--border-radius-lg);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.875rem;
  text-align: center;
  padding: $box-padding-lg $box-padding-sm;
}

// color modifiers
//---------------------------------------------
.insight--green {
  background: $pastel-green;
}

.insight--purple {
  background: $pastel-purple;
}

.insight--blue {
  background: $pastel-blue;
}

.insight--beige {
  background: $pastel-beige;
}

.insight--yellow {
  background: $pastel-yellow;
}

// headline
//---------------------------------------------
.insight__header {
  h1, h2, h3, h4, span, p {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.3;
    margin: 0;
    display: block;
  }
}

// value
.insight__value {
  font-weight: bold;

  @media (max-width: $breakpoint-md) {
    font-size: 2rem; // 32
  }

  @media (min-width: $breakpoint-md) {
    font-size: 2.875rem; // 46
  }
}

.insight__footer {

}


// <div> insight row
//---------------------------------------------
.insight-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 0.625rem;
  column-gap: 1.25rem;
  margin-block-end: 1.875rem;
}

.insight-row__value {
  font-weight: bold;
  white-space: nowrap;

  @media (max-width: $breakpoint-md) {
    font-size: 2rem; // 32
  }

  @media (min-width: $breakpoint-md) {
    font-size: 2.875rem; // 46
  }
}

.insight-row__change {

}

// <span> holding the increased / decreased value
.insight-row__highlight {
  font-weight: bold;

  .has--increased & {
    color: $success-color;
  }

  .has--decreased & {
    color: $danger-color;
  }

  .has--not-changed & {
    color: $gray-dark;
  }
}

// meaningfulness stars
//---------------------------------------------
.meaningfulness {
  display: flex;
  justify-content: flex-start;
  gap: 1.25rem;

  // bigger than 500
  @container card (width > 31.25rem) {
    align-items: center;
  }

  // smaller than 500
  @container card (width <= 31.25rem) {
    align-items: flex-start;
    flex-direction: column;
  }
}

// <div> star wrapper
.meaningfulness__stars {
  background: $gray-light;
  display: inline-flex;
  position: relative;
}

// stars svg mask
.meaningfulness__stars-svg {
  position: relative;
  z-index: 2;
  stroke: #fff;
}

// stars bar (yellow)
.meaningfulness__stars-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1;
  background: $bright-yellow;
}

// <div> rating value
.meaningfulness__value {
  font-size: 1rem;
  font-weight: 400;
}