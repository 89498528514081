// COOKIES (data privacy)

// table
// --------------------------------------------------
.cookie {
  border: 0.0625rem solid $border-color;
  border-radius: var(--border-radius-sm);
  padding: 0.625rem;
  margin: 0 0 $space-30 0;
}

// single row
.cookie__row {
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  padding: 0.625rem;
  border-radius: var(--border-radius-sm);

  &:nth-child(odd) {
    background: $gray-lightest;
  }

  @media(max-width: $breakpoint-md) {
    flex-direction: column;
  }

  @media(min-width: $breakpoint-md) {
    flex-direction: row;
  }

  :first-child {
    flex: 0 0 35%;
    font-weight: 700;
  }

  :last-child {
    flex: 0 0 65%;
    line-height: $line-height-text;
    word-break: break-word;
  }
}