﻿// CANVAS (community image)

// <div> container
//---------------------------------------------
.canvas-wrapper {
  max-width: 5rem; // 80
  max-height: 5rem; // 80
}

// <canvas>
//---------------------------------------------
.canvas {
  max-width: 100%;
  display: block;
  border-radius: var(--border-radius-sm);
  border: 0.0625rem solid $border-color;
}



