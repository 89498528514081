//= CUSTOM STIMULUS DROP AREA

// <div> wrapper
//---------------------------------------------
.drop-area {
  position: relative;
}

// <input type="file">
//---------------------------------------------
.drop-area__input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

// <div> inner
//---------------------------------------------
.drop-area__inner {
  position: relative;
  z-index: 2;
}

// <label>
//---------------------------------------------
.drop-area__label {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: $space-30;
  align-items: center;
  justify-content: center;
  padding: $space-60 $space-30;
  border-radius: var(--border-radius-lg);
  background: $gray-lightest;
  text-align: center;

  &:has(input:focus-visible) {
    box-shadow: $focus-shadow;
    text-decoration: none;
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }

  .is--active &,
  &:hover {
    background: $gray-light;
  }
}

// <svg> icon cloud upload
.drop-area__svg {
  fill: $gray-dark;
  transition: $transition;
}

// <div> 'drag and drop...'
.drop-area__text {
  color: $gray-dark;
  line-height: $line-height-text;
}

// <span> that holds the filename
.drop-area__filename {
  &:not(:empty),
  &:not([hidden]) {
    display: block;
    line-height: $line-height-text;
    font-size: 0.875rem;
    font-weight: 700;
    word-break: break-all;
  }

  &[hidden],
  &:empty {
    display: none;
  }
}

// <span> loader
.drop-area__loader {
  &:not([hidden]) {
    display: block;
    position: relative;
    min-height: 1.3125rem;

    &::before {
      content: '';
      display: inherit;
      width: 1.25rem;
      height: 1.25rem;
      border-top: 0.1875rem solid #ccc;
      border-right: 0.1875rem solid #ccc;
      border-bottom: 0.1875rem solid rgba(0, 0, 0, 0.5);
      border-left: 0.1875rem solid rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      animation: rotate 1.1s linear infinite;  // find animation in keyframes.scss

      position: absolute;
      translate: -50% 0;
      left: 0;
    }
  }
}

// <span> for error message
.drop-area__error {
  &:not([hidden]) {
    color: $danger-color;
    line-height: $line-height-text;
    font-size: 0.875rem;
    font-weight: 700;
    text-align: center;
  }

  svg {
    translate: 0 0.125rem;
    padding: 0 0.3125rem 0 0;
    display: inline-block;
  }
}

// submit
//---------------------------------------------
.drop-area__submit {
  &:not([hidden]) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: $space-30 0 0 0;
  }
}