// CROPPER JS OVERRIDES

// <div> cropper wrapper
//---------------------------------------------
.cropper {
  visibility: hidden;
  height: 0;

  &.is--visible {
    visibility: visible;
    height: auto;
  }
}

// <div> where the actual magic happens
//---------------------------------------------
.cropper-container {
  max-width: 18.75rem;
  max-height: 18.75rem;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
}

// <img> to crop
//---------------------------------------------
.cropper__image {
  max-width: 100%;
}

// <div> inner flex wrapper
//---------------------------------------------
.cropper__inner {
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;

  // if card is bigger than 700 px show the text right of the cropper
  @container card-inline (width > 43.75rem) {
    flex-direction: row;
    gap: 1.875rem; // 30
  }
}

// <div> visual area
.cropper__area {
  flex: 0 1 18.75rem; // 300
  max-width: 100%;
}

// <div> preview image wrapper
.cropper__visual {
  overflow: hidden;

  // disallow mouse pointer on the cropping area
  .is--cropping & {
    pointer-events: none;
  }
}

// <div> container for buttons (zooming, moving)
.cropper__controls {
  margin: $space-5 0 0 0;
  background: $gray-lightest;
  border-radius: var(--border-radius-lg);
  display: grid;
  grid-template-columns: repeat(6,1fr);

  @media(min-width: $breakpoint-sm) {
    gap: 0.3125rem;
    padding: 0.3125rem;
  }
}

// <div> text
.cropper__description {
  flex: 1;
}

// cropper-crop-box
//---------------------------------------------
.cropper-face {
  border-radius: 50%;
}

.cropper-view-box {
  border-radius: 50%;
}

// drag points
//---------------------------------------------
.cropper-point {
  background-color: #fff;
  opacity: 0.95;

  &.point-n,
  &.point-e,
  &.point-s,
  &.point-w {
    opacity: 0;
  }

  &.point-nw,
  &.point-ne,
  &.point-sw,
  &.point-se {
    height: 0.625rem;
    width: 0.625rem;
  }

  &.point-nw {
    left: -0.3125rem;
    top: -0.3125rem;
  }

  &.point-ne {
    right: -0.3125rem;
    top: -0.3125rem;
  }

  &.point-sw {
    left: -0.3125rem;
    bottom: -0.3125rem;
  }

  &.point-se {
    right: -0.3125rem;
    bottom: -0.3125rem;
  }
}

// <div> line
.cropper-line {
  background-color: #fff;
}

// <div> lines within the crop circle
.cropper-dashed {
  opacity: 0;
}

.cropper-view-box {
  outline: none;
}