// SETUP NEW COMMUNITY

// <div> most outer wrapper
//---------------------------------------------
.setup {
  padding: $space-60 $page-padding calc($setup-submit-height + $space-60);
}

// <div> gray box
//---------------------------------------------
.setup-box {
  padding: $space-30;
  background: $gray-lightest;
  border-radius: var(--border-radius-lg);

  &:not([class*="margin--"]) {
    margin-block-end: $space-30;
  }

  svg.is--invalid {
    box-shadow: 0 0 0 0.25rem $danger-color;
  }
}

// <modifier> for sticky image preview
//---------------------------------------------
.setup-box--sticky {
  position: sticky;

  @media (max-width: $breakpoint-lg) {
    top: 0;
  }

  @media (min-width: $breakpoint-lg) {
    top: $header-height;
  }

  // styles for sticky image preview
  &.is--sticky {
    padding-block: $space-10;
    z-index: 1;
    box-shadow: $box-shadow-sm;

    @media (min-width: $breakpoint-lg) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .h3 {
      display: none;
    }

    .canvas-wrapper {
      width: 2.5rem; // 40
      height: 2.5rem; // 40
      margin: 0;
    }
  }
}

// <modifier>
.setup-box--pattern {
  background-image: radial-gradient($gray-light 0.0625rem, transparent 0.0625rem);
  background-size: 0.75rem 0.75rem; // 12 12
  background-color: $gray-lightest;
}

// <div> question
//---------------------------------------------
.setup-question {

}

// <hX>, <label>, <legend>
.setup-question__header {
  width: 100%;
  padding: $space-15 0 $space-50 0;
}

// <hX>
.setup-question__headline {
  font-size: 1.625rem;
  text-align: center;
  max-width: 31.25rem; // 500
  margin-inline: auto;
  word-break: break-word;
}


// <div> success manager
//---------------------------------------------
.setup-help-manager {
  display: flex;
  gap: $space-15;
  background: $gray-lightest;
  padding: $space-15;
  border-radius: var(--border-radius-lg);

  &:not(:only-child) {
    margin-block-start: $space-30;
  }

  // > 500
  @container card (width > 31.25rem) {
    align-items: center;
    flex-direction: row;
  }

  // <= 500
  @container card (width <= 31.25rem) {
    flex-direction: column;
    text-align: center;
  }
}

// <img>
.setup-help-manager__img {
  flex: 0 0 60px;
  width: 60px;
  border-radius: 50%;
  align-self: center;
}

// <div>
.setup-help-manager__info {
  flex: 1;
  display: flex;
  gap: $space-5;
  flex-direction: column;
}

// <h3> name
.setup-help-manager__name {
  line-height: 1.3;
}

// <div> for tel and email
.setup-help-manager__contact {
  display: flex;
  align-items: center;
  gap: $space-5;

  // <= 500
  @container card (width <= 31.25rem) {
    justify-content: center;
  }
}


// transitions
//---------------------------------------------
.setup-transition {
  will-change: transform, opacity;

  &.leave-to-left {
    animation: leaveToLeft 0.5s $transition-timing forwards; // find in keyframes.scss
  }

  &.leave-to-right {
    animation: leaveToRight 0.5s $transition-timing forwards; // find in keyframes.scss
  }

  &.enter-from-left {
    animation: enterFromLeft 0.5s $transition-timing forwards; // find in keyframes.scss
  }

  &.enter-from-right {
    animation: enterFromRight 0.5s $transition-timing forwards; // find in keyframes.scss
  }

  &.bounce-in-left {
    animation: bounceInLeft 1s ease-in-out forwards;
  }
}