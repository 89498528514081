// SKELETON (used for people browser)

// <div> wrapper
//---------------------------------------------
.skeleton {
  opacity: 0.7;
  pointer-events: none;
  margin-block-start: $space-30;
}

// <div> search
//---------------------------------------------
.skeleton__search {
  background: #fff;
  border-radius: var(--border-radius-lg);
  height: 60px;
  padding-inline: $box-padding-lg;
  display: flex;
  align-items: center;
  color: $gray-middle;
}

// <div> match
//---------------------------------------------
.skeleton__match {
  background: #fff;
  border-radius: var(--border-radius-lg);
  padding: $box-padding-lg;
  margin-block-start: 1.875rem;

  // if the outer container is smaller than 330 (px) change the direction to top-down
  @container card (width <= 20.625rem) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.9375rem;
  }

  // if the outer container is bigger than 330 (px) change the direction to left-right
  @container card (width > 20.625rem) {
    display: grid;
    align-items: center;
    column-gap: 1.25rem;
    row-gap: 1.875rem;
    grid-template-columns: 6.25rem 1fr;
    grid-template-rows: auto;
  }
}

.skeleton__match-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: $gray-lightest;
}

.skeleton__match-info {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  // if the outer container is smaller than 330 (px) change the direction to top-down
  @container card (width <= 20.625rem) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
}

.skeleton__match-headline {
  width: 100%;
  height: 25px;
  max-width: 200px;
  background: $gray-lightest;
  border-radius: var(--border-radius-sm);
}

.skeleton__match-text {
  width: 100%;
  height: 20px;
  max-width: 100px;
  background: $gray-lightest;
  border-radius: var(--border-radius-sm);
}