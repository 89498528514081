﻿// COLOR PICKER (Custom + Stimulus)

// <div> container
//---------------------------------------------
.color-picker {
  display: grid;
  gap: $space-15;
  grid-template-columns: 2.5rem 8.125rem; // 40 130
}

// <div> that gets replaced as soon as the color picker is initialized.
// until then at least show a little styling
.color-picker__placeholder {
  width: 2.5rem; // 40
  height: 2.5rem; // 40
  border-radius: var(--border-radius-sm);
  border: 0.0625rem solid $border-color;

  position: relative;

  &::before {
    content: '';
    width: 0.875rem;
    height: 0.875rem;
    border-top: 0.1875rem solid $gray-middle;
    border-right: 0.1875rem solid $gray-middle;
    border-bottom: 0.1875rem solid rgba(69, 76, 86, 0.4);
    border-left: 0.1875rem solid rgba(69, 76, 86, 0.4);
    border-radius: 50%;
    animation: rotate 1.1s linear infinite;  // find animation in keyframes.scss
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }
}

// overrides
//---------------------------------------------
.pickr .pcr-button {
  width: 2.5rem; // 40
  height: 2.5rem; // 40
  border-radius: var(--border-radius-sm);
  border: 0.0625rem solid $border-color;
  overflow: hidden;

  &,
  &::after {
    transition: none;
  }
}

.pickr .pcr-button::before {
  background: none;
}