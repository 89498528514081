// EMAIL

// <div> email wrapper
//---------------------------------------------
.email {
  padding: $space-50 $space-30;
  margin: $space-60 0;
  background: $gray-lightest;
  border-radius: var(--border-radius-lg);

  &.is--preview {
    .label {
      display: none;
    }

    // remove borders and paddings from inputs
    .text-input,
    .text-area {
      border: 0;
      border-radius: 0;
      padding: 0;
      pointer-events: none;
      user-select: none;
      resize: none;

      // css only it is not possible to disallow text-selection in input fields
      // so we set the background to white, when the text is selected, so it seems as you cant select it.
      &::selection {
        background-color: #fff;
      }
    }
  }
}

// <div> top (language and preview) {
.email__top {
  width: 100%;
  max-width: 37.5rem; // 600
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-block-end: $space-30;
  margin-inline: auto;
}

// <div> inner container
.email__inner {
  width: 100%;
  max-width: 37.5rem; // 600
  margin-inline: auto;
  position: relative;
  z-index: 1;

  // add loading animation
  .is--loading & {
    // add overlay and spinner
    &::before,
    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      opacity: 1;
    }

    &::before {
      z-index: 2;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: var(--border-radius-lg);
      background: #fff;
    }

    &::after {
      z-index: 3;

      width: 3.125rem; // 50
      height: 3.125rem; // 50
      border-radius: 50%;
      border: 0.1875rem solid $gray-middle;
      border-top-color: $gray-dark;
      animation: loaderSpinner 1s linear infinite;
      display: inline-block;
      left: 50%;
      top: 50%;
      translate: -50% -50%;
    }
  }
}

// <div> subject
.email__subject {
  flex: 1;
  padding: $space-30;
  margin-block-end: $space-30;
  background: #fff;
  border-radius: var(--border-radius-lg);
}

// <div> content
//---------------------------------------------
.email__card {
  background: #fff;
  border-radius: var(--border-radius-lg);
  padding: $space-30;
  flex: 1;
  display: flex;
  flex-direction: column;

  // remove the spacing between the email <divs> headline, content, salutation
  // if it is in the preview mode. In the preview, the spacings should appear
  // like they would in the email.
  &:not(.is--preview &) {
    gap: $space-30;
  }
}


// input for headline
//---------------------------------------------
.email__headline {
  // restyle it for preview
  .is--preview & {
    font-size: 1.625rem;
    font-weight: 700;
    margin: 0 0 $space-15 0;
  }
}

// wysiwyg for content
//---------------------------------------------
.email__content {

}

// wysiwyg for salutation
//---------------------------------------------
.email__salutation {
  margin-top: auto;

  // restyle it for preview
  .is--preview & {

  }
}