// PILL <div>

// <div> wrapper
//---------------------------------------------
.pills-container {
  display: flex;
  justify-content: inherit;
  flex-wrap: wrap;
  gap: 0.9375rem;
}

// <div> pill
//---------------------------------------------
.pill {
  // match the checkbox chips as far as possible
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  padding: 0.5rem 0.75rem; // 8 12
  border-radius: var(--border-radius-xl);
  background: var(--pill-bg);
  line-height: 1.3;
  font-size: 0.875rem;
}

// <svg> icon
.pill__icon {
  align-self: flex-start;
  fill: $gray-dark;
}

// <span> text
.pill__text {
  align-self: center;
}