// STEPPER

// <ol>
//---------------------------------------------
.stepper {
  --bullet-size: 2.5rem; // 40 default bullet size
  --bullet-line-thickness: 0.3125rem; // 5
  --bullet-line-color: #{$gray-light};
  --bullet-line-spacing: 0.625rem; // 10 spacing of line between bullets
  --bullet-active-color: #{$gray-darkest};
  --stepper-status-color: #{$gray-dark};

  padding: 0;
  list-style: none;
  display: flex;

  // bigger than 700 (horizontal stepper)
  @container card-inline (width > 43.75rem) {
  }

  // smaller than 700 (vertical stepper)
  @container card-inline (width <= 43.75rem) {
    --bullet-line-thickness: 0.1875rem; //
    flex-direction: column;
    gap: $space-30;
  }
}

// <li>
//---------------------------------------------
.stepper__item {
  flex: 1;
  display: flex;
  row-gap: $space-30;
  column-gap: $space-15;
  position: relative;

  // add a line on the right/bottom side of every step.
  // only the last child does not get a line on the right/bottom.
  &:not(:last-child) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      background-color: var(--bullet-line-color);
    }

    &::after {
      --bullet-line-color: var(--bullet-active-color); // dark line color
      transition-duration: 0.5s; // line transition duration (gray to dark)
      transition-timing-function: $transition-timing; // line transition timing (gray to dark)
    }
  }

  // bigger than 700 (horizontal stepper)
  @container card-inline (width > 43.75rem) {
    --bullet-size: 2.5rem; // 40
    align-items: center;
    flex-direction: column;

    // the last child does not get a line on the right side.
    &:not(:last-child) {
      &::before,
      &::after {
        max-width: calc(100% - calc(var(--bullet-size)) - calc(var(--bullet-line-spacing)*2));
        height: var(--bullet-line-thickness);
        top: calc((var(--bullet-size)/2) - (var(--bullet-line-thickness)/2));
        left: calc(50% + calc(var(--bullet-size) / 2) + calc(var(--bullet-line-spacing)));
        border-radius: var(--border-radius-sm);
      }

      &::before {
        width: 100%;
      }

      // do not show the 'dark' line yet
      &::after {
        width: 0;
      }
    }

    // horizontal line animation
    &.is--completed {
      &::after {
        width: 100%;
      }
    }
  }

  // smaller than 700 (vertical stepper)
  @container card-inline (width <= 43.75rem) {
    --bullet-size: 2.1875rem; // 35
    align-items: center;
    justify-content: center;

    // the last child does not get a line on the bottom.
    &:not(:last-child) {
      &::before,
      &::after {
        width: var(--bullet-line-thickness);
        left: calc((var(--bullet-size)/2) - calc(var(--bullet-line-thickness)/2));
        top: calc(50% + calc(var(--bullet-size)/2));
        max-height: 100%;
      }

      &::before {
        height: 100%;
      }

      // do not show the 'dark' line yet
      &::after {
        height: 0;
      }
    }

    // show the active vertical line transition
    &.is--completed {
      &::after {
        height: 100%;
      }
    }
  }

  // step completed
  &.is--completed {
    // override the status color to green, when step is completed
    --stepper-status-color: #{$success-color};

    // show the check icon
    .stepper__icon--success {
      display: block;
    }

    // change the color of the bullet from gray to black
    .stepper__bullet {
      background: var(--bullet-active-color);
      border-color: var(--bullet-active-color);
    }
  }

  // first step in progress
  &:first-child:not(.is--completed) {
    // override the status color to blue, when step is completed
    --stepper-status-color: #{$info-color};

    // show the in progress icon
    .stepper__icon--progress {
      display: block;
    }

    // change the bullet from gray to black with white inset box-shadow
    .stepper__bullet {
      background: var(--bullet-active-color);
    }
  }

  // for the direct sibling of the is--completed
  // add bullet animation to all steps > 1
  &.is--completed + li:not(.is--completed) {
    --stepper-status-color: #{$info-color};

    // show the in progress icon
    .stepper__icon--progress {
      display: block;
    }

    // change the bullet from gray to black with white inset box-shadow
    .stepper__bullet {
      background: var(--bullet-active-color);
      border-color: var(--bullet-active-color);

      // animate the stepper__bullet
      animation-name: pulseStepperBullet;
      animation-duration: 0.6s;
      animation-fill-mode: forwards; // do not loop the animation. just show it once
      animation-delay: 0.3s; // we wait for the line transition to finish
    }
  }
}


// <div> bullet
//---------------------------------------------
.stepper__bullet {
  width: var(--bullet-size);
  height: var(--bullet-size);
  flex: 0 0 var(--bullet-size);
  border-radius: 50%;
  color: #fff;
  background: $gray-light;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  transition-duration: 0.3s;
  transition-timing-function: $transition-timing;
  transition-delay: 0.3s;
}

// <svg> check
.stepper__icon {
  // only shown when step is completed
  display: none;
}

// <a> / <div>
//---------------------------------------------
.stepper__link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex: 1;

  // only add hover styles if it is an <a> or <button>
  &:is(a, button) {
    &:hover,
    &:focus-visible {
      .stepper__headline {
        text-decoration: underline;
        text-decoration-thickness: 0.125rem; // 2px
      }
    }
  }

  // bigger than 700 (horizontal stepper)
  @container card-inline (width > 43.75rem) {
    align-items: center;
    gap: $space-15;
    text-align: center;
  }

  // smaller than 700 (vertical stepper)
  @container card-inline (width <= 43.75rem) {
    align-items: flex-start;
    gap: $space-10;
  }
}

// <hX>
.stepper__headline {
  margin: 0;

  // bigger than 700 (horizontal stepper)
  @container card-inline (width > 43.75rem) {
    font-size: 1.125rem;
  }

  // smaller than 700 (vertical stepper)
  @container card-inline (width <= 43.75rem) {
    font-size: 1rem;
  }
}

// <span> status
.stepper__status {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--stepper-status-color);
}