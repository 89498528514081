// BUTTON <button>

// <button> reset styles
//---------------------------------------------
button {
  background: 0;
  border: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  user-select: none;
  touch-action: manipulation;
  cursor: pointer;

  &[disabled] {
    pointer-events: none;
  }
}

// general button styles
//---------------------------------------------
.btn {
  padding: 0.625rem 2.1875rem;
  border: 0.0625rem solid transparent;
  min-height: 2.5rem;
  line-height: 1.3;
  text-align: center;
  text-decoration: none;
  border-radius: var(--border-radius-sm);
  display: inline-flex;
  gap: 0.625rem; // 10
  align-items: center;
  justify-content: center;
  //flex-wrap: wrap; // break text under e.g. svg on smaller resolutions

  &:hover,
  &:focus {
    text-decoration: none;
  }

  // only add the disabled style to the clicked button.
  // all the other buttons are disabled but dont get this gray style.
  &[disabled]:not(.show--enabled) {
    &,
    &:hover,
    &:focus {
      border-color: $gray-dark;
      background-color: $gray-dark;
      color: #fff;
    }
  }

  // loading animation
  &.is--loading {
    position: relative;

    &::before {
      content: '';
      width: 0.875rem;
      height: 0.875rem;
      border-top: 0.1875rem solid #fff;
      border-right: 0.1875rem solid #fff;
      border-bottom: 0.1875rem solid rgba(255, 255, 255, 0.5);
      border-left: 0.1875rem solid rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      animation: rotate 1.1s linear infinite;  // find animation in keyframes.scss
      position: absolute;
      left: 0.625rem; // 10
    }

    svg {
      opacity: 0;
    }
  }

  &:has(svg:first-child) {
    &.is--loading {
      &::before {
        left: 2.1875rem;
      }
    }
  }

  &.is--hidden {
    display: none;
  }

  svg {
    flex-shrink: 0;
  }
}

// primary
//---------------------------------------------
.btn--primary {
  background: var(--button-bg-color);
  color: var(--button-text-color);
  border-color: var(--button-bg-color);
  font-weight: 700;

  &:focus,
  &:hover {
    color: var(--button-text-color-hover);
    background: var(--button-bg-color-hover);
    border-color: var(--button-bg-color-hover);
  }

  &:active {
    box-shadow: inset 0 -0.0625rem 0.625rem 0 rgba(0, 0, 0, 0.05); // 0.125rem 0.9375rem
  }
}

// secondary
//---------------------------------------------
.btn--secondary {
  background: #fff;
  color: $text-color;
  border-color: $border-color;
  font-weight: 400;

  &:focus,
  &:hover {
    background: $gray-lightest;
  }

  &:active {
    box-shadow: inset 0 -0.0625rem 0.625rem 0 rgba(0, 0, 0, 0.05); // 0.125rem 0.9375rem
  }
}

// tertiary
//---------------------------------------------
.btn--tertiary {
  background: transparent;
  color: currentColor;
  border-color: currentColor;
  font-weight: 400;

  &:focus,
  &:hover {
    background: #fff;
    color: $text-color;
  }

  &:active {
    box-shadow: inset 0 -0.0625rem 0.625rem 0 rgba(0, 0, 0, 0.05); // 0.125rem 0.9375rem
  }
}

// danger
//---------------------------------------------
.btn--danger {
  background: $danger-color;
  color: #fff;
  border-color: $danger-color;
  font-weight: 700;

  &:focus,
  &:hover {
    background: $danger-color-hover;
    border-color: $danger-color-hover;
  }

  &:active {
    box-shadow: inset 0 -0.0625rem 0.625rem 0 rgba(0, 0, 0, 0.05); // 0.125rem 0.9375rem
  }
}

// text style
//---------------------------------------------
.btn-text {
  color: inherit;
  display: inline-flex;
  gap: $space-10;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 0.125rem;
  }

  &[disabled] {
    color: $gray-dark;

    svg {
      opacity: 0.8;
    }
  }
}

// icon only
//---------------------------------------------
.btn-icon {
  min-width: 2.125rem;
  min-height: 2.125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-sm);
  transition: color $transition;
  border: 0.0625rem solid transparent; // used for high contrast mode
  color: $gray-dark;

  &:focus,
  &:hover {
    color: $gray-mm;
  }

  &[disabled] {
    color: $gray-dark;
  }

  // pulsing animation (used on attribute cards with missing information)
  &.is--pulsing {
    animation: pulse 4s infinite; // find animation in _keyframes.scss
    background: $alert-info-bg;
    color: $alert-info-color;

    // reset focus styles to match the basic button focus styles
    &:focus {
      animation: none;
      border-radius: var(--border-radius-sm);
    }
  }
}

// button sizes
//---------------------------------------------
.btn--xs {
  font-size: 0.875rem; // 14
  padding: 0.3125rem 0.625rem; // 5 10
  min-height: 0;
}
