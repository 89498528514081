// ABOUT SECTION

// steps
//---------------------------------------------
.about-steps {
  display: grid;
  gap: 1.875rem;
  justify-items: center;
  width: 100%;

  @media (max-width: $breakpoint-lg) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

// single item
.about__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 35.625rem;
  text-align: center;
  background: var(--card-bg);
  border-radius: var(--border-radius-lg);
  padding: $box-padding-lg;
}

// custom icon svg coffee handle
.about__svg-handle {
  filter: drop-shadow(-0.0625rem 0 0.0625rem $gray-middle);
}

// <h3>
.about__headline {
  font-size: 1.625rem;
  margin: 0 0 0.9375rem 0;
}

// <p>
.about__text {
  margin: $space-30 0 0 0;
  max-width: $container-xs;
  line-height: $line-height-text;
}

