﻿// LABEL <label>

// <label>
//---------------------------------------------
.label {
  color: inherit;
  margin: 0 0 $space-5 0;
  display: flex;
  flex-direction: column;

  // center the label within the wizard
  .wizard--attributes & {
    text-align: center;
    margin: 0 0 $space-30 0;
    width: 100%;
  }
}

// <span> text
//---------------------------------------------
.label__text {
  font-weight: 700;
  line-height: 1.3;

  // make the label text bigger within the wizard
  .wizard--attributes & {
    font-size: 1.625rem;
    text-wrap: balance;
  }
}

// <span> hint
//---------------------------------------------
.label__hint {
  font-size: 0.875rem;
  line-height: $line-height-text;
  max-width: 37.5rem; // 600
  margin-block-start: $space-5; //

  // make the label text bigger within the wizard
  .wizard--attributes & {
    font-size: 1.125rem;
    margin: $space-10 0 0 0; // 10
  }

  // a little workaround for the double dropdown to show the hint text as 'label'
  // check 'DropdownAttribute.php'
  .is--dependant & {
    font-weight: 700;
    text-wrap: balance;
    line-height: 1.3;
    margin: 0;
    font-size: 1rem;

    // change the font size if the 'hint' is within the wizard
    .wizard--attributes & {
      font-size: 1.625rem;
    }
  }
}