// PARTICIPANT IMPERSONATION

// <div> wrapper
// --------------------------------------------------
.impersonation {
  background: $alert-error-bg;
  color: $alert-error-color;
  width: 100%;
  padding-inline: $page-padding;

  @media (max-width: $breakpoint-lg) {
    padding-block: $box-padding-sm;
  }

  @media (min-width: $breakpoint-lg) {
    position: fixed;
    left: 0;
    top: 0;
    z-index: $zindex-impersonation;
    height: $system-message-height;
    padding-block: 0.3125rem;
  }
}

// <div> inner
.impersonation__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.9375rem;
  height: 100%;

  form {
    margin-left: auto;
  }

  // override dropdown logout styling
  .dropdown__link {
    padding: 0;
    &:hover,
    &:focus {
      background: $alert-error-bg;
      color: $alert-error-color;
      text-decoration: underline;
    }
  }
}

// mask <svg>
.impersonation__svg {
  flex: 0 0 24px;
  animation: scaleSVG 1s forwards;
  animation-delay: 1s;
}

@keyframes scaleSVG {
  50% {
    transform: scale(1.6) rotate(10deg);
  }
}

// email <span>
.impersonation__email {
  @media(max-width: $breakpoint-sm) {
    display: none;
  }

  @media(min-width: $breakpoint-sm) {
    display: inline;
  }
}