// RIBBON (used for cards and communities)

// <div> ribbon
//---------------------------------------------
.ribbon {
  display: inline-block;
  background: linear-gradient(to right, #ffde3a, $bright-yellow);
  padding: 0.5rem 1.25rem 0.5rem 0.9375rem; // 8 20 8 15
  font-weight: bold;
  font-size: 0.875rem; // 14
  border-radius: 0 0.625rem 0 0.625rem; // 10
  text-transform: uppercase;
  line-height: 1;

  // community specific placement
  .community & {
    position: absolute;
    right: 0;
    top: 0;
  }

  // community checkbox specific placement
  .community-checkbox & {
    @media (max-width: $breakpoint-sm) {
      top: -3.125rem; // -3.125rem
    }

    @media (min-width: $breakpoint-sm) {
      top: 0;
    }
  }

  // card specific placement (move into right top corner)
  .card__header & {
    position: absolute;
    right: -1.875rem; // -30
    top: -1.875rem; // -30
  }
}

