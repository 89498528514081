﻿// CHIPS (checkbox or radiobutton)

// <div> container
//---------------------------------------------
.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;

  // center the chips when shown in the wizard
  .wizard--attributes & {
    justify-content: center;
  }
}

// <div> chip
//---------------------------------------------
.chip {
  display: inline-block;
  position: relative;
}

// <label>
//---------------------------------------------
.chip__label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1.25rem;
  border-radius: var(--border-radius-xl);
  border: 0.0625rem solid $gray-dark;
  color: $gray-dark;
  font-weight: 700;
  cursor: pointer;
  background: #fff;
  min-height: 2.5rem;
  position: relative;

  // add the check icon via pseudo element but hide it via opacity 0
  &::before {
    opacity: 0;
    content: '';
    position: absolute;
    left: 0.5rem;
    top: 50%;
    width: 0.3125rem;
    height: 0.625rem;
    border: solid $gray-mm;
    border-width: 0 0.125rem 0.125rem 0;
    transform: rotate(45deg) translate(0, -90%);
  }

  &:hover {
    border-color: $gray-mm;
    color: $gray-mm;
  }

  // error style
  .is--invalid & {
    border-color: var(--input-error-border-color);
    background: var(--input-error-bg-color);
  }
}

// <input>
//---------------------------------------------
.chip__input {
  // "hide" the input
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translate(0, -50%);
  left: 0.625rem;
  width: 0.9375rem;
  height: 0.9375rem;
  opacity: 0;
  cursor: pointer;

  // checked
  &:checked ~ .chip__label {
    border-color: $gray-mm;
    color: $gray-mm;
    box-shadow: 0 0 0 0.0625rem $gray-mm inset;
    padding: 0.625rem 0.8125rem 0.625rem 1.6875rem; // 10px 15px 10px 25px

    // show the check icon
    &::before {
      opacity: 1;
    }
  }

  // focus
  &:focus-visible ~ .chip__label {
    box-shadow: $focus-shadow; // 2px
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }

  // readonly
  &[readonly] ~ .chip__label {
    cursor: default;
    pointer-events: none; // disable clicks
  }

  // disabled
  &[disabled] ~ .chip__label {
    background: $gray-dark;
    color: #fff;
    pointer-events: none;
  }

  // disabled and checked
  &[disabled]:checked ~ .chip__label {
    border-color: $gray-dark;

    &::before {
      border-color: #fff;
    }
  }

  // error style
  &[aria-invalid="true"] ~ .chip__label,
  &.is--invalid ~ .chip__label {
    border-color: $danger-color;
  }

  &:focus[aria-invalid="true"] ~ .chip__label,
  &:focus.is--invalid ~ .chip__label {
    border-color: $gray-dark;
  }
}


// <ul> chip list
// used for showing only the values (e.g. in profile view mode), not clickable
//---------------------------------------------
.chips-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

// <li>
.chips-list__item {
  background: #fff;
  color: $gray-mm;
  padding: 0.625rem 1.25rem;
  border-radius: var(--border-radius-xl);
  font-weight: 700;
  border: 0.125rem solid $gray-mm;
  line-height: 1;
}

