﻿// RADIO BUTTON <input type="radio">

// radio <input type="radio">

// <label>
//---------------------------------------------
.radio-label {
  color: inherit;
  display: inline-flex;
  align-items: flex-start;
  gap: 0.625rem;
  line-height: $line-height-text;
  cursor: pointer;
  user-select: none; // make text unselectable

  &:hover {
    .radio__svg {
      color: $gray-mm;
    }
  }
}

// <label> <svg>
//---------------------------------------------
.radio__svg {
  flex: 0 0 1.625rem; // make sure the svg stays 1.625rem wide
  border-radius: 50%;
  color: $gray-dark;
  transform: translateY(-0.125rem);
}

// <svg> <circle>
.radio__bg {
  fill: #fff;
}

// <input> radio
//---------------------------------------------
.radio {
  // hide the radio
  position: absolute;
  width: 1.625rem;
  height: 1.625rem;
  opacity: 0;
  z-index: 1;
  cursor: pointer;

  // hover
  &:hover ~ .radio-label .radio__svg {
    color: $gray-mm;
  }

  // focus
  &:focus-visible ~ .radio-label .radio__svg {
    box-shadow: $focus-shadow, inset 0 0 0 0.125rem $gray-mm;
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }

  // checked
  &:checked ~ .radio-label {
    .radio__bg {
      fill: $gray-mm;
      stroke: $gray-mm;
    }

    .radio__checkmark {
      fill: #fff;
    }
  }

  // disabled
  &:disabled ~ .radio-label {
    pointer-events: none;

    .radio__bg {
      fill: $gray-dark;
      stroke: $gray-dark;
    }
  }

  // error style
  &[aria-invalid="true"] ~ .radio-label,
  &.is--invalid ~ .radio-label {
    .radio__bg {
      fill: $danger-color;
      stroke: $danger-color;
    }
  }
}