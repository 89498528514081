// COMMUNITY SETUP NAVIGATION

// <div>
//---------------------------------------------
.setup-navigation {
  // step line styles
  --step-line-thickness: 0.3125rem; // 5
  --step-line-spacing: 0.9375rem; // 15 spacing of line between steps
  --step-line-color: #{$gray-light};

  // step icon styles
  --step-icon-size: 3.125rem; // 50 default icon size
  --step-icon-bg-color: #{$gray-middle};
  --step-icon-color: #{$gray-dark};

  border-radius: var(--border-radius-sm);
  overflow: hidden;
  width: 100%;
  margin-block-end: 10vh;

  max-width: $container-xl;
  margin-inline: auto;
}


// <ul>
.setup-navigation__list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 0.0625rem; // 1
}

// <li>
.setup-navigation__item {
  position: relative;

  // if you need to target a link of the last step you edited
  //&:not(.is--saved) {
  //  .setup-navigation__link:is(a) {
  //
  //  }
  //}

  @media (min-width: $breakpoint-md) {
    // add a line on the right/bottom side of every step.
    // only the last child does not get a line on the right/bottom.
    &:not(:last-child) {
      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        background-color: var(--step-line-color);

        max-width: calc(100% - calc(var(--step-icon-size)) - calc(var(--step-line-spacing) * 2));
        height: var(--step-line-thickness);
        top: calc((var(--step-icon-size) / 2) - (var(--step-line-thickness) / 2));
        left: calc(50% + calc(var(--step-icon-size) / 2) + calc(var(--step-line-spacing)));
        border-radius: var(--border-radius-sm);
      }

      // the gray line between steps is always visible
      &::before {
        width: 100%;
      }

      // add the colored line, but do not show it if the step is not active or saved
      &::after {
        width: 0;
        --step-line-color: var(--button-bg-color); // colored line color
      }

      &.is--saved {
        &::after {
          width: 100%;
        }
      }
    }
  }
}

// <a>
.setup-navigation__link {
  display: block;
  text-decoration: none;
  color: $gray-mm;
  font-size: 0.875rem; // 14
  text-align: center;

  &:is(a) {
    --step-icon-color: var(--button-text-color);
    --step-icon-bg-color: var(--button-bg-color);

    &:hover {
      --step-icon-color: var(--button-text-color-hover);
      --step-icon-bg-color: var(--button-bg-color-hover);
    }
  }

  // active and saved states (change the icon colors)
  // currently replaced by &:is(a)
  //.is--saved &,
  //.is--active & {
  //  --step-icon-color: var(--button-text-color);
  //  --step-icon-bg-color: var(--button-bg-color);
  //
  //  &:hover {
  //    --step-icon-color: var(--button-text-color-hover);
  //    --step-icon-bg-color: var(--button-bg-color-hover);
  //  }
  //}

  .is--active & {
    font-weight: 700;
  }
}

// <div> wrapper that holds the icon
.setup-navigation__visual {
  background: var(--step-icon-bg-color);
  color: var(--step-icon-color);
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $breakpoint-md) {
    width: var(--step-icon-size);
    height: var(--step-icon-size);
    border-radius: var(--border-radius-lg);
  }

  @media (max-width: $breakpoint-md) {
    width: auto;
    height: var(--step-icon-size);

    .is--active & {
      --step-icon-color: var(--button-text-color-hover);
      --step-icon-bg-color: var(--button-bg-color-hover);
    }
  }
}

// <span> wrapper for <svg>
.setup-navigation__icon {
  fill: var(--step-icon-color);
}

// <span> text
.setup-navigation__text {
  margin: $space-10 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;
  text-align: center;

  @media (max-width: $breakpoint-md) {
    // this is a copy of class .visually-hidden
    position: absolute;
    white-space: nowrap;
    width: 0.0625rem; //1px
    height: 0.0625rem; //1px
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -0.0625rem; //1px
  }
}