// SUB NAVIGATION (Admin Dashboard)

// <div> sub navigation container
.sub-navigation-container {
  container: sub-nav / inline-size;
  position: relative;
}

// inner wrapper <div>
// --------------------------------------------------
.sub-navigation {
  width: 100%;
  display: flex;
  align-items: center;
  padding: $space-30 0 $space-60 0;
  border-top: 0.0625rem solid $border-color-light;
}

// <nav>
// --------------------------------------------------
.sub-navigation__nav {
  width: 100%;
  display: block;
  padding: 0;
}

// <ul>
.sub-navigation__list {
  margin: 0;
  padding: 0.125rem 0;
  list-style: none;
  display: flex;

  // > 700px
  @container sub-nav (width > 43.75rem) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: $space-15;
  }

  // < 700px
  @container sub-nav (width <= 43.75rem) {
    flex-direction: column;
    align-items: stretch;
  }
}

// <li>
// --------------------------------------------------
.sub-navigation__item {
  // > 700 px
  @container sub-nav (width > 43.75rem) {
    &.item--pull-right {
      margin-inline-start: auto;
    }
  }
}

// <a>
// --------------------------------------------------
.sub-navigation__link {
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem;
  text-decoration: none;
  position: relative;
  gap: $space-10;

  &:hover,
  &:focus {
    color: $gray-mm;
  }

  // > 700 px (pill style)
  @container sub-nav (width > 43.75rem) {
    padding: 0.75rem $space-20; // 12 20
    border-radius: var(--border-radius-xl);
    background: $gray-light;

    &:hover,
    &:focus {
      background: $gray-middle;
    }

    // add a visible border below the active item
    &[aria-current='page'],
    &.is--active {
      font-weight: 700;
      background: $gray-darkest;
      color: #fff;
    }
  }

  // < 700 px
  @container sub-nav (width <= 43.75rem) {
    color: $gray-dark;

    &:not(.sub-navigation--back) {
      padding: 0.9375rem; // 15
      border-radius: var(--border-radius-sm);

      &::after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: -0.1875rem; // -3
        right: auto;
        bottom: 0;
        height: 100%;
        border-left: 0.3125rem solid $border-color-light;
        border-radius: 0;
        opacity: 0.5;
      }
    }

    // add a visible left border to the active item
    &[aria-current='page'],
    &.is--active {
      color: $gray-mm;
      font-weight: 700;

      &::after {
        opacity: 1;
        border-color: $gray-mm;
      }
    }
  }
}

// <svg> icon
.sub-navigation__icon {
  flex: 0 0 1rem;
}

// back link
.sub-navigation--back {
  display: flex;
  align-items: center;
  gap: $space-10;

  // < 700 px
  @container sub-nav (width <= 43.75rem) {
    margin: 0 0 $space-30 0;
  }
}