// TEXT AREA <textarea>

// <textarea>
//---------------------------------------------
.text-area {
  appearance: none;
  width: 100%;
  background: #fff;
  border: 0.0625rem solid $border-color;
  border-radius: var(--border-radius-sm);
  max-width: 100%;
  padding: 0.9375rem;
  resize: vertical;
  line-height: $line-height-text;

  // hover & focus
  &:hover,
  &:focus {
    border-color: $gray-mm;
  }

  &:focus {
    // add focus outline for all users (mouse, touch & keyboard)
    box-shadow: $focus-shadow;
  }

  // readonly & disabled
  &[disabled],
  &[readonly] {
    pointer-events: none;
    background: $gray-lightest;
  }

  // error style
  &[aria-invalid="true"],
  &.is--invalid {
    border-color: $danger-color;

    &:focus {
      border-color: $border-color;
    }
  }

  // remove "x" from ie when text is typed
  &::-ms-clear {
    display: none;
  }
}