// SETUP TEMPLATE

// <div> template page grid
//---------------------------------------------
.template-grid {
  display: grid;
  gap: $space-30;

  // if the outer container is bigger than 500 (px) change the direction to left/right
  @container card (width > 31.25rem) {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)); // 320
  }
}

// <div> single template item
.template {
  background: $gray-lightest;
  border-radius: var(--border-radius-lg);
  padding: $space-30;
  position: relative;
  transition: 0.15s $transition-timing;

  &:focus-within,
  &:hover {
    background: $gray-light;
  }

  // todo: as soon as :has() has propper browser support, wen can set the box-shadow like this
  //&:has(:checked) {
  //
  //}
}

// <input type="radio">
.template__input {
  position: absolute;
  width: 1.625rem;
  height: 1.625rem;
  opacity: 0;
  z-index: 1;
  cursor: pointer;

  &:checked ~ label {
    transition: 0.15s $transition-timing;
    box-shadow: 0 0 0 0.25rem $gray-darkest;
  }
}

// <label>
.template__label {
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: var(--border-radius-lg);
  z-index: 2;

  .is--invalid & {
    box-shadow: 0 0 0 0.25rem $danger-color;
  }
}

.template__item {

}

.template__header {
  display: flex;
  align-items: center;
  gap: 0.9375rem;
}

.template__image {
  align-self: flex-start;
  border-radius: var(--border-radius-lg);
}

.template__description {
  margin: $space-20 0 0 0;
  line-height: $line-height-text;
}


// <div> tile selection (radio buttons)
//---------------------------------------------
.tile-selection {
  display: grid;

  // > 700px
  @container card (width > 43.75rem) {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: $space-40;
  }

  // <= 700px
  @container card (width <= 43.75rem) {
    gap: $space-15;
  }
}

.tile-selection__item {
  position: relative;
}

// <input type="radio">
.tile-selection__input {
  position: absolute;
  width: 1.625rem;
  height: 1.625rem;
  opacity: 0;
  z-index: 1;
  cursor: pointer;

  &:checked ~ label {
    transition: 0.15s $transition-timing;
    background: var(--button-bg-color);
    color: var(--button-text-color);
    transform: scale(1.03);
  }
}

// <label>
.tile-selection__label {
  -webkit-tap-highlight-color: transparent;
  gap: $space-10;
  height: 100%;
  cursor: pointer;
  background: $gray-lightest;
  border-radius: var(--border-radius-lg);
  padding: $space-30;
  transition: 0.15s $transition-timing;

  .is--invalid & {
    box-shadow: 0 0 0 0.25rem $danger-color;
  }

  &:focus,
  &:hover {
    background: $gray-light;
  }

  // > 700px
  @container card (width > 43.75rem) {
    display: grid;
    justify-items: center;
    text-align: center;
    grid-template-rows: auto auto 1fr;
  }

  // <= 700px
  @container card (width <= 43.75rem) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

// <label> <span> name
.tile-selection__name {
  padding-block-start: 0.125rem;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: $line-height-text;
}

// <label> <span> description
.tile-selection__description {
  line-height: $line-height-text;
  font-size: 0.875rem;
  flex: 0 0 100%;
}

// time-frame
//---------------------------------------------
.time-frame {
  display: grid;
  gap: $space-30;

  // > 700px
  @container card (width > 43.75rem) {
    grid-template-columns: 2fr 1fr 2fr;
  }
}

// <div> item with selects
.time-frame__item {

}

// <div> wrapper for selects and ':'
.time-frame__selects {
  display: flex;
  align-items: center;
  gap: $space-10;
}

// <span> ':'
.time-frame__colon {
  font-size: 1.25rem;
  font-weight: 700;
}

// <span> -------------
.time-frame__line {
  // > 700px
  @container card (width > 43.75rem) {
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: calc(#{$input-height}/2);
      height: 0.125rem;
      background: $gray-middle;
    }
  }

  // <= 700px
  @container card (width <= 43.75rem) {
    display: none;
  }
}

// <div> icon picker (for community image)
//---------------------------------------------
.icon-picker {
  display: flex;
  align-items: center;

  @container card (width <= 31.25rem) {
    flex-direction: column;
  }
}

.icon-picker__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: $space-10;
  padding-block: $space-15;
}

.icon-picker__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem; // 40
  height: 2.5rem; // 40

  background: $gray-middle;
  border-radius: var(--border-radius-sm);

  &.is--active {
    box-shadow: 0 0 0 0.1875rem $gray-darkest;
  }

  &:hover {
    box-shadow: 0 0 0 0.125rem $gray-darkest;
  }
}

// <div> summary
//---------------------------------------------
.summary {
  list-style: none;
  margin-block-end: $space-50;
}

// <li>
.summary__item {
  display: grid;
  padding: $space-15;
  border-radius: var(--border-radius-lg);
  line-height: $line-height-text;

  &:nth-child(odd) {
    background: $gray-lightest;
  }

  // > 500px
  @container card (width > 31.25rem) {
    grid-template-columns: 1fr 2fr 1fr;
    gap: $space-30;
  }

  // <= 500px
  @container card (width <= 31.25rem) {
    gap: $space-15;
    flex-direction: column;
  }
}

// <div> title
.summary__title {
  font-weight: 700;
}

// <div> value
.summary__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20rem; // 320
}

// <span> info
.summary__info {
  display: inline-flex;
  align-items: center;
  gap: $space-5;
  color: $info-color;
}

// <a> link
.summary__link {
  // > 500px
  @container card (width > 31.25rem) {
    margin-inline-start: auto;
  }
}

// <div> submit
//---------------------------------------------
.setup-submit {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-navigation;
  background: #fff;
  padding-inline: $space-30;
  height: $setup-submit-height;
  box-shadow: 0 -0.625rem 2.5rem -10px rgba(0, 0, 0, 0.1);
}

// <div> inner container
.setup-submit__inner {
  max-width: $container-md;
  margin-inline: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: $space-30;
  height: 100%;

  &:has(.btn:nth-child(2)) {
    justify-content: space-between;
  }
}

// <span> text
.setup-submit__text {
  @media (max-width: $breakpoint-sm) {
    // this is a copy of class .visually-hidden
    position: absolute;
    white-space: nowrap;
    width: 0.0625rem; //1px
    height: 0.0625rem; //1px
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -0.0625rem; //1px
  }
}