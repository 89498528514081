// SEARCH

// <div> wrapper
//---------------------------------------------
.search {
}

// <div> search bar
//---------------------------------------------
.search__bar {
  display: flex;
  gap: 0.9375rem;

  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
  }

  @media (min-width: $breakpoint-sm) {
    flex-direction: row;
  }
}


// <div> search field
//---------------------------------------------
.search__field {
  flex: 1;
  display: flex;
  gap: 0.625rem;
  position: relative;
}

.search__label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  color: $gray-dark;
}

// <svg> magnifier icon
.search__icon {

}

// <input>
.search__input {
  padding-inline: 3.75rem;
  height: 3.75rem; // 60
  border-radius: var(--border-radius-lg);
  font-size: 1.25rem;
}

.search__submit {

}

// <button> 'x' clear search
.search__clear {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  min-width: 3.75rem;

  border-radius: var(--border-radius-sm) var(--border-radius-xl) var(--border-radius-xl) var(--border-radius-sm);

  &.is--visible {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// <div> current search
//---------------------------------------------
.search__current {
  margin: $space-30 0 0 0;
}