// SVG ANIMATIONS <svg>

// success animation
//---------------------------------------------
@keyframes successFadeIn {
  0% {
    opacity: 0;
    transform: none;
  }
  50% {
    opacity: 0.5;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.svg-success {
  position: relative;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  animation-delay: 0s;
  animation-name: successFadeIn;
  transform-origin: center;
}


// <rect> animation
@keyframes fillSuccessRect {
  from {
    height: 0;
  }

  to {

  }
}

.svg-success__check-left {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-delay: 0.4s;
  animation-name: fillSuccessRect;
}

.svg-success__check-right {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-delay: 0.6s;
  animation-name: fillSuccessRect;
}