// SUCCESS MANAGER CONTACT

// <div> wrapper
//---------------------------------------------
.success-manager {
  display: flex;
  gap: 1.875rem;

  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    text-align: center;
  }

  @media (min-width: $breakpoint-md) {
    align-items: center;
    flex-direction: row;
  }
}

// <img>
.success-manager__img {
  flex: 0 0 10.625rem;
  width: 10.625rem;
  border-radius: 50%;
  align-self: center;
}

// <div>
.success-manager__info {
  flex: 1;
  display: flex;
  gap: $space-20;
  flex-direction: column;
}

// <h3> name
.success-manager__name {
  font-size: 1.625rem;
  line-height: 1.3;

  @media (min-width: $breakpoint-md) {
    padding: 0 3.125rem 0 0; // create space for the icon
  }
}

// <div> for tel and email
.success-manager__contact {
  display: flex;
  align-items: center;
  gap: 0.625rem;

  @media (max-width: $breakpoint-md) {
    justify-content: center;
  }
}