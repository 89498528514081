﻿// FORM STYLES & LAYOUT

// row
//---------------------------------------------
.form__row {
  &:not([hidden]) {
    display: grid;
  }

  gap: 0.625rem;
  grid-template-columns: repeat(1, minmax(0, 1fr)); // minmax prevents text overflowing

  // add spacing bottom but not on the last child
  &:not(:last-child) {
    margin: 0 0 1.25rem 0; // fieldset has the same spacing
  }

  // if it is inside a community, add background and padding to it
  .community-question & {
    background: $gray-lightest;
    border-radius: var(--border-radius-lg);
    padding: $box-padding-lg;
  }

  // remove the styles if it is within a fieldset
  .community-question .fieldset & {
    background: unset;
    border-radius: unset;
    padding: unset;
  }

  // double dropdown styles for dependant row
  &.is--dependant {
    position: relative;
    margin-block-start: $space-100;
    background: $gray-lightest;
    border-radius: var(--border-radius-lg);
    padding: $space-20;
    transition: $transition;
    opacity: 1;

    &::before {
      content: '';
      width: 0.25rem; // 4
      height: 3.125rem; // 50
      position: absolute;
      left: 50%;
      bottom: 100%;
      translate: -50% -1.5625rem; // -25
      background: $gray-light;
      border-radius: 0.3125rem; // 5
    }

    // if it is disabled, show slightly transparent via opacity
    &.is--disabled {
        opacity: 0.5;
    }
  }

  .setup-question & {
    &:not(:only-child) {
      margin-block-end: $space-50;
    }
  }

  .setup-box & {
    &:last-child {
      margin-block-end: $space-15;
    }
  }
}

// <div> group of multiple items, inputs, selects, ...
.form__group {
  display: flex;
  gap: $space-15;

  // <= 500
  @container card (width > 31.25rem) {
    align-items: center;
  }

  // <= 500
  @container card (width <= 31.25rem) {
    flex-direction: column;
  }
}

// add more spacing between the legend and the choice lists (checkboxes & radio buttons)
legend + .form__row {
  margin: $space-10 0 0;
}

// wizard specific modifications
.wizard--attributes {
  legend + .form__row {
    width: fit-content; // center choice lists (checkboxes & radio buttons) so they are aligned with the legend
  }

  .form__row {
    margin-inline: auto;
    max-width: 23.75rem; // 380
  }

  // center the error message so it is aligned with the form field
  .field__error {
    align-items: center;
    padding: $space-15 0 0 0;

    > div {
      display: inline-flex;
      text-align: center;
    }
  }
}

// submit container (button)
// info: styles also used on wizard__navigation
//---------------------------------------------
.form__submit {
  display: flex;
  gap: 0.9375rem;
  margin: $space-40 0 0 0;

  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    align-items: stretch;
  }

  @media (min-width: $breakpoint-sm) {
    align-items: center;
    flex-direction: row;
  }

  .btn {
    flex: 1 1 50%;
  }

  :only-child {
    flex: 0 0 100%;
  }
}

// <modifier>
.form__submit--inline {
  justify-content: flex-start;

  .btn {
    flex: 0 0 auto;
  }
}

// field error
//---------------------------------------------
.field__error {
  display: flex;
  align-items: flex-start;
  flex-direction: column; // just in case there is more than one error shown (e.g. blank and email format)
  gap: 0.3125rem;
  color: $danger-color;
  line-height: $line-height-text;
  font-size: 0.875rem;

  // add extra margin if the error is shown within a fieldset.
  .fieldset & {
    margin: $space-5 0 0 0;
  }

  // select all direct child divs
  > div {
    display: inline-flex;
    align-items: flex-start;
    gap: 0.3125rem;
  }

  .label + & {
    margin: 0 0 $space-5 0; // add spacing to field error that follows a label
  }

  .setup-question & {
    align-items: center;
  }
}

// <svg>
.field__error-svg {
  transform: translate(0, 0.125rem);
  flex: 0 0 0.3125rem;
}

// <span>
.field__error-text {

}

// additional links (e.g. below login or registration)
//---------------------------------------------
.form-addition {
  margin: $space-60 0 0 0;
  display: flex;
  flex-direction: column;
  gap: $space-5;
  font-size: 0.875rem;
}