// PAUSE INFO (shown when used is currently paused)

// wrapper <div>
//---------------------------------------------
.pause-info {
  width: 100%;
  background: #aedff6;
  color: #0d5577;
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.625rem;
  padding: $space-15;
}

// <svg>
.pause-info__icon {
  flex: 0 0 1.5rem;
  width: 1.5rem;
  max-width: 1.5rem;
  height: 1.5rem;
}

// <div>
.pause-info__details {
  line-height: $line-height-text;
  min-height: 1.5rem;
}

// <a>
.pause-info__link {
  text-decoration: none;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
}