﻿// CHECKBOX <input type="checkbox">

// <label>
//---------------------------------------------
.checkbox-label {
  color: inherit;
  display: inline-flex;
  align-items: flex-start;
  gap: 0.625rem;
  line-height: $line-height-text;
  cursor: pointer;
  user-select: none; // make text unselectable

  &:hover {
    .checkbox__svg {
      color: $gray-mm;
    }
  }
}

// <label> <svg>
//---------------------------------------------
.checkbox__svg {
  flex: 0 0 1.625rem; // make sure the svg stays 1.625rem wide
  border-radius: var(--border-radius-sm);
  border: 1px solid $gray-dark;
  color: $gray-dark;
  transform: translateY(-0.125rem);
}

// <svg> <rect>
.checkbox__bg {
  fill: #fff;
}

// <input> checkbox
//---------------------------------------------
.checkbox {
  // hide the checkbox
  position: absolute;
  width: 1.625rem;
  height: 1.625rem;
  opacity: 0;
  z-index: 1;
  cursor: pointer;

  // hover
  &:hover ~ .checkbox-label .checkbox__svg {
    border-color: $gray-mm;
    color: $gray-mm;
  }

  // focus
  &:focus-visible ~ .checkbox-label .checkbox__svg {
    border-color: $gray-mm;
    box-shadow: $focus-shadow, inset 0 0 0 0.125rem $gray-mm;
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }

  // checked
  &:checked ~ .checkbox-label {
    .checkbox__svg {
      border-color: $gray-mm;
    }

    .checkbox__bg {
      fill: $gray-mm;
      stroke: $gray-mm;
    }

    .checkbox__checkmark {
      stroke: #fff;
    }
  }

  // disabled
  &:disabled ~ .checkbox-label {
    pointer-events: none;

    .checkbox__bg {
      fill: $gray-dark;
      stroke: $gray-dark;
    }
  }

  // error style
  &[aria-invalid="true"] ~ .checkbox-label,
  &.is--invalid ~ .checkbox-label {
    .checkbox__bg {
      fill: $danger-color;
      stroke: $danger-color;
    }
  }
}
