// DATA TABLE

// different grid columns options, depending on context
//---------------------------------------------

// participants table in admin cockpit
.dt-grid--participants {
  --data-table-grid-columns: 3.125rem 17.5rem repeat(auto-fit, minmax(6.25rem, 1fr)); // 50 280 repeat
}

// attribute table in insights
.dt-grid--attributes {
  --data-table-grid-columns: repeat(auto-fit, minmax(6.25rem, 1fr)); // 280 repeat
}

// access list table in admin cockpit
.dt-grid--access-list {
  --data-table-grid-columns: 3.125rem 2fr 1fr 1fr 1fr; // 50 280 repeat
}

// <div> wrapper
//---------------------------------------------
.data-table {

}

// <div> actions
//---------------------------------------------
.dt-header {
  background: $gray-lightest;
  border-radius: var(--border-radius-lg);
  padding: $space-20;
  display: flex;
  gap: 1.875rem;

  @container card (width > 50rem) {
    align-items: center;
    justify-content: space-between;
  }

  @container card (width <= 50rem) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

// search
//---------------------------------------------
.dt-search {
  display: flex;
  position: relative;

  @container card (width > 50rem) {
    flex: 0 1 21.875rem;
  }

  @container card (width <= 50rem) {
    flex: 1;
  }
}

// <label>
.dt-search__label {
  position: absolute;
  left: 0;
  top: 0;
  color: $gray-dark;
  height: 100%;
  width: 2.5rem; // 40
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

// <input>
.dt-search__input {
  padding-inline: $space-40;
}

// <button> 'x' clear
.dt-search__clear {
  display: none;

  &.is--visible {
    display: inline-flex;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border-radius: var(--border-radius-sm);
    min-width: 2.5rem; // 40
  }
}

// actions (export, delete)
//---------------------------------------------
.dt-actions {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: $space-30;

  @container card (width <= 31.25rem) {
    flex-direction: column;
  }
}

// <div> sorter
//---------------------------------------------
.dt-sorter {
  border: 0.0625rem solid $border-color-light;
  border-radius: var(--border-radius-lg);

  @container card (width > 50rem) {
    display: grid;
    grid-template-columns: var(--data-table-grid-columns);
    gap: 0.625rem;
    padding: 0.9375rem;
  }

  @container card (width <= 50rem) {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
    margin-block-end: $space-30;
    padding: 0.9375rem;
  }
}

// <div> (one row)
.dt-sorter__item {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-size: 0.875rem;

  @container card (width <= 50rem) {
    &.sort--community {
      display: none;
    }
  }

  @container card (width <= 21.875rem) {
    justify-content: space-between;
    width: 100%;
  }
}

// <label>
.dt-sorter__select-all {
  @container card (width > 50rem) {
    // this is a copy of class .visually-hidden
    position: absolute;
    white-space: nowrap;
    width: 0.0625rem; //1px
    height: 0.0625rem; //1px
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -0.0625rem; //1px
  }
}

// <button>
.dt-sorter__button {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-weight: 700;
  width: 100%;
  text-align: left;
  line-height: 1.3;

  &:has(.is--active) {
    text-decoration: underline;
    text-underline-offset: 0.125rem;
  }

  @container card (width > 50rem) {
    .dt-value--right & {
      justify-content: flex-end;
    }
  }

  @container card (width <= 50rem) {
    justify-content: space-between;
  }
}

// <svg>
.dt-sorter__icon {
  flex: 0 0 0.9375rem;
}

// <ul> list of entries
//---------------------------------------------
.dt-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

// <li> (one row)
//---------------------------------------------
.dt-list__item {
  border-radius: var(--border-radius-lg);

  &:nth-child(even)  {
    background: $gray-lightest;
  }
}

// <modifier> for responsive behaviour
.dt-item--default {
  @container card (width > 50rem) {
    display: grid;
    grid-template-columns: var(--data-table-grid-columns);
    gap: 0.625rem;
    align-items: center;
    padding: 0.9375rem;
  }

  @container card (width <= 50rem) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.25rem;
    row-gap: 0.9375rem;
    padding: 1.25rem 0.9375rem;
    background: $gray-lightest;

    &:not(:last-child) {
      margin: 0 0 $space-30 0;
    }
  }
}

// <modifiers>
//---------------------------------------------
.dt-value--right {
  text-align: right;
}

.dt-value--top {
  align-self: flex-start;
}

// <div> checkbox
//---------------------------------------------
.dt-list__check {
  position: relative;
  padding-block-start: 0.3125rem;
}

// <div> cell
//---------------------------------------------
.dt-list__cell {
  @container card (width <= 50rem) {
    // on smaller card width show the "label" on the left side and the value on the right side.
    flex: 0 0 100%;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @container card (width <= 21.875rem) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
  }
}

// <span> label
//---------------------------------------------
.dt-list__cell-label {
  font-weight: 700;

  @container card (width > 50rem) {
    display: none;
  }
}

// <span> value
//---------------------------------------------
.dt-list__cell-value {
  display: inline-flex;
  align-items: center;
  gap: $space-5;
}

// <div>
//---------------------------------------------
.dt-empty {
  font-weight: bold;
  text-align: center;
  margin: $space-10 0 0 0;
  padding: $space-30 $space-15;
}