// COMMUNITY


// <div> wrapper (landingpage)
//---------------------------------------------
.community-container {
  width: 100%;
  margin-inline: auto;
  gap: 1.875rem;

  @media (max-width: $breakpoint-lg) {
    max-width: $container-sm;
  }

  // if there are less than 3 items show them stacked
  &:not(.is--grid) {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-lg) {
      max-width: $container-md;
    }
  }

  // if there is more than 3 items, show the communities as grid
  &.is--grid {
    display: grid;

    @media (min-width: $breakpoint-lg) {
      grid-template-columns: repeat(2, 1fr);
      max-width: $container-lg;
    }
  }
}

// <div> skeleton
.community-skeleton {
  @media (min-width: $breakpoint-lg) {
    border: 0.125rem solid $border-color-light;
    border-radius: var(--border-radius-lg);
  }
}

// <div> wrapper for community-checkbox
//---------------------------------------------
.community-checkbox {
  // set colors for community
  --community-bg: $gray-lightest;
  --pill-bg: #fff;

  position:relative;
  background: $gray-lightest;
  border-radius: var(--border-radius-lg);
  margin: 1.875rem 0 0 0;

  &.is--highlight {
    box-shadow: $box-shadow-highlight;
  }

  @media (max-width: $breakpoint-sm) {
    padding: 3rem 0 0 0;
    --checkboxPos: 1.875rem 2.1875rem;
  }

  @media (min-width: $breakpoint-sm) {
    padding: 0 0 0 2.5rem;
    --checkboxPos: 1.375rem 2.4375rem; // 30 39
  }

  // adapt styles for this kind of checkbox

  // <input> & <svg>
  .checkbox,
  .checkbox__svg {
    left: 0;
    top: 0;
    translate: var(--checkboxPos);
  }

  // <label>
  .checkbox-label {
    // position the label on top of the community card
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

// <div> join page details block
.community-join-details {
  margin-block-start: $space-60;
  padding-block-start: $space-30;
  border-top: 0.0625rem solid $border-color-light;
}

// <div> community
//---------------------------------------------
.community {
  background: var(--community-bg);
  border-radius: var(--border-radius-lg);
  padding: $box-padding-lg;
  position: relative;

  &:not(.community-checkbox &) {
    &.is--highlight {
      box-shadow: $box-shadow-highlight;
    }
  }
}

// <div> header
//---------------------------------------------
.community__header {
  display: flex;
  align-items: center;
  gap: 0.9375rem;
  width: 100%;

  .is--highlight & {
    font-weight: 700;
    color: $gray-darkest;
    padding-inline-end: 3.75rem; // 60
  }
}

// <img>
.community__image {
  align-self: flex-start;
  border-radius: var(--border-radius-lg);
}

// <hX> name as headline
.community__headline {
  align-self: center;

  @media (max-width: $breakpoint-sm) {
    font-size: 1.25rem; // 20
  }

  @media (min-width: $breakpoint-sm) {
    font-size: 1.625rem; // 26
  }

  h1, h2, h3, h4, span, p {
    font-size: inherit;
    font-weight: inherit;
    line-height: 1.3;
  }
}

// <div> objective
//---------------------------------------------
.community__objective {
  margin: 1.25rem 0 0 0;
}

// <div> details
//---------------------------------------------
.community__details {
  margin: 1.25rem 0 0 0;
}

// <div> headline above community attributes
//---------------------------------------------
.community-question__headline {
  margin: 0 0 1.25rem 0;
}


// community overview in admin dashboard
//---------------------------------------------
.community-grid {
  display: grid;
  gap: 1.875rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)); // 320
}

// <div> facts in card
.community-facts {

}

.community-facts__header {
  margin: 0 0 1.875rem 0;
}

.community__fact {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  color: $gray-dark;

  &:not(:last-child) {
    margin: 0 0 0.9375rem 0;
  }

  &.is--highlight {
    font-weight: 700;
    color: $text-color;
  }
}

// <div> buttons
.community__actions {
  display: flex;
  gap: $space-10;
  flex-wrap: wrap;
}

// community selection for 'import participants'
//---------------------------------------------
.community-selection {
  display: grid;
  gap: 1.875rem;

  // if the outer container is bigger than 500 (px) change the direction to left/right
  @container card (width > 31.25rem) {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)); // 320
  }
}

// <div> item
.community-selection__item {
  display: flex;
  background: $gray-lightest;
  border-radius: var(--border-radius-lg);

  &:hover,
  &:focus-within {
    background: $gray-light;
  }
}

// <label>
.community-selection__label {
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  padding: $space-15;
  width: 100%;
}

// <svg>
.community-selection__svg {
  transform: none;
}

// <img>
.community-selection__image {
  border-radius: var(--border-radius-sm);
  margin-inline-start: $space-10;
}

// <span> for both texts
.community-selection__text {
  flex: 1;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: $space-10;
}

// <div> share community
//---------------------------------------------
.share-community {

}

// <button>
.share-community__button {
  position: relative;
  display: inline-flex;
  gap: $space-10;
  border-radius: var(--border-radius-xl);
  background: var(--button-bg-color);
  color: var(--button-text-color);

  &:hover,
  &:focus {
    background: var(--button-bg-color-hover);
    color: var(--button-text-color-hover);
  }

  // > 700px
  @container card-inline (width > 43.75rem) {
    padding: 0.75rem $space-15; // 12
  }

  // < 700px
  @container card-inline (width <= 43.75rem) {
    width: 2.125rem;
    height: 2.125rem;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
}

// share <svg> in button
.share-community__icon {
  .is--success & {
    display: none;
  }
}

// check <svg> in button
.share-community__success {
  &:not(.is--success &) {
    display: none;
  }
}

// <span> text
.share-community__text {
  // move the text out of the visible area
  @container card-inline (width <= 43.75rem) {
    // this is a copy of class .visually-hidden
    position: absolute;
    white-space: nowrap;
    width: 0.0625rem; //1px
    height: 0.0625rem; //1px
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -0.0625rem; //1px
  }
}
