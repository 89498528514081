// INVITE LINK

// <div> wrapper
//---------------------------------------------
.invite {
  display: flex;
  gap: $space-15;

  // < 700
  @container card (width <= 43.75rem) {
    flex-direction: column;
  }
}

.invite__group {
  flex: 1;
  display: flex;
  position: relative;
}

// <label>
.invite__label {
  position: absolute;
  left: 0;
  top: 0;
  width: 3.125rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-dark;
}

// <input>
.invite__input {
  padding-inline-start: 3.125rem; // 50
  min-height: 100%;
}

// <button>
.invite__button {
  position: relative;
}

// <svg> in button
.invite__svg {
  position: absolute;
  left: 0.625rem;
  top: 50%;
  translate: 0 -50%;
  transition: $transition;
  opacity: 0;

  .is--success & {
    opacity: 1;
  }
}