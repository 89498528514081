// CARD USER (on dashboard)

// <div> card user
//---------------------------------------------
.user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.875rem;
  position: relative;
  margin: 0 auto;

  // smaller than 390
  @container card (width > 24.375rem) {
    flex-direction: row;
    max-width: none;
    text-align: left;
  }

  // bigger than 390
  @container card (width <= 24.375rem) {
    text-align: center;
    flex-direction: column;
  }
}

// <img>
.user__img {
  flex: 0 0 7.5rem; // 120
  width: 7.5rem; // 120
  border-radius: 50%;
}

// <div>
.user__info {
  flex: 1;
  display: flex;
  gap: 0.9375rem;
  flex-direction: column;
}

// name
.user__name {
  font-size: 1.625rem;
  line-height: 1.3;
}

// <a>
.user__link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

// details
.user__details {
  flex: 0 0 100%;

  // smaller than 390
  @container card (width <= 24.375rem) {
    justify-content: center;
  }
}