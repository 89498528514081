// TABLE <table>

// <div> wrapper
//---------------------------------------------
.table-container {
  overflow-x: auto;
  min-height: 0.01%;
  width: 100%;
  overflow-y: hidden;
  border: 0.0625rem solid $border-color-light;
  border-radius: var(--border-radius-lg);
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

// <table>
//---------------------------------------------
.table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  //table-layout: fixed;

  tr {
    &:nth-child(even) {
      //background: $gray-lightest;
    }
  }

  th,
  td {
    padding: 0.9375rem;
    vertical-align: top;

    // if it has class has--error or has--warning, ...
    &[class*="has--"] {
      padding: $space-15 $space-5;
    }
  }

  th {
    text-align: left;
    border: 0;
    border-bottom: 0.0625rem solid $border-color-light;
  }

  td {
    line-height: $line-height-text;
    border-top: 0.0625rem solid $border-color-light;

    &[scope="row"] {
      font-weight: 700;
    }
  }
}

// set a fixed with to the first column for index
.table__index {
  width: 4.375rem; // 70
}

// message for errors or warnings within a table cell
.table__message {
  font-size: 0.75rem;
  min-width: 12.5rem; // 200
  font-weight: 400;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    gap: $space-5;
  }

  svg {
    flex-shrink: 0;
    align-self: flex-start;
    translate: 0 0.1875rem; // 3
  }
}

// <svg> for status
//---------------------------------------------
.table__icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table__icon {
  width: 30px; // 30
  height: 30px; // 30
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-sm);
}

.table__icon--error {
  color: $alert-error-color;
  background: $alert-error-bg;
}

.table__icon--warning {
  color: $alert-warning-color;
  background: $alert-warning-bg;
}

.table__icon--info {
  color: $alert-info-color;
  background: $alert-info-bg;
}

// <td> <div> table cell value
//---------------------------------------------
.table__value {
  border-radius: var(--border-radius-sm);

  &[class*="has--"] {
    padding: $space-10;
    font-weight: 700;
  }

  &.has--info {
    color: $alert-info-color;
    background: $alert-info-bg;
  }

  &.has--warning {
    color: $alert-warning-color;
    background: $alert-warning-bg;
  }

  &.has--error {
    color: $alert-error-color;
    background: $alert-error-bg;
  }
}