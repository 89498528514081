// TEXT INPUT <input type="text, url, ...">

// wrapper
//---------------------------------------------
.text-input {
  appearance: none;
  width: 100%;
  background: #fff;
  border: 0.0625rem solid $border-color;
  border-radius: var(--border-radius-sm);
  padding: 0 0.9375rem;
  height: $input-height;

  // hover & focus
  &:hover,
  &:focus {
    border-color: $gray-mm;
  }

  // readonly & disabled
  &[disabled],
  &[readonly] {
    background: $gray-lightest;
    pointer-events: none;
  }

  // error style
  // &:user-invalid,
  &[aria-invalid="true"],
  &.is--invalid {
    border-color: $danger-color;

    &:focus {
      border-color: $border-color;
    }
  }

  // remove "x" from ie when text is typed
  &::-ms-clear {
    display: none;
  }

  // make "password circles" appear bigger than they are when using the web font
  &[type="password"] {
    font-family: $base-font;
  }
}

// placeholder color
//---------------------------------------------
input::placeholder {
  color: $gray-dark;
}

// modifier
.input--code {
  font-weight: 700;
  letter-spacing: 0.3125rem;
  font-size: 1.625rem; // 26
}