// FILTER buttons

// <div> wrapper
// --------------------------------------------------
.filter {
  display: flex;
  flex-wrap: wrap;
  gap: $space-10;
}

// <button>
// --------------------------------------------------
.filter__btn {
  padding: 0.625rem 1.25rem;
  border-radius: var(--border-radius-xl);
  color: $gray-darkest;
  background: $gray-light;
  border: 0.0625rem solid transparent;

  &:not(.is--active) {
    border-color: $gray-middle;

    &:hover,
    &:focus {
      background: $gray-middle;
    }
  }

  &.is--active {
    // used from .button--primary
    display: inline-flex;
    align-items: center;
    gap: $space-5;

    background: var(--button-bg-color);
    color: var(--button-text-color);
    border-color: var(--button-bg-color);
    font-weight: 700;

    &:focus,
    &:hover {
      color: var(--button-text-color-hover);
      background: var(--button-bg-color-hover);
      border-color: var(--button-bg-color-hover);
    }

    &:active {
      box-shadow: inset 0 -0.0625rem 0.625rem 0 rgba(0, 0, 0, 0.05); // 0.125rem 0.9375rem
    }
  }
}

// <svg> check icon
// --------------------------------------------------
.filter__check {
  &:not(.is--active &) {
    display: none;
  }
}