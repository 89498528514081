// IFRAME

// <div> wrapper
//---------------------------------------------
.iframe-wrapper {
  position: relative;
}

// 16:9 aspect ratio
.iframe--16to9 {
  padding-bottom: 56.25%;
}

// 4:3 aspect ratio
.iframe--4to3 {
  padding-bottom: 75%;
}

// <iframe>
.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
