// IMPORT PARTICIPANTS


// <div> singe instruction
//---------------------------------------------
.instruction {
  &:not(:first-child) {
    margin: $space-60 0 0 0;
  }
}

// <hX> headline
//---------------------------------------------
.instruction__headline {
  display: flex;
  align-items: center;
  gap: $space-15;
  font-size: 1rem;
  margin: 0 0 $space-20 0;
}

// <span> in headline (1) (2) (3), ...
.instruction__step {
  flex: 0 0 1.875rem; // 30
  align-self: flex-start;
  width: 1.875rem; // 30
  height: 1.875rem;// 30
  line-height: 1.875rem; // 30
  background: $gray-mm;
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

// <div> content
//---------------------------------------------
.instruction__content {
  padding: 0 0 0 2.8125rem; // 45 = 30width of step and 15gap
}

// <div> summary
//---------------------------------------------
.import-summary {
  border: 0.0625rem solid $border-color-light;
  border-radius: var(--border-radius-lg);
  padding-block: $space-10;
  padding-inline: $space-15;
  margin-block-end: $space-30;
}

.import-summary__item {
  display: flex;
  align-items: center;
  gap: $space-10;
  padding: $space-15;
  line-height: 1.5;

  &:not(:last-child) {
    border-bottom: 1px solid $border-color-light;
  }
}

// <svg>
.import-summary__icon {
  flex-shrink: 0;
}

.summary-icon--success {
  fill: $success-color;
}

.summary-icon--warning {
  fill: $bright-yellow;
}

.summary-icon--info {
  fill: $info-color;
}