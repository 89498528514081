// TEASER INVITE

// <div>
//---------------------------------------------
.recommend-status {
  display: flex;
  gap: 1.875rem;
  align-items: center;

  @container card (width <= 31.25rem) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
}

.recommend-status__headline {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.3125rem;
}

// <span> in headline
.recommend-status__count {
  font-size: 1rem;
}

// <span> in headline
.recommend-status__text {
  font-weight: 700;
  font-size: 1rem;
}