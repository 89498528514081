// MEET (JITSI)

.meet-container {
  @media (max-width: $breakpoint-lg) {
    padding: $space-30 $page-padding;
  }

  @media (min-width: $breakpoint-lg) {
    padding: $space-60 $page-padding;
  }
}

.meet__inner {
  margin: 0 auto;

  @media (max-width: $breakpoint-lg) {
    max-width: $container-md;
  }

  @media (min-width: $breakpoint-lg) {
    max-width: $container-lg;
  }
}


// <div> flex container
//---------------------------------------------
.meet {
  display: grid;
  gap: 1.875rem;
  width: 100%;
  margin: $space-60 0;

  @media (max-width: $breakpoint-md) {
    grid-template-columns: 30px 1fr 30px;
  }

  @media (min-width: $breakpoint-md) {
    grid-template-columns: 25vw 1fr 25vw;
  }
}

// <div> participants
//---------------------------------------------
.meet-participant {
  padding: 0 30px;
  min-height: 100%;

  &:first-child {
  }

  &:last-child {
  }

  @media (min-width: $breakpoint-lg) {

  }
}

.meet-participant__card {
  background: #fff;
  border-radius: var(--border-radius-lg);
  padding: 1.875rem;

  height: 100%;
  max-height: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.9375rem;

  container-type: inline-size;
  container-name: card;
}

.meet-participant__header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0 0 $space-30 0;

  h2 {
    margin: 0 0 $space-10 0;
  }

  a {
    line-height: 1.3;
  }
}

@container card (max-width: 340px) {
  .meet-participant__header {
    flex-direction: column;
    text-align: center;
  }
}


.meet-participant__img {

}

// <div> participants
//---------------------------------------------
.meet__video {
  flex: 1;
  border-radius: var(--border-radius-lg);
  overflow: hidden;

  @media (min-width: $breakpoint-lg) {

  }
}