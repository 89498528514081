// ensures that padding and borders are included in the element's width and height,
*,
*::before,
*::after {
  box-sizing: border-box;
  scroll-behavior: auto;
}

// html and body resets remove the default body margin
html {
  font-size: 100%; // default 16px
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-height: 100vh;
}

// helps prevent overflow issues and ensures better responsiveness
img,
picture,
video,
svg {
  display: block;
  max-width: 100%;
}

// form controls use the same font as the surrounding
input,
button,
textarea,
select {
  font: inherit;
}

// Remove default padding
ul,
ol {
  padding: 0;
}

// accessibility - user prefers reduced motion (animation & transition)
// --------------------------------------------------
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}