// UPCOMING MATCHING ROUNDS

// <div> item
//---------------------------------------------
.upcoming-round {
  padding: $space-15;

  &:nth-child(odd) {
    background: $gray-lightest;
    border-radius: var(--border-radius-sm);
  }

  // > 700px
  @container card-inline (width > 43.75rem) {
    display: flex;
    gap: $space-15;
    flex-direction: row;
  }

  // < 700px
  @container card-inline (width <= 43.75rem) {

  }
}


// date
//---------------------------------------------
.upcoming-round__date {
  font-weight: 700;
  flex: 0 0 5rem; // 80
}



// communities
//---------------------------------------------
.upcoming-round__communities {
  flex: 1;
  display: flex;

  // > 700px
  @container card-inline (width > 43.75rem) {
    flex-wrap: wrap;
    gap: $space-15;
    justify-content: flex-end;
    text-align: right;
  }

  // < 700px
  @container card-inline (width <= 43.75rem) {
    margin: $space-15 0 0 0;
    display: flex;
    flex-direction: column;
    gap: $space-10;
  }
}

.upcoming-round__community {
  display: flex;
  align-items: center;
  gap: $space-5;
  font-size: 0.875rem; //14
  line-height: 1.3;
}