// PROFILE

// <div> wrapper
//---------------------------------------------
.profile {

}

// <div> top bar (status + public profile link)
//---------------------------------------------
.profile-top-bar {
  display: flex;
  justify-content: flex-end;
  gap: 1.875rem;

  @media (max-width: $breakpoint-sm) {
    align-items: flex-end;
    flex-direction: column;
    flex-wrap: wrap;
  }

  @media (min-width: $breakpoint-sm) {
    flex-wrap: nowrap;
    flex-direction: unset;
    align-items: center;
  }
}

// progress
//---------------------------------------------
.profile-progress {
  width: 100%;
  flex: 1;
}

// <div>
.profile-progress__bar {
  background: $gray-middle;
  width: 100%;
  border-radius: var(--border-radius-lg);
}

// <div>
.profile-progress__status {
  background: $gray-dark;
  padding: 0.3125rem 0.9375rem; // 5 15
  border-radius: var(--border-radius-lg);
  color: #fff;
  font-weight: 700;
  transition: width 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 4.0625rem; // 65
  max-width: 100%;
}

// <span>
.profile-progress__percent {
  display: inline-flex;
  align-items: center;
  gap: 0.3125rem;
}

// <svg>
.profile-progress__svg {
  &.is--hidden {
    display: none;
  }
}

// <a> public profile
//---------------------------------------------
.profile-link {
  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }

  @media (min-width: $breakpoint-sm) {
    width: auto;
  }
}