// SIDEBAR (profile)

// wrapper <div>
// --------------------------------------------------
.sidebar {
  padding: $box-padding-lg $box-padding-sm;
  background: #fff;
  border-radius: var(--border-radius-lg);
  height: 100%;

  @media (max-width: $breakpoint-lg) {
    position: fixed;
    z-index: $zindex-navigation;
    width: 100%;
    left: 0;
    bottom: -100%;
    overflow-y: auto;
    padding-block-end: calc($mobile-sidebar-height + $space-30);
    opacity: 0.85;
    transition: $transition; // 0.25
    will-change: transform, opacity;

    &:focus-within,
    &.is--visible {
      bottom: 0;
      opacity: 1;
    }
  }

  @media (min-width: $breakpoint-lg) {
    position: static;
  }
}

// <div> role
//---------------------------------------------
.sidebar__role {
  display: flex;
  justify-content: space-between;
  gap: 0.625rem;
  margin: 0 0 $space-30 0;
  padding: 0.375rem 0.5rem;
  background: $gray-lightest;
  border-radius: var(--border-radius-sm);
}

// <link> user or admin
.sidebar__role-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  padding: 0.625rem;
  border-radius: var(--border-radius-sm);
  text-decoration: none;

  // if it is only one child, then is uses the whole space
  &:first-child {
    flex: 1;
  }

  // if there is a second child (admin + user), we limit the second one to 50% width
  // and the first child uses the rest (50%) of the space
  &:nth-child(2) {
    flex: 0 0 50%;
  }

  &:hover {
    background: #fff;
  }

  &.is--active {
    background: #fff;
    font-weight: 700;
  }
}


// <nav>
// --------------------------------------------------
.sidebar__nav {
  width: 100%;
  display: block;
  max-width: $container-lg;
  padding: 0;
}

// <ul>
.sidebar__list {
  margin: 0;
  padding: 0.125rem 0;
  list-style: none;
}

// <li>
.sidebar__item {
  &:not(:last-child) {
    margin: 0 0 0.3125rem 0;
  }
}

// <a>, <button>
.sidebar__link {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  width: 100%; // make sure logout button is 100% wide
  font-size: 1rem;
  text-decoration: none;
  color: $gray-mm;
  position: relative;
  padding: 0.625rem 0.9375rem;
  border-radius: var(--border-radius-sm);

  &[aria-expanded='true'],
  &:hover,
  &:focus {
    background: $gray-lightest;
  }

  // active state
  &[aria-current='page'],
  &.is--active {
    background: var(--button-bg-color);
    color: var(--button-text-color);
    font-weight: 700;
  }
}

// <span> holding the svg
.sidebar__icon {
  width: 2.1875rem;
  height: 2.1875rem;
  color: $gray-mm;
  background: $gray-lightest;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  [aria-current='page'] &,
  .is--active & {
    color: var(--button-bg-color);
    background: var(--button-text-color);
  }
}

// <svg> icon
.sidebar__icon-svg {
  height: 0.875rem; // 16
  width: auto;
}

// <span>
.sidebar__text {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

// <svg> toggle icon
.sidebar__icon-toggle {
  margin-left: auto;

  [aria-expanded='true'] & {
    transform: rotate(180deg);
  }
}

// <span> badge
.sidebar__badge {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  transform: translate(-0.1875rem, 0);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $bright-yellow;
  color: $text-color;
  margin-left: auto;
}

// bottom container
// --------------------------------------------------
.sidebar__bottom {
}

// group name
// --------------------------------------------------
.sidebar__group-name {
  display: block;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 700;
  color: $gray-dark;
  padding: 0 0.9375rem;
  margin: 0 0 $space-15 0;
}

// <div> sidebar mobile bar bottom
.sidebar__toggler {
  @media (max-width: $breakpoint-lg) {
    background: #fff;
    position: fixed;
    width: 100%;
    height: $mobile-sidebar-height; // 80
    bottom: 0;
    left: 0;
    z-index: $zindex-sidebar;
    box-shadow: 0 0 0.9375rem 0 rgba(0,0,0,0.15);
    padding: 0.9375rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: $breakpoint-lg) {
    display: none;
  }
}

// <button> nav toggle
//---------------------------------------------
.sidebar__toggle {
  width: auto;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  border-radius: 1.5625rem;
  padding: 0.9375rem 2.1875rem;
  background: var(--button-bg-color);
  color: var(--button-text-color);

  // active state
  &:hover,
  &:focus,
  &[aria-expanded="true"]  {
    background: var(--button-bg-color-hover);
    color: var(--button-text-color-hover);
  }

  // active state
  &[aria-expanded="true"]  {
    svg:first-of-type {
      display: none;
    }

    svg:last-of-type {
      display: block;
    }
  }
}

// <svg>
.sidebar__toggle-icon {
  flex: 0 0 1.25rem;

  &:first-of-type {
    display: block;
  }

  &:last-of-type {
    display: none;
  }

  [aria-expanded="true"] & {
    &:first-of-type {
      display: block;
    }

    &:last-of-type {
      display: none;
    }
  }
}

// text
.sidebar__toggle-text {
  font-size: 1.125rem;
  font-weight: 700;
}