// BADGE

// wrapper
//---------------------------------------------
.badge {
  font-size: 0.75rem;
  border-radius: var(--border-radius-sm);
  padding: 0.3125rem;
  display: inline-block;

  .sidebar__link & {
    margin-left: auto;
  }

  .card__headline-label & {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.badge--success {
  background: $success-color;
  color: #fff;
}

.badge--warning {
  background: $bright-yellow;
  color: $gray-mm;
}

/*
.badge--info {

}

.badge--error {

}
*/