// SYSTEM MESSAGE

// wrapper
//---------------------------------------------
.system-message {
  width: 100%;
  padding-inline: $page-padding;
  background: $alert-warning-bg;
  color: $alert-warning-color;

  @media (max-width: $breakpoint-lg) {
    padding-block: $box-padding-sm;
  }

  @media (min-width: $breakpoint-lg) {
    position: fixed;
    left: 0;
    top: 0;
    z-index: $zindex-system-message;
    height: $system-message-height;
    padding-block: 0.3125rem;
  }
}

// <div> inner
//---------------------------------------------
.system-message__inner {
  display: flex;
  justify-content: center;
  gap: 0.9375rem;
  height: 100%;

  @media (max-width: $breakpoint-lg) {
    align-items: flex-start;
  }

  @media (min-width: $breakpoint-lg) {
    align-items: center;
  }
}

// <svg> icon
//---------------------------------------------
.system-message__svg {
  flex: 0 0 1.5rem;
}