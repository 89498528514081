// CARD

// <div> card
//---------------------------------------------
.card {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-lg);
  padding: $box-padding-lg;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  background: var(--card-bg);
  color: var(--card-color);
}

// <modifier>
// todo: modifier löschen, sobald invite card weg kann.
.card--light {
  //--card-bg: #fff;
  //--card-color: #{$text-color};
}

.card--dark {
  --card-bg: #{$gray-mm};
  --card-color: #fff;
}

.card--highlight {
  box-shadow: $box-shadow-highlight;
}

// card header
//---------------------------------------------
.card__header {
  position: relative;

  &:has(.badge) {
    padding-inline-end: $space-60;
  }
}

// headline
//---------------------------------------------
.card__headline {
  font-size: 1rem;
  display: inline-flex;
  flex-direction: column;
  gap: 0.625rem;
  line-height: 1.5;

  .card--highlight & {
    font-weight: 700;
    color: $gray-darkest;
  }
}

// <img> e.g. community
.card__headline-image {
  border-radius: var(--border-radius-sm);
  align-self: flex-start;
}

// "label"
.card__headline-label {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;

  h1, h2, h3, h4 {
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: normal;
    align-self: center;
  }
}

// hint text
.card__headline-hint {
  font-size: 0.875rem;
  display: block;
}

// open (collapsible)
.collapse__buttons {
  &.is--hidden {
    display: none;
  }

  // reset the margin-top if there is no other element in the card body.
  &:only-child {
    margin: 0;
  }
}


// card body
//---------------------------------------------
// view mode
.card-mode--view {
  &.is--hidden {
    display: none;
  }
}

// edit mode
.card-mode--edit {
  &:not(&.is--visible) {
    display: none;
  }

  // add focus outline for keyboard users. div is focused when card is--editing
  &:focus-visible {
    border-radius: var(--border-radius-sm);
  }
}

// the shown value
.card__attribute-value {
  line-height: 1.3;
  font-weight: 700;
  word-break: break-word;
}

// unanswered
.card__attribute-highlight {
  background: $alert-info-bg;
  color: $alert-info-color;
  padding: 0.3125rem 0.625rem;
  border-radius: var(--border-radius-sm);
  font-weight: normal;
}

// empty or missing information
.card__empty {
  background: $gray-lightest;
  border-radius: var(--border-radius-sm);
  width: 100%;
  font-weight: bold;
  padding: 0.625rem;
  font-size: 0.875rem;
  color: $gray-dark;
}

// card footer
//---------------------------------------------
.card__footer {
  margin-top: auto; // push the container down to the end

  // default: all buttons and links with class btn on the first level get this specific styling.
  & > .btn {
    width: 100%;
    max-width: 19.375rem; // 310
  }
}


// card saved animation
//---------------------------------------------
.card__success {
  display: inline-flex;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  min-height: 2.125rem;
  min-width: 2.125rem;
}

// save animation
.card__saved {
  display: none;

  .is--success & {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 2.125rem;
    height: 2.125rem;
    color: #fff;
    background: $success-color;
    border-radius: var(--border-radius-sm);
    animation-duration: 2.5s;
    animation-name: saveSuccess;
    animation-fill-mode: forwards;
    animation-timing-function: $transition-timing;
  }
}