// HELP

// wrapper <div>
//---------------------------------------------
.help {
  position: fixed;
  z-index: $zindex-dropdown;
  width: 2.8125rem; // 45
  height: 2.8125rem; // 45
  bottom: 0.625rem;
  right: 0.625rem;

  @media (min-width: $breakpoint-lg) {
    right: $page-padding;
    bottom: $page-padding;
  }

  .has--navigation & {
    right: $page-padding;
    bottom: calc(#{$footer-height} + #{$page-padding});

     @media (max-width: $breakpoint-lg) {
       bottom: 1.125rem;
       right: 0.9375rem;
     }
  }
}

// trigger <button>
//---------------------------------------------
.help__toggle {
  border-radius: 50%;
  background: $gray-mm;
  border: 0.125rem solid #fff;
  width: 2.8125rem; // 45
  height: 2.8125rem; // 45
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  &:active {
    transform: scale(0.9);
  }

  &:hover,
  &:focus {
    background: $gray-dark;
    color: #fff;
  }

  // show 'x' or '?' depending on aria-expanded value
  &[aria-expanded="true"] {
    svg:first-of-type {
      display: none;
    }

    svg:last-of-type {
      display: block;
    }
  }
}

// <svg>
.help-trigger__icon {
  height: 1.25rem;

  // initially hide the close 'x' button.
  // when parent gets attribute aria-expanded="true" then show it and hide the '?'
  &:last-of-type {
    display: none;
  }

  @media (min-width: $breakpoint-lg) {
    height: 1.4375rem;
  }
}

// <span> text
.help-trigger__text {
  position: absolute;
  white-space: nowrap;
  width: 0.0625rem;
  height: 0.0625rem;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip-path: inset(50%);
  margin: -0.0625rem;
}