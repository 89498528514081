﻿//= SWITCH (Checkbox)

// <div> chip
//---------------------------------------------
.switch {
  display: inline-block;
}


// <label>
//---------------------------------------------
.switch__label {
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: pointer;
  gap: 0.625rem;

  &::before {
    content: '';
    width: 2.875rem;
    height: 1.625rem;
    display: inline-block;
    position: relative;
    background: $gray-middle;
    border-radius: var(--border-radius-xl);
    transition: background $transition;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.1875rem;
    border-radius: var(--border-radius-xl);
    background: #fff;
    transition: $transition;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,0.3); // 0.125rem 0.25rem
  }
}

// <input> checkbox
//---------------------------------------------
.switch__input {
  // "hide" the checkbox
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;

  // checked state
  &:checked ~ .switch__label {
    &::before {
      background: $success-color;
    }

    &::after {
      translate: 1.25rem 0;
    }
  }

  // set focus style to label
  &:focus-visible ~ .switch__label {
    &::before {
      box-shadow: $focus-shadow; // 0.125rem
      outline: $focus-outline;
      outline-offset: $focus-outline-offset;
    }
  }

  &[disabled] ~ .switch__label {
    color: $gray-dark;
    pointer-events: none;

    &::before {
      opacity: 0.8;
    }
  }
}


