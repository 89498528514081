//= UX AUTOCOMPLETE OVERRIDES
// file location: node_modules/.../tom-select.css
//---------------------------------------------

// <div> wrapper
//---------------------------------------------
.ts-wrapper {
  position: relative;
}

// <select>
//---------------------------------------------
.tomselected {
  appearance: none;
  width: 100%;
  height: $input-height;
  padding: 0 2.1875rem 0 0.9375rem;
  background-color: #fff;
  color: $text-color;
  border: 0.0625rem solid $border-color;
  border-radius: var(--border-radius-sm);

  // set the arrow right
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/></svg>');
  background-size: 0.75rem;
  background-repeat: no-repeat;
  background-position: right 0.625rem center; // 10px set the arrow svg as background image

  // hover & focus
  &:hover {
    border-color: $gray-mm;
  }

  // disabled
  &[disabled] {
    background-color: $gray-lightest;
    pointer-events: none;
  }

  // error style
  &[aria-invalid="true"],
  &.is--invalid {
    border-color: $danger-color;

    &:focus {
      border-color: $border-color;
    }
  }

  &::-ms-expand {
    display: none;
  }

  // for touch devices show the native select and move the autocomplete out of the visible area
  // to have the benefits of native select on mobile
  @media (hover: hover) {
    position: absolute;
    white-space: nowrap;
    width: 0.0625rem;
    height: 0.0625rem;
    overflow: hidden;
    border: 0;
    padding: 0;
    clip-path: inset(50%);
    margin: -0.0625rem;
  }
}

// hide the clear button - its the selects nature to have something selected
.ts-wrapper .clear-button {
  display: none;
}

// <div> wrapper 'select'
//---------------------------------------------
.ts-control {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  width: 100%;
  height: $input-height;
  padding: 0 2.1875rem 0 0.9375rem;
  background-color: #fff;
  color: $text-color;
  border: 0.0625rem solid $border-color;
  border-radius: var(--border-radius-sm);
  position: relative;

  // set the arrow right
  &::after {
    content: '';
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    display: block;
    width: 0.5625rem;
    height: 0.5625rem;
    border-top: 0.0625rem solid $gray-mm;
    border-right: 0.0625rem solid $gray-mm;
    border-width: 0.1875rem;
    border-radius: 0.0625rem;
    transform: rotate(135deg);
  }

  // arrow when dropdown is open
  .dropdown-active & {
    box-shadow: $focus-shadow;
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;

    &::after {
      transform: rotate(-45deg);
      top: 1rem;
    }
  }

  // hover
  &:hover,
  .focus &, {
    border-color: $gray-mm;
  }

  .focus & {
    // add focus outline for all users (mouse, touch & keyboard)
    box-shadow: $focus-shadow;
  }

  // ux autocomplete styles (check if needed)
  overflow: hidden;
  z-index: 1;

  // hide the ux-autocomplete on touch devices and show the select instead
  @media (hover: none) {
    display: none;
  }

  .is--loading & {
    &, input {
      background: $gray-lightest;
      pointer-events: none;
      font-size: 0;
    }

    &::before {
      content: '';
      width: 0.875rem;
      height: 0.875rem;
      border-top: 0.1875rem solid #ccc;
      border-right: 0.1875rem solid #ccc;
      border-bottom: 0.1875rem solid rgba(0, 0, 0, 0.5);
      border-left: 0.1875rem solid rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      animation: rotate 1.1s linear infinite;  // find animation in keyframes.scss
      position: absolute;
      left: 0.9375rem; // 15
    }
  }
}

// selected text
.ts-control .item {
  cursor: default;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// <input>
.ts-control > input {
  flex: 1 1 auto;
  border: 0;

  &:focus {
    box-shadow: none;
    outline: 0.0625rem solid transparent;
  }

  // remove "x" from internet explorer when text is typed
  &::-ms-clear {
    display: none;
  }

  .input-hidden & {
    opacity: 0;
    position: absolute;
    left: -625rem;
  }
}

// <div> dropdown menu
//---------------------------------------------
.ts-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 2;
  border: 0.0625rem solid $border-color;
  background: #fff;
  overflow: hidden;
  transform: translateY(0.4375rem);
  box-shadow: $focus-shadow;
  border-radius: var(--border-radius-sm);

  .dropdown-active & {
    box-shadow: $focus-shadow;
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }
}

// <div> menu content wrapper
.ts-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 18.75rem; // 300
  overflow-scrolling: touch;
  scroll-behavior: smooth;
}

// <div> option
.ts-dropdown .option {
  padding: 0.625rem 0.9375rem; // 10 15
  cursor: default;
  line-height: $line-height-text;

  &.selected {
    font-weight: 700;
    color: $text-color;

    background-color: $gray-lightest;

    &::after {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-hidden="true"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/></svg>');
      width: 0.75rem; // 12
      height: 0.75rem; // 12
      display: inline-block;
      transform: translateX(0.625rem);
    }
  }

  &:hover,
  &:focus {
    color: $text-color;
    background-color: $gray-middle;
  }

  // if user is navigating with keyboard also highlight the current aria-selected item
  &[aria-selected="true"] {
    color: $text-color;
    background-color: $gray-middle;
  }

  // groups following a single option get a border top for better visual separation
  & + .optgroup {
    border-top: 0.0625rem solid $gray-middle;
  }
}

// no results
.ts-dropdown .no-results {
  padding: 0.9375rem 1.25rem;
  cursor: default;
}

// option group
//---------------------------------------------
.ts-dropdown .optgroup {
  &:not(:last-child) {
    border-bottom: 0.0625rem solid $gray-middle;
  }
}

.ts-dropdown .optgroup-header {
  color: $gray-dark;
  background: #fff;
  cursor: default;
  padding: .75rem .9375rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.0125rem;
}