//= MS HIGH CONTRAST MODE

// mode active
// --------------------------------------------------------
@media (forced-colors: active) {
  // make sure all buttons get a visible border
  //---------------------------------------------
  button {
    border: 0.0625rem solid transparent;
  }

  // hover fix for buttons which do not initially have a border (transparent vs. rgba(255,255,255,0))
  .btn-icon,
  .password-reveal__toggle,
  .email-chip__delete,
  .password-generate__button,
  .accordion__toggle {
    &:focus,
    &:hover {
      border-color: rgba(255,255,255,0);
    }
  }

  .btn-icon.is--pulsing {
    animation: none;
    border-radius: var(--border-radius-sm);
  }

  // add transparent focus outline
  //---------------------------------------------
  :focus,
  :focus-visible {
    outline-width: 0.1875rem;
    outline-style: solid;
    outline-offset: 0.125rem;
    outline-color: transparent;
  }

  .chip__input:focus ~ .chip__label,
  .checkbox:focus ~ .checkbox-label,
  .radio:focus ~ .radio-label,
  .avatar-picker__input:focus ~ .avatar-picker__label,
  .dropzone-input:focus + .dropzone-placeholder {
    outline-width: 0.1875rem;
    outline-style: solid;
    outline-offset: 0.125rem;
    outline-color: Highlight;
  }

  // remove transitions on hover (button, checkbox, etc.)
  //---------------------------------------------
  :hover {
    &,
    &::before {
      transition: none !important;
    }
  }

  // link hover styles
  //---------------------------------------------
  p a,
  label a,
  .anchor,
  .btn:is(a),
  .copyright__logo,
  .dropdown__link:is(a),
  .resources__link,
  .navigation__link {
    text-decoration: underline;
    text-decoration-color: transparent;

    text-underline-offset: 0.25rem;
    text-decoration-thickness: 0.125rem;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .social-media__link {
    &:hover,
    &:focus {
      border: 1px solid transparent;
    }
  }

  // svgs
  //---------------------------------------------
  // make sure it gets the same color as the link, button, etc.
  svg {
    forced-color-adjust: auto;
  }

  // dont apply system colors to following svgs. use the original colors instead
  .match svg,
  .contact__visual svg {
    forced-color-adjust: none;
  }

  // make sure every item in this list has a visible border
  //---------------------------------------------
  .accordion__item,
  .contact__item,
  .about__item,
  .alert,
  .pause-info,
  .notification,
  .support__dialog,
  .modal__dialog,
  .card,
  .wizard__box {
    border: 0.0625rem solid CanvasText;
  }

  // form elements hover state
  //---------------------------------------------
  .checkbox-label,
  .radio-label {
    svg  {
      forced-color-adjust: none;
      border: 0.0625rem solid CanvasText;
    }

    &:hover {
      .checkbox__svg,
      .radio__svg {
        forced-color-adjust: none;
        color: Highlight;
        border-color: Highlight;
      }
    }
  }

  // chips
  .chip__input:checked~.chip__label,
  .chip__label {
    &:hover {
      border: 0.0625rem solid Highlight;
    }
  }

  // ux autocomplete
  .ts-control {
    &:hover,
    .focus & {
      border: 0.0625rem solid Highlight;
    }
  }

  .ts-dropdown .option:hover {
    color: Highlight;
    font-weight: 700;
  }

  // datepicker
  .flatpickr-day:hover {
    font-weight: 700;
    text-decoration: underline;
    border-color: Highlight;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    &:hover {
      border: 0.0625rem solid Highlight;
    }
  }

  .flatpickr-monthSelect-month:hover {
    font-weight: 700;
    text-decoration: underline;
    border-color: Highlight;
  }

  .flatpickr-disabled {
    opacity: 0.6;
  }

  // show checked avatar
  .avatar-picker__input:checked~.avatar-picker__label,
  .avatar-picker__input:focus~.avatar-picker__label {
    outline-width: 0.1875rem;
    outline-style: solid;
    outline-offset: 0.125rem;
    outline-color: Highlight;
  }

  .avatar-picker__label {
    &:hover {
      outline-width: 0.1875rem;
      outline-style: solid;
      outline-offset: 0.125rem;
      outline-color: Highlight;
    }
  }

  // dropzone
  //---------------------------------------------
  // show the dropzone file input as button
  .dropzone-container {
    border: 0.0625rem solid ButtonBorder;
    color: ButtonText;
    forced-color-adjust: none;

    &:hover,
    &:focus {
      background: transparent;
      border: 0.0625rem solid Highlight;
    }
  }

  // remove the padding top, so the text is centered (icon is removed by whcm)
  .dropzone-placeholder {
    padding: 0;
    background: 0;
  }

  // invite email chip
  //---------------------------------------------
  .email-chips {
    padding-inline: 0;
  }

  .email-chip__item {
    gap: 0.625rem;
    border: 0.0625rem solid CanvasText;
  }

  .email-chip__delete {
    border-radius: 0 1.25rem 1.25rem 0;
  }

  // checkbox & radio
  //---------------------------------------------
  .checkbox-label::before,
  .radio-label::before {
    border: 0.0625rem solid CanvasText;
  }

  .radio {
    // checked
    &:checked ~ .radio-label {
      &::before {
        forced-color-adjust: none; // remove the color adjustment of ms contrast
        box-shadow: none;
        background: CanvasText;
      }
    }

    // checked & focused
    &:checked:focus ~ .radio-label {
      &::before {
        box-shadow: none;
      }
    }
  }

  // ux autocomplete active item
  //---------------------------------------------
  .ts-dropdown .option.selected {
    text-decoration: underline;
  }

  // cropperjs point
  //---------------------------------------------
  .cropper-point {
    forced-color-adjust: none;
    background: #fff;
  }

  // datepicker
  //---------------------------------------------
  .flatpickr-day.today {
    background: CanvasText;
  }

  .flatpickr-calendar:before,
  .flatpickr-calendar:after {
    opacity: 0;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: Highlight;
    border-color: Highlight;
  }

  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange {
    background: Highlight;
    border-color: Highlight;
  }

  // month select
  .flatpickr-monthSelect-month {
    border: 0.0625rem solid CanvasText;
  }

  .flatpickr-monthSelect-month.selected {
    background-color: Highlight;
  }

  // password circles
  //---------------------------------------------
  .password-bullets__circle {
    background: CanvasText;
  }

  // password generate button
  //---------------------------------------------
  .password-generate__button {
    padding: 0.625rem;
  }

  // hide decorative images and optimize containers
  //---------------------------------------------
  .hero__visual,
  .about__visual,
  .invite-status__img,
  .wizard__image,
  [data-controller="party"] {
    display: none;
  }

  // hero
  .hero__inner {
    grid-template-columns: repeat(1,1fr);
  }

  .hero__text {
    text-align: center;
    max-width: 48.125rem;
    margin: 0 auto;
  }

  // increase visual separation of sections
  //---------------------------------------------
  .hero,
  .about,
  .accordion {
    &:not(:only-child) {
      border-bottom: 0.0625rem solid CanvasText;
    }
  }

  .about--steps {
    content-visibility: visible; // make sure the border is shown
  }

  // header & footer
  //---------------------------------------------
  .header {
    border-bottom: 0.0625rem solid CanvasText;
  }

  .logo {
    forced-color-adjust: none;
    background: #fff;
    border-radius: 0.3125rem;
    box-shadow: 0 0 0 0.125rem rgba(255,255,255, 1);
  }

  .footer {
    border-top: 0.0625rem solid CanvasText;
  }

  // navigation
  //---------------------------------------------
  .navigation {
    border: 0;
  }

  .navigation__link {
    &.is--active {
      text-decoration: none;
      border: 0.0625rem solid transparent;
      border-radius: var(--border-radius-sm);
    }

    &::after {
      content: none;
    }
  }

  // data privacy cookie row borders
  //---------------------------------------------
  .cookie__row {
    border-radius: 0;

    &:not(:last-child) {
      border-bottom: 0.0625rem solid CanvasText;
    }
  }

  // progress bar
  //---------------------------------------------
  .profile-progress__bar {
    border: 0.0625rem solid CanvasText;
  }

  .profile-progress__status {
    background: CanvasText;
  }
}