// FEATURE LAB

// <div> wrapper
// --------------------------------------------------
.feature {
  position: relative;
  background: $gray-lightest;
  border-radius: var(--border-radius-lg);
  padding: $box-padding-lg;

  &:not(:last-child) {
    margin: 0 0 $space-30 0;
  }
}

// <div> switch
// --------------------------------------------------
.feature__switch {
  position: absolute;
  right: $box-padding-lg;
  top: $box-padding-lg;
}

// <label>
.feature__label {
  cursor: pointer;
  display: block;
  -webkit-tap-highlight-color: transparent;
}

// <span> toggle
.feature__toggle {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  gap: 0.625rem;

  &::before {
    content: '';
    width: 2.875rem;
    height: 1.625rem;
    display: inline-block;
    position: relative;
    background: $gray-middle;
    border-radius: var(--border-radius-xl);
    transition: background $transition;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.1875rem;
    border-radius: var(--border-radius-xl);
    background: #fff;
    transition: $transition;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,0.3); // 0.125rem 0.25rem
  }
}

// <input type="checkbox">
// --------------------------------------------------
.feature__input {
  // hide the checkbox
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;

  // checked state
  &:checked ~ .feature__label {
    .feature__toggle {
      &::before {
        background: $success-color;
      }

      &::after {
        translate: 1.25rem 0;
      }
    }
  }

  // set focus style to label
  &:focus-visible ~ .feature__label {
    .feature__toggle {
      &::before {
        box-shadow: $focus-shadow; // 0.125rem
        outline: $focus-outline;
        outline-offset: $focus-outline-offset;
      }
    }
  }
}


// <hX> headline
// --------------------------------------------------
.feature__headline {
  padding-inline-end: 3.75rem; // create space for the switch
}

// <div> info (text & image)
// --------------------------------------------------
.feature__info {
  display: flex;
  gap: $space-30;
  max-width: $container-md;
  margin: $space-30 0;
  line-height: $line-height-text;

  // smaller than 500
  @container card-inline (width <= 31.25rem) {
    flex-direction: column;
  }

  // bigger than 500
  @container card-inline (width > 31.25rem) {
    align-items: center;
  }
}

// <button> img
.feature__zoom {
  align-self: flex-start;

  position: relative;
  border: 0.0625rem solid $gray-middle;
  border-radius: var(--border-radius-sm);
  will-change: transform;
  transition: $transition;

  // smaller than 500
  @container card-inline (width <= 31.25rem) {
    flex: 0 0 100%;
  }

  // bigger than 500
  @container card-inline (width > 31.25rem) {
    flex: 0 0 11.25rem; // 180
  }

  &:hover,
  &:focus {
    transform: scale(1.02);
  }
}

// <img>
.feature__img {
  border-radius: var(--border-radius-sm);
}

// <svg> zoom icon
.feature__zoom-svg {
  position: absolute;
  right: 0.3125rem;
  bottom: 0.3125rem;
  fill: $gray-middle;
}

// <span> role
// --------------------------------------------------
.feature__role {
  color: $gray-dark;
  display: inline-flex;
  align-items: center;
  gap: 0.3125rem;
  margin: 0.625rem 0 0 0;
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration: none;
}