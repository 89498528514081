// TYPOGRAHPY

// headlines
//---------------------------------------------
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: 700;
  line-height: 1.3;
  margin: 0;
  display: block; // make sure spans are displayed as block elements
  text-wrap: balance;
}

// headline 1
h1,
.h1 {
  @media (max-width: $breakpoint-md) {
    font-size: 1.875rem; // 30
  }

  @media (min-width: $breakpoint-md) {
    font-size: 2.125rem; // 34
  }
}

// headline 2
h2,
.h2 {
  font-size: 1.625rem; // 26
}

// headline 3
h3,
.h3 {
  font-size: 1.25rem; // 20
}

// headline 4
h4,
.h4 {
  font-size: 1rem; // 16
}

// other sizes
.large-headline {
  @media (max-width: $breakpoint-md) {
    font-size: 2rem; // 32
  }

  @media (min-width: $breakpoint-md) {
    font-size: 2.875rem; // 46
  }
}

// <a>
//---------------------------------------------
a {
  color: inherit;
  text-underline-offset: 0.125rem;
}

p a,
label a,
.anchor {
  font-weight: 700;
  text-decoration: underline;
  color: var(--link-color);
  text-underline-offset: 0.125rem;
  word-break: break-word; // prevent a horizontal scrollbar with breaking the links into a new line
  text-align: left;

  &:hover {
    text-decoration: none;
  }

  // within an alert the links should be the same color as the alert text.
  .alert & {
    color: inherit;
  }

  // todo 12/2024: switch to &:has(svg) ...
  &.has--icon {
    display: inline-flex;
    align-items: flex-start;
    gap: 10px;
    line-height: 1.3;

    svg {
      flex: 0 0 1rem;
    }
  }

  /*
  &:has(svg) {
    display: inline-flex;
    align-items: flex-start;
    gap: 10px;
    line-height: 1.3;

    svg {
      flex: 0 0 1rem;
    }
  }
  */
}

// p, strong, b
//---------------------------------------------
p {
  margin: 0;
  line-height: $line-height-text;
  word-break: break-word;
}

strong, b {
  font-weight: 700;
}


// lists
//---------------------------------------------
ul, ol {
  margin: 0;
}

.list--reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// address
//---------------------------------------------
address {
  font-style: normal;
}


// spacings
//---------------------------------------------
.copy-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5 {
    margin: 0 0 $space-15 0;
  }

  ul, ol {
    padding-left: 1.1875rem;

    li {
      line-height: $line-height-text;
    }
  }

  // apply more spacing on top of headlines, if they follow a p, ul or ol
  p, ul, ol, video {
    margin: 0 0 $copy-text-spacing 0;

    & + h2, & + h3, & + h4 {
      margin-top: $space-30;
    }
  }

  :last-child {
    margin-bottom: 0;
  }
}

// spacings for attribute lists
//---------------------------------------------
.card__attribute-value,
.participant__attribute-row {
  ul {
    &:not(.chips-list) {
      padding-left: 1.1875rem;
      margin: 0 0 $copy-text-spacing 0;

      li {
        line-height: $line-height-text;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// word-separator (like --- or ----)
//---------------------------------------------
.word-separator {
  display: flex;
  align-items: center;
  padding: $space-30 0;
  text-align: center;
  gap: 0.9375rem;
  color: $gray-dark;

  &::after,
  &::before {
    content: "";
    height: 0.125rem;
    background: $gray-middle;
    flex: 1 1 auto;
  }
}

// emoji
//---------------------------------------------
.emoji {
  font-family: "Twemoji Mozilla", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", "EmojiOne Color", "Android Emoji", sans-serif;
}