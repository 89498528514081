// MODAL

// wrapper <div>
// --------------------------------------------------
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $page-padding;
  z-index: $zindex-modal;
  transition: $transition; // 0.25
  opacity: 1;
  visibility: visible;
  will-change: transform, opacity, visibility;
  background: $overlay-bg-color;

  &[inert] {
    opacity: 0;
    visibility: hidden;
  }
}

// <div> backdrop (area to close the element on click outside)
//---------------------------------------------
.modal__backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
}

// <div> modal
//---------------------------------------------
.modal__dialog {
  padding: $box-padding-lg;
  border-radius: var(--border-radius-lg);
  width: 100%;
  max-width: 35.625rem;
  background: #fff;
  margin: auto; // center the modal vertically and horizontally
  position: relative;
  z-index: $zindex-modal-dialog;

  transform-origin: center center;
  transition: $transition; // 0.25
  will-change: transform, opacity, visibility;
  opacity: 0;
  transform: scale3d(0.9, 0.9, 0.9);

  .is--visible & {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

// <div> header
//---------------------------------------------
.modal__header {
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
}

// <hX> headline
//---------------------------------------------
.modal__headline {
  h1, h2, h3, h4, span, p {
    font-weight: 700;
    line-height: 1.3;
    margin: 0;
    font-size: 1.625rem; // 26px
  }
}

// <button> close
//---------------------------------------------
.modal__close {
  margin-left: auto;
  transform: translate(15px, -15px);
}

// <div> body
//---------------------------------------------
.modal__body {
  margin: $space-30 0 0 0;
}

// <div> footer
//---------------------------------------------
.modal__footer {
  display: flex;
  gap: 0.9375rem;
  margin: $space-30 0 0 0;

  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    align-items: stretch;
  }

  @media (min-width: $breakpoint-sm) {
    flex-direction: row;
    align-items: center;
  }

  :only-child {
    flex: 0 0 100%;
  }

  // special case for two submit buttons within the footer.
  .form__submit {
    margin: 0;
  }
}

// welcome modal content
//---------------------------------------------
.modal--welcome {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.9375rem;
  margin: 0 0 $space-15 0;
  text-align: center;

  img,
  svg {
    margin: $space-15 0;
    max-width: 21.875rem; // 350

    @media (min-width: $breakpoint-md) {
      max-width: 100%;
      width: 25rem;
    }
  }
}