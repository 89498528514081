// ALERT

// wrapper
//---------------------------------------------
.alert {
  border-radius: var(--border-radius-sm);
  padding: $box-padding-sm;
  color: #fff;
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  margin-block-end: $space-30;

  &[hidden] {
    display: none;
  }

  // make sure the last alert has no margin-bottom, so the spacing is consistent with the grid gap
  .grid__alerts & {
    &:last-child {
      margin: 0;
    }
  }

  // if the alert is the only child withhin the simple-toggle wrapper, remove the spacing
  .simple-toggle & {
    &:only-child {
      margin: 0;
    }
  }

  // todo 1: use &:not([hidden]) to show alerts only when they do not have the hidden attribute

  // todo 2: use this to only apply margin at the bottom, if the alert itself does not have any other margin-- utility classes set.
  // also review other places in (simple.-toggle and grid__alerts)
  //  &:not([class*="margin--"]) {
  //    margin-block-end: $space-30; // take from above
  //  }


  &.margin--reset-all {
    margin-bottom: 0;
  }
}

// <modifier>
.alert--default {
  background: $gray-lightest;
  color: $text-color;
}

.alert--error {
  background: $alert-error-bg;
  color: $alert-error-color;
}

.alert--warning {
  background: $alert-warning-bg;
  color: $alert-warning-color;
}

.alert--info {
  background: $alert-info-bg;
  color: $alert-info-color;
}

.alert--success {
  background: $alert-success-bg;
  color: $alert-success-color;
}

.alert--tip {
  background: $alert-tip-bg;
  color: $alert-tip-color;
}

// <svg> icon
//---------------------------------------------
.alert__icon {
  flex: 0 0 1.5rem;
  width: 1.5rem;
  height: auto;
}

// <div> message
//---------------------------------------------
// bold headline
.alert__headline {
  font-weight: 700;
  line-height: $line-height-text;
  display: block;
  margin: 0 0 $space-5 0;
}

// copy
.alert__description {
  margin: 0;
  line-height: $line-height-text;

  &:not(:last-child) {
    margin: 0 0 $copy-text-spacing 0;
  }
}