// FOOTER <footer>

// <footer>
// --------------------------------------------------
.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 1.875rem; // 30
  column-gap: 0.9375rem; // 15
  background: $gray-mm;
  color: #fff;
  min-height: $footer-height;

  @media (max-width: $breakpoint-lg) {
    flex-direction: column;
    padding: 1.875rem $page-padding;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 0.625rem $page-padding;
    flex-direction: row;
  }

  // if there is a mobile navigation create some space for it.
  // Otherwise when the page is scrolled down completely it would overlay the footer links
  .has--navigation & {
    @media (max-width: $breakpoint-lg) {
      padding-block-end: 7.5rem;
    }

    @media (min-width: $breakpoint-lg) {
      padding: 0.625rem $page-padding;
    }
  }

  // in profile the footer is always visible (fixed) at the bottom
  &.is--fixed {
    @media (min-width: $breakpoint-lg) {
      position: fixed;
      bottom: 0;
      height: $footer-height;
      z-index: $zindex-footer;
    }
  }
}

// <span> copyright
// --------------------------------------------------
.copyright {
  @media (max-width: $breakpoint-xl) {
    text-align: center;
  }

  @media (min-width: $breakpoint-xl) {
    text-align: left;
  }
}

.copyright__logo {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.copyright__text {

}

.copyright__image {
  max-width: 7.8125rem;
}

// resource links
// --------------------------------------------------
.resources {
  &:only-child {
    margin-inline: auto;
  }
}

.resources__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  column-gap: 1.875rem;
  row-gap: 0.9375rem;
}

.resources__link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

// social media
// --------------------------------------------------
.social-media__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.625rem;

  @media (max-width: $breakpoint-xl) {
    justify-content: center;
  }

  @media (min-width: $breakpoint-xl) {
    justify-content: flex-end;
  }
}

// <a>
.social-media__link {
  width: 2.125rem;
  height: 2.125rem;
  border-radius: var(--border-radius-sm);
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //transition: $transition; // 0.25

  &:hover {
    border: 0.0625rem solid #fff;
  }
}

// <svg>
.social-media__icon {
  height: 0.9375rem;
  width: auto;
}