// PARTICIPANTS (ADMIN)

// <div> name
//---------------------------------------------
.participants__profile {
  @container card (width <= 50rem) {
    margin-block-end: $space-10;
    flex: 0 0 100%;
  }
}

// <span> name
.participants__name {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  line-height: 1.3;
  text-decoration: none;

  &:is(a) {
    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }
}

// <img>
.participants__image {
  border-radius: 50%;
}

// <div> community images
//---------------------------------------------
.participants__community-images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.625rem;
}

.participants__community-img {
  border-radius: var(--border-radius-sm);
}

// <div> delete modal user list
//---------------------------------------------
.participant__delete {
  margin-block: $space-30;
  max-height: 18.75rem; // 300
  overflow-y: auto;
}

.participant__delete-inner {
  flex: 1;
  max-height: 100%;
}

.participant__delete-list {
  margin: 0;
  padding: 0 $space-15 0 0;
  list-style: none;
  display: flex;
  flex-direction: column-reverse;
}

.participant__delete-item {
  &:not(:only-child) {
    padding: $space-10;
    border-radius: var(--border-radius-lg);
  }

  &:nth-child(even)  {
    background: $gray-lightest;
  }
}