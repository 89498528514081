// CONTENT


// <div> wrapper
//---------------------------------------------
.content {
  display: flex;
  gap: 1.875rem;

  //  > 500
  @container card (width > 31.25rem) {
    flex-direction: row;
    align-items: center;
  }

  //  <= 500
  @container card (width <= 31.25rem) {
    flex-direction: column;
  }
}


// <div> img wrapper
//---------------------------------------------
.content__visual {
  align-self: flex-start;

  //  > 500
  @container card (width > 31.25rem) {
    flex: 0 0 9.375rem; // 150
  }

  //  <= 500
  @container card (width <= 31.25rem) {
    max-width: 5rem; // 80
  }
}

// <img>
.content__img {
  border-radius: var(--border-radius-lg);
}

// <div> text
//---------------------------------------------
.content__text {
  flex: 1;
}

// content__character
//---------------------------------------------
.content__character {
  flex: 0 0 7.5rem; // 120
  display: flex;
  margin-inline: auto;
  height: 7.5rem; // 120
  margin-block-end: $space-30;
  border-radius: 50%;
  padding: 1.25rem; // 20
  background: $gray-lightest;
  background: radial-gradient(circle, #fff 0%, $gray-lightest 40%, $gray-light 100%);
}

// content__animation
//---------------------------------------------
.content__animation {
  flex: 0 0 7.5rem; // 120
  display: flex;
  margin-inline: auto;
  margin-block-end: $space-30;
}

// <div> process
//---------------------------------------------
.content-process {
  @media (min-width: $breakpoint-sm) {
    padding: $space-30;
  }
}