// MULTIPLE EMAILS (invite)

// <div> wrapper
//---------------------------------------------
.multiple-emails {

}

// <div> addresses wrapper
//---------------------------------------------
.multiple-emails__chips {

}

// <ul>
.email-chips {
  display: none;
  list-style: none;
  padding: $box-padding-sm;
  margin: 1.25rem 0;
  border-radius: var(--border-radius-sm);
  background: $gray-lightest;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.625rem;

  &.is--visible {
    display: flex;
  }
}

// <li>
.email-chip__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0; // style needed for text-overflow ellipsis on span
  padding: 0 0 0 1.25rem;
  border-radius: var(--border-radius-xl);
  font-weight: bold;
  background: var(--button-bg-color);
  color: var(--button-text-color);

  @media(max-width: $breakpoint-sm) {
    width: 100%;
  }

  @media(min-width: $breakpoint-sm) {
    width: auto;
  }

  &:hover,
  &:focus,
  &:focus-within {
    background: var(--button-bg-color-hover);
    color: var(--button-text-color-hover);
  }
}

// <span> email address
.email-chip__address {
  line-height: 1.3;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.875rem; // 14
}

// <button>
.email-chip__delete {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  min-height: 2.5rem;
  border: 0.0625rem solid transparent;
}

// <div> inner flex container
//---------------------------------------------
.multiple-emails__inner {
  display: flex;

  @media(max-width: $breakpoint-md) {
    flex-direction: column-reverse;
  }

  @media(min-width: $breakpoint-md) {
    flex-direction: column;
  }
}

// <div> flex wrapper for controls
//---------------------------------------------
.multiple-emails__add {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

// <div> wrapper for input and button
//---------------------------------------------
.multiple-emails__input {
  position: relative;
  display: flex;
  gap: 0.625rem;

  button {
    white-space: nowrap;
    height: 2.5rem;
    padding-inline: 0.9375rem;

    span {
      @media(max-width: $breakpoint-lg) {
        // this is a copy of class .visually-hidden
        position: absolute;
        white-space: nowrap;
        width: 0.0625rem; //1px
        height: 0.0625rem; //1px
        overflow: hidden;
        border: 0;
        padding: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        margin: -0.0625rem; //1px
      }

      @media(min-width: $breakpoint-lg) {
        display: block;
        line-height: 1;
      }
    }
  }
}